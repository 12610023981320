import React, { useEffect, useState } from 'react';
import './StyleSheets/PageStyle.css';
import Breadcrumb from '../../Assets/Breadcrumb/Breadcrumb';
import { FaFacebook, FaLink, FaShare, FaCheck } from "react-icons/fa";
import {storage, db } from "../../firebase/config"
import { get, child, ref } from 'firebase/database';
import { getDownloadURL, ref as storageRef } from 'firebase/storage';
import { FacebookShareButton } from 'react-share';

const ProductLink = () => {
  const [products, setProducts] = useState([])
  const user = JSON.parse(localStorage.getItem('user'))
  const [showLink, setShowLink] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const breadcrumbPaths = [
    { label: 'Home', url: '/MyChosenDropPoint' },
    { label: 'Product Link', url: '/ProductLink' },
  ];
  useEffect(() => {
    get(child(ref(db), 'products')).then(async (snapshot) => {
      if (snapshot.exists()) {
        const productsData = snapshot.val();
        const productsArray = Object.values(productsData);
  
        const productsWithImages = await Promise.all(productsArray.map(async (product) => {
          const imageExtensions = ['png', 'jpg'];
  
          let imageUrl = null;
          for (const extension of imageExtensions) {
            const imageRef = storageRef(storage, `products/${product.id}/${product.id}.${extension}`);
            try {
              imageUrl = await getDownloadURL(imageRef);
              break; 
            } catch (error) {
              console.error(`Error fetching ${extension} image:`, error);
            }
          }
          return imageUrl ? { ...product, image: imageUrl } : { ...product, image: null };
        }));
        setProducts(productsWithImages);
      } else {
        console.log('No data available');
      }
    }).catch((error) => {
      console.error(error);
    });
  }, []);

  const handleShowLink = () => {
    setShowLink(true);
  };
  const handleCopyLink = () => {
    const linkText = `https://ichosendroppoint.com/ChoosenDropPoint/${user.uid}`;
    const textarea = document.createElement('textarea');
    textarea.value = linkText;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-PH', { style: 'currency', currency: 'PHP' }).format(amount);
  };
  return (
    <div id='productLinkContent'>
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Product Link</h1>
              <span>Unlock the potential for shared success with our premium products.</span>
            </div>
            <div className="col-sm-6">
              <div className='container breadcrumb-holder d-flex justify-content-end'>
                <Breadcrumb paths={breadcrumbPaths} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="content">
        <div className="container-fluid">
          <div className="card" style={{minHeight: '70vh'}}>
            <div className="card-header border-0 p-5">
              <div className="row">
                <div className="col-12 col-sm-6">
                  <h3>Share our Products</h3>
                </div>
                <div className="col-12 col-sm-6 d-flex justify-content-end">
                  <button className="btn btn-link share-btn" onClick={handleShowLink}>
                    <FaShare/> Share now
                  </button>
                </div>
              </div>
            </div>
            {showLink && (
              <div className='link-holder p-4'>
                <div className="row  d-flex justify-content-between">
                  <div className="col-12 col-md-8 d-flex justify-content-center align-items-center">
                    <button className={`copy-btn p-2 align-items-center ${isCopied ? 'btn btn-success' : 'btn btn-light'}`} onClick={handleCopyLink}>
                      <div className="product-link p-3">
                        <span className='prod-link text-muted'>https://ichosendroppoint.com/ChoosenDropPoint/{user.uid}</span>
                      </div>
                      {isCopied ? <FaCheck style={{marginRight: '5px', marginLeft: '5px'}}/> 
                      : 
                      <FaLink style={{marginRight: '5px', marginLeft: '5px'}}/>} {isCopied ? 'Copied' : 'Copy Link'}
                    </button>
                  </div>
                  <div className='col-12 col-md-4 fbShare-btn d-flex justify-content-end align-items-center'>
                    <button className="btn btn-primary fb-btn align-items-center">
                    <FacebookShareButton
                    url={`https://ichosendroppoint.com/ChoosenDropPoint/${user.uid}`} 
                    quote={`Check out this amazing products! 
                    https://ichosendroppoint.com/ChoosenDropPoint/${user.uid}`}
                    hashtag={'#Choosen #FacebookShareButton'}
                  >
                    <FaFacebook size={20} className='fb-icon mr-2 mb-1'/>
                    Share on Facebook
                  </FacebookShareButton>
                    </button>
                  </div>
                </div>
              </div>
            )}
            <div className="card-body mt-5">
              <div className="product-holder row justify-content-around">
                {products.map((product, index) => (
                  <div key={index} className="col-12 col-xl-2 col-lg-4 col-md-4 col-sm-6">
                    <div className="card w-100">
                      <div className="card-body product-holder justify-content-center align-items-center">
                        <div className="products">
                          <div className="product-img">
                            <img src={product.image} alt="" style={{}}/>
                          </div>
                          <div className="product-info mt-1">
                            <h5>{product.name}</h5>
                            <h6>{formatCurrency(product.price)}</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductLink;
