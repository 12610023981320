import React, { useState, useEffect } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { AiFillDashboard } from 'react-icons/ai';
import { FaWallet, FaSignOutAlt, FaShoppingCart, FaLink, FaHistory } from 'react-icons/fa';
import { IoIosWallet } from 'react-icons/io';
import { SiCashapp } from 'react-icons/si';
import { CgDarkMode } from 'react-icons/cg';
import { MdOutlineBorderColor, MdOutlineKeyboardArrowLeft  } from 'react-icons/md';
import { Switch } from 'antd';
import LOGO_white from '../../../Assets/Images/CDP_white.png';
import LOGO_green from '../../../Assets/Images/CDP_green.png';
import './SideNavbar.css';
import { getAuth, signOut } from "firebase/auth";

function SideNavbar() {
  // State to manage open/closed status of sub-menus
  const [openSubMenu, setOpenSubMenu] = useState({});
  // State to manage the active menu
  const [activeMenu, setActiveMenu] = useState(null);
  // State to manage the selected main menu (when clicking its child sub-menu)
  const [selectedMenu, setSelectedMenu] = useState(null);
  // Add a state variable to track the last clicked parent
  const [lastClickedParent, setLastClickedParent] = useState(null);
  const navigateto = useNavigate()
  const handleNonParentMenuClick = (key) => {
    setActiveMenu(key);
    setSelectedMenu(null);
    setOpenSubMenu({});
    setLastClickedParent(null); // Reset the last clicked parent
  };

  const handleSignOut = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    const auth = getAuth();

    signOut(auth).then(() => {
      navigateto('/')
    }).catch((error) => {
      console.log(error)
    });
  };

  const handleSubMenuClick = (menuKey, subMenuIndex) => {
    const newSelectedMenu = `${menuKey}${subMenuIndex}`;
    const parentKey = menuData.find(
      (menu) =>
        menu.subMenu &&
        menu.subMenu.findIndex((item, index) => `${menu.key}${index}` === newSelectedMenu) !== -1
    )?.key;
  
    // Set the selected state to the current menu
    setSelectedMenu(newSelectedMenu);
  
    setActiveMenu(null); // Reset activeMenu when clicking a child sub-link
  
    // Check if the parent is different from the last clicked parent
    if (parentKey && parentKey !== lastClickedParent) {
      setLastClickedParent(parentKey);
      setOpenSubMenu({ [parentKey]: true });
    }
  };

  // Toggle the sub-menu open/closed status when clicking on the main menu
  const toggleSubMenu = (key) => {
    if (openSubMenu[key]) {
      setOpenSubMenu((prevOpenSubMenu) => ({
        ...prevOpenSubMenu,
        [key]: false,
      }));
      setActiveMenu(null); // Reset activeMenu when closing a sub-menu
    } else {
      setOpenSubMenu((prevOpenSubMenu) => ({
        ...prevOpenSubMenu,
        [key]: true,
      }));
  
      // Check if the clicked menu has a subMenu
      const isParent = menuData.find((menu) => menu.key === key && menu.subMenu);
  
      if (isParent) {
        setActiveMenu(key);
      } else {
        handleNonParentMenuClick(key);
      }
    }
    setSelectedMenu(null); // Reset selectedMenu when clicking main menu
  };

  // Render sub-menu items
  const renderSubMenu = (subMenu, menuKey) => (
    <ul className='nav nav-treeview nav-subMenu'>
      {subMenu.map((item, index) => (
        <li key={index} className='nav-item sub-menu'>
          <Link
            to={item.link}
            className={`nav-link sub-link ${selectedMenu === `${menuKey}${index}` ? 'active' : ''}`}
            onClick={() => handleSubMenuClick(menuKey, index)}
          >
            {item.icon}
            <p>{item.label}</p>
          </Link>
        </li>
      ))}
    </ul>
  );

 // Render parent main menu items
const renderParentMainMenu = (item) => (
  <li key={item.key} className={`nav-item main-menu ${openSubMenu[item.key] ? 'open-menu' : ''}`}>
    <Link
      to={item.link}
      onClick={() => toggleSubMenu(item.key)}
      className={`nav-link main-link ${activeMenu === item.key ? 'selected' : ''}`}
    >
      {item.icon}
      <p>{item.label}</p>
      {item.subMenu && <MdOutlineKeyboardArrowLeft className="right fas fa-angle-left" />}
    </Link>
    {item.subMenu && openSubMenu[item.key] && renderSubMenu(item.subMenu, item.key)}
  </li>
);

// Render all menu items
const renderAllMenuItems = () => (
  <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
    {menuData.map((item) => (
      item.subMenu ? renderParentMainMenu(item) : (
        <li key={item.key} className='nav-item main-menu '>
          <Link
            to={item.link}
            onClick={item.onClick ||(() => handleNonParentMenuClick(item.key))}
            className={`nav-link main-link ${activeMenu === item.key ? 'active' : ''}`}
          >
            {item.icon}
            <p>{item.label}</p>
          </Link>
        </li>
      )
    ))}
  </ul>
);
  // Menus data
  const menuData = [
    {
      key: 'dashboard',
      label: 'Dashboard',
      icon: <AiFillDashboard className="icon-nav" />,
      link: '',
    },
    {
      key: 'salesOrder',
      label: 'Sales Order',
      icon: <FaShoppingCart className="icon-nav" />,
      subMenu: [
        { label: 'Product Link', icon: <FaLink />, link: 'ProductLink' },
        { label: 'Input Order', icon: <MdOutlineBorderColor />, link: 'InputOrder' },
        { label: 'Order History', icon: <FaHistory />, link: 'OrderHistoy' },
      ],
    },
    {
      key: 'wallet',
      label: 'Wallet',
      icon: <FaWallet className="icon-nav" />,
      subMenu: [
        { label: 'Wallet Details', icon: <IoIosWallet className='icon-submenu'/>, link: 'WalletDetails' },
        { label: 'Cash-out History', icon: <SiCashapp />, link: 'CashOutHistory' },
      ],
    },
    {
      key: 'logout',
      label: 'Logout',
      icon: <FaSignOutAlt className="icon-nav" />,
      onClick: handleSignOut,
    },
  ];

  // Dark Mode
  const [theme, setTheme] = useState(() => {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme || 'light';
  });

  useEffect(() => {
    document.body.classList.toggle('dark-mode', theme === 'dark');
  }, [theme]);

  // Save the theme to local storage
  const changeTheme = (value) => {
    const newTheme = value ? 'dark' : 'light';
    localStorage.setItem('theme', newTheme);
    setTheme(newTheme);
  };

  // Change the logo when light/dark mode toggle
  const getLogo = () => (theme === 'dark' ? LOGO_white : LOGO_green);

  return (
    <aside className={`main-sidebar elevation-4 ${theme === 'dark' ? 'dark-mode' : ''}`}>
      <div className="brand-link">
        <img src={getLogo()} alt="LOGO" className="brand-image" />
      </div>

      <div className="sidebar">
        <nav className="mt-2 side-navbar">
          
          {renderAllMenuItems()}

          <ul className="nav nav-pills nav-sidebar flex-column">
            <li className="nav-item">
              <div className="darkmode nav-link">
                <CgDarkMode className="icon-nav" />
                <p>
                  <Switch
                    className="switch"
                    checked={theme === 'dark'}
                    onChange={changeTheme}
                    checkedChildren="Dark"
                    unCheckedChildren="Light"
                  />
                </p>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </aside>
  );
}

export default SideNavbar;
