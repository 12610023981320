import React, { useEffect, useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { get, ref } from 'firebase/database';
import { db } from '../../../firebase/config';

const TotalSales = () => {
  const user = JSON.parse(localStorage.getItem('user'))
  const [data,setData] = useState([])
  const [totalSales, setTotalSales] = useState(0)
  const color = {
    " Chosen Choco Barley":"#5A3921",
    " Chosen Pure Barley Powder" :"#224B0C",
    " Ohayo Premium Kojic Soap" : "#D06224",
    " Chosen 15 in 1 Latte Coffee Mix" : "#876445",
    " Chosen One 17 in 1 Juice Drink" : "#d62728",
    "Chosen Choco Barley":"#5A3921",
    "Chosen Pure Barley Powder" :"#224B0C",
    "Ohayo Premium Kojic Soap" : "#D06224",
    "Chosen 15 in 1 Latte Coffee Mix" : "#876445",
    "Chosen One 17 in 1 Juice Drink" : "#d62728"
  }

  useEffect(() => {
    const fetchOrderItems = async () => {
      try {
        const ordersRef = ref(db, 'orders');
        const snapshot = await get(ordersRef);

        if (snapshot.exists()) {
          const orderItemsArray = Object.entries(snapshot.val())
            .filter(([orderId, orderData]) => (orderData.user === user.uid) && (orderData.status === 'Delivered'))
            .map(([orderId, orderData]) => ({
              orderId: orderId,
              ...orderData,
            }))
            .sort((b, a) => b.timestamp - a.timestamp);
            let overallSalesSum = 0;
            orderItemsArray.forEach((order, index) => {
              if ('sales' in order) {
                  overallSalesSum += order.sales;
              } else {
                  console.warn(`Order ${index + 1} does not have a valid sales property.`);
              }
          });
          setTotalSales(overallSalesSum);
          const transformedData = [];

          orderItemsArray.forEach((order) => {
            const month = new Date(order.timestamp).toLocaleString('en-US', { month: 'long', year: 'numeric'  });
            if (!transformedData.find(entry => entry.month === month)) {
              transformedData.push({
                month: month,
              });
            }
            order.items.forEach((product) => {
              const productName = product.name;
              const quantity = product.quantity;

              const monthIndex = transformedData.findIndex(entry => entry.month === month);
              if (monthIndex !== -1) {
                if (!transformedData[monthIndex][productName]) {
                  transformedData[monthIndex][productName] = quantity;
                } else {
                  transformedData[monthIndex][productName] += quantity;
                }
              }
            });
          });
          setData(transformedData);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchOrderItems();
  }, [user.uid]);
  
  return (
    <div className="card" style={{ maxHeight: '130vh', minHeight: '55vh' }}>
      <div className="card-header border-0">
        <h3 className="card-title">Total Sales Overview</h3>
      </div>

      <div className="d-flex justify-content-between pl-4 pr-4">
        <p className="d-flex flex-column">
          <span className='text-bold text-lg'>₱ {totalSales.toFixed(2).toLocaleString()}</span>
          <span>Total Sales</span>
        </p>
      </div>

      <div className="chart-container d-flex justify-content-center align-items-end">
      <ResponsiveContainer width="100%">
          <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip />
            <Legend />
            {data.length > 0 && Object.keys(data[0]).map((key, index) => {
              if (key !== 'month') {
                return <Bar key={index} dataKey={key} fill={color[key] || '#34deeb'} name={key} />;
              }
              return null;
            })}
          </BarChart>
        </ResponsiveContainer>

      </div>
    </div>
  );
};

export default TotalSales;
