import React, { useState, useEffect } from 'react';
import './StyleSheets/PageStyle.css';
import { FaCamera, FaRegSave } from 'react-icons/fa';
import Breadcrumb from '../../Assets/Breadcrumb/Breadcrumb';
import { message } from 'antd';
import { storage, db } from '../../firebase/config';
import { child, get, ref, push, update } from 'firebase/database';
import { uploadBytes,  ref as sRef, listAll, getDownloadURL } from 'firebase/storage';
import defaultprofile from '../../Assets/Images/default.png'
import { useUserContext } from '../context/UserContext';
import { Navigate, useNavigate } from 'react-router-dom';
import { updatePassword, EmailAuthProvider, reauthenticateWithCredential  } from "firebase/auth";
import { auth } from '../../firebase/config';


const MyAccount = () => {
  const { updateUserPhoto } = useUserContext();
  const breadcrumbPaths = [
    { label: 'Home', url: '/MyChosenDropPoint' },
    { label: 'My Account', url: '/MyAccount' },
  ];
  const email = JSON.parse(localStorage.getItem('user'))
  // const [editInfoForm, setEditInfoForm] = useState(false);
  const [dpChange, setDPChange] = useState(null)
  const user = auth.currentUser;
  const [formData, setFormData] = useState({
    phone: '',
    region: '',
    province: '',
    city: '',
    brgy: '',
    street: '',
    landmark: '',
  });
  // const [newFormData, setnewFormData] = useState({
  //   firstname: '',
  //   lastname:'',
  //   phone: '',
  //   region: '',
  //   province: '',
  //   city: '',
  //   brgy: '',
  //   street: '',
  //   landmark: '',
  // });
   const [userData, setUserData] = useState(null);
   const [latestPhotoUrl, setLatestPhotoUrl] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      get(child(ref(db), `users/${email.uid}`)).then((snapshot) => {
        if (snapshot.exists()) {
          setUserData(snapshot.val())
        } else {
          console.log("No data available");
        }
      }).catch((error) => {
        console.error(error);
      });
    }
    fetchUserData();
  }, [email.uid,]);

  useEffect(()=>{
    if (userData) {
        setFormData({
          username: userData.userName || '',
          memberID: userData.IdNum || '',
          phone: userData.phone || '',
          region: userData.region || '',
          province: userData.province || '',
          city: userData.city || '',
          brgy: userData.brgy || '',
          street: userData.street || '',
          landmark: userData.landmark || '',
      });
    }
  },[userData])

  useEffect(() => {
    const fetchLatestPhoto = async () => {
      try {
        const listProfile = sRef(storage, `users/${email.uid}/profile`);
        // Get a list of all items in the profile folder
        const listResult = await listAll(listProfile);
        
        // Find the latest image (assumed to be the last item in the list)
        const latestImage = listResult.items[listResult.items.length - 1];

        // Get the download URL of the latest image
        const url = await getDownloadURL(latestImage);
        // Set the state with the latest image URL
        setLatestPhotoUrl(url);
        updateUserPhoto(url)
      } catch (error) {
        console.error('Error fetching latest photo:', error.message);
      }
    };

    fetchLatestPhoto();
    
  }, [dpChange]);

  const  uploadImage = (userid,imageFile) =>{
  const originalFileName = imageFile.name;
  const fileExtension = originalFileName.split('.').pop().toLowerCase();
  const newFileName = 'profile.' + fileExtension;

    const storageRef = sRef(storage, `users/${userid}/profile/${newFileName}`);
    uploadBytes(storageRef, imageFile).then((snapshot) => {
      setDPChange(snapshot)
      updateUserPhoto(snapshot);
    });
  }
  // const handleEditClick = () => {
  //   setEditInfoForm((prev) => !prev);
  //   setnewFormData({
  //     phone: userData.phone || '',
  //     region: formData.region || '',
  //     province: formData.province || '',
  //     city: formData.city || '',
  //     brgy: formData.brgy || '',
  //     street: formData.street || '',
  //     landmark: formData.landmark || '',
  //   });
  //   console.log(newFormData)
  // };

  const handleFileChange = (e) => {
    uploadImage(email.uid,e.target.files[0])
  };

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   console.log(value)
  //   setnewFormData((prevFormData) => ({
  //     ...prevFormData,
  //     [name]: value,
  //   }));
  // };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   console.log('formdata', formData);
  //   const updates = {};
  //   updates['/users/' + email.uid + '/phone'] = newFormData.phone;
  //   updates['/users/' + email.uid + '/region'] = newFormData.region;
  //   updates['/users/' + email.uid + '/province'] = newFormData.province;
  //   updates['/users/' + email.uid + '/city'] = newFormData.city;
  //   updates['/users/' + email.uid + '/brgy'] = newFormData.brgy;
  //   updates['/users/' + email.uid + '/street'] = newFormData.street;
  //   updates['/users/' + email.uid + '/landmark'] = newFormData.landmark;
  //   setEditInfoForm(false);
    
  //   return update(ref(db), updates);
  // };
  const [OpenChangePass, setOpenChangePass] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const navigate = useNavigate();

  const handleOpenChangePass = () => {
    setOpenChangePass(true);
  }
  const handleCloseChangePass = () => {
    setOpenChangePass(false);
  }
  const submitNewPassword = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const user = auth.currentUser;

      const credentials = EmailAuthProvider.credential(
        user.email,
        oldPassword
      );

      await reauthenticateWithCredential(user, credentials);
      await updatePassword(user, newPassword);
      message.success('Password updated successfully!');
      setIsLoading(false);
      setOpenChangePass(false); 
      setOldPassword('')
      setNewPassword('')
    } catch (error) {
      message.error('Wrong Old password!');
      setOldPassword('')
      setNewPassword('')
      setIsLoading(false);
    }
  };
  
  
  return (
    <div id="myAccountContent">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Profile Settings</h1>
              <span>Customize your profile preferences.</span>
            </div>
            <div className="col-sm-6">
              <div className='container breadcrum-holder d-flex justify-content-end'>
                <Breadcrumb paths={breadcrumbPaths} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {formData && (
        <div className="content">
          <div className="container-fluid row">
            <div className="card-userPic col-lg-4 md-12">
              <form>
                <div className="userPic-holder">
                {latestPhotoUrl ? (
                        <div>
                          <img src={latestPhotoUrl} alt="Latest Uploaded" />
                        </div>
                      ) : (
                          <img src={defaultprofile} alt="Latest Uploaded" />
                      )}
                  <input
                    className="upload inputfile"
                    type="file"
                    capture="camera"
                    accept="image/*"
                    name="ProfilePic"
                    id="ProfilePic"
                    onChange={handleFileChange}
                  />
                  <label htmlFor="ProfilePic">
                    <span>
                        <FaCamera className="camera-icon" />
                    </span>
                  </label>
                </div>
              </form>
            </div>

            {/* {editInfoForm ? (
              <div className="card-editInfo col-lg-8 md-12">
                <form onSubmit={handleSubmit}>
                  <div className="userInfo-header">
                    <h1>Profile Information</h1>
                  </div>
                  <div className="userInfo-container row">
                    <div className="Fname-grp col-12 p-2">
                      <label>Full Name:</label>
                      <span>
                      {userData ? (
                        <p>{userData.firstname} {userData.lastname}</p>
                      ) : (
                        <p>None</p>
                      )}
                      </span>
                      <small className="text-muted">* This field is not editable</small>
                    </div>
                    <div className="Email-grp col-12 p-2">
                      <label>Email: </label>
                      <span>{email.email}</span>
                      <small className="text-muted">* This field is not editable</small>
                    </div>
                    <div className="Contact-grp col-12 p-2">
                      <label htmlFor="phone">Phone Number:</label>
                      <input
                        className="form-control"
                        type="text"
                        name="phone"
                        id="phone"
                        value={newFormData.phone}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                  </div>

                  <hr />

                  <div className="addressInfo-header">
                    <h1>Address Information</h1>
                  </div>
                  <div className="userAdd-container row">
                    <div className="Region-grp col-lg-6 md-12 p-2">
                      <label htmlFor="region">Region:</label>
                      <input
                        className="form-control"
                        type="text"
                        name="region"
                        id="region"
                        value={newFormData.region}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="Province-grp col-lg-6 md-12 p-2">
                      <label htmlFor="province">Province:</label>
                      <input
                        className="form-control"
                        type="text"
                        name="province"
                        id="province"
                        value={newFormData.province}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="Municipal-grp col-lg-6 md-12 p-2">
                      <label htmlFor="city">Municipality:</label>
                      <input
                        className="form-control"
                        type="text"
                        name="city"
                        id="city"
                        value={newFormData.city}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="Brgy-grp col-lg-6 md-12 p-2">
                      <label htmlFor="brgy">Brgy:</label>
                      <input
                        className="form-control"
                        type="text"
                        name="brgy"
                        id="brgy"
                        value={newFormData.brgy}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="Street-grp col-lg-6 md-12 p-2">
                      <label htmlFor="street">Street:</label>
                      <input
                        className="form-control"
                        type="text"
                        name="street"
                        id="street"
                        value={newFormData.street}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="Landmark-grp col-lg-6 md-12 p-2">
                      <label htmlFor="landmark">Landmark:</label>
                      <input
                        className="form-control"
                        type="text"
                        name="landmark"
                        id="landmark"
                        value={newFormData.landmark}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="editBtn">
                    <button type="submit" className="btn btn-success">
                      <FaRegSave /> Save Info
                    </button>
                  </div>
                </form>
              </div>
            ) : ( */}
              <div className="card-userInfo col-lg-8 md-12">
                <div className="userInfo-header">
                  <h1>Profile Information</h1>
                </div>
                <div className="userInfo-container row">
                  <div className="Fname-grp col-12 col-lg-6 p-2">
                    <label>Full Name:</label>
                    <span>
                    {userData ? (
                      <p>{userData.firstname} {userData.lastname}</p>
                    ) : (
                      <p>loading</p>
                    )}
                    </span>
                  </div>
                  <div className="Email-grp col-12 col-lg-6 p-2">
                    <label>Username: </label>
                    {userData ? (
                    <span>{userData.username}</span>
                    ) : (
                      <p>loading</p>
                    )}
                  </div>
                  <div className="Email-grp col-12 col-lg-6 p-2">
                    <label>Email: </label>
                    <span>{email.email}</span>
                  </div>
                  <div className="Email-grp col-12 col-lg-6 p-2">
                    <label>Member ID: </label>
                    {userData ? (
                    <span>{userData.IdNum}</span>
                    ) : (
                      <p>loading</p>
                    )}
                  </div>
                  <div className="Contact-grp col-12 col-lg-6 p-2">
                    <label>Phone Number:</label>
                    {userData ? (
                    <span>{userData.phone}</span>
                    ) : (
                      <p>loading</p>
                    )}
                  </div>
                  <div className="Contact-grp col-12 col-lg-6 p-2">
                    <label>Subscription Expiration:</label>
                    {userData ? (
                    <span>{userData.subscription_Expiration}</span>
                    ) : (
                      <p>loading</p>
                    )}
                  </div>
                  <div className="Contact-grp col-12 col-lg-6 p-2">
                  {OpenChangePass ? (
                    <button className='btn btn-warning' onClick={handleCloseChangePass}>
                      Cancel
                    </button>
                  ) : (
                    <button className='btn btn-primary' onClick={handleOpenChangePass}>
                      Change Password
                    </button>
                  )}
                  </div>
                  {OpenChangePass && (
                    <div className="changePassForm col-12">
                      <form action="" onSubmit={submitNewPassword}>
                        <div className="input-form">
      
                          <label htmlFor="password">Old Password:</label>
                          <input
                            className="form-control"
                            type="password"
                            name="password"
                            id="password"
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                            required
                          />
                        </div>
                        <div className="input-form">
      
                          <label htmlFor="newPassword">Enter New Password:</label>
                          <input
                            className="form-control"
                            type="password"
                            name="newPassword"
                            id="newPassword"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                          />
                        </div>
                        <div className="save-btn mt-3">
                          <button type="submit" className='btn btn-success' 
                              disabled={isLoading}>
                            {isLoading && (
                              <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                            )}
                            {isLoading ? 'Saving...' : 'Save New Password'}
                          </button>
                        </div>
                      </form>
                    </div>
                  )}
                </div>

                <hr />

                <div className="addressInfo-header">
                  <h1>Address Information</h1>
                </div>
                <div className="userAdd-container row">
                  <div className="Region-grp col-lg-6 md-12 p-2">
                    <label>Region:</label>
                    <span>{formData.region}</span>
                  </div>
                  <div className="Province-grp col-lg-6 md-12 p-2">
                    <label>Province:</label>
                    <span>{formData.province}</span>
                  </div>
                  <div className="Municipal-grp col-lg-6 md-12 p-2">
                    <label>Municipality:</label>
                    <span>{formData.city}</span>
                  </div>
                  <div className="Brgy-grp col-lg-6 md-12 p-2">
                    <label>Brgy:</label>
                    <span>{formData.brgy}</span>
                  </div>
                  <div className="Street-grp col-lg-6 md-12 p-2">
                    <label>Street:</label>
                    <span>{formData.street}</span>
                  </div>
                  <div className="Landmark-grp col-lg-6 md-12 p-2">
                    <label>Landmark:</label>
                    <span>{formData.landmark}</span>
                  </div>
                </div>
                {/* <div className="editBtn">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleEditClick}
                  >
                    <CiEdit /> Edit Info
                  </button>
                </div> */}
              </div>
            {/* )} */}
          </div>
        </div>
      )}
    </div>
  );
};

export default MyAccount;
