import React from 'react'
import './Footer.css'
import LOGO from '../../../Assets/Images/CDP_white_bigger.png'
import { Link } from 'react-router-dom'
import { IoIosArrowForward } from "react-icons/io";
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram, FaPhone } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

const Footer = () => {
  return (
    <footer >
         <div className="footer">
            <div className="container">
               <div className="row">
                  <div className="col-lg-3 col-md-6 col-sm-6">
                     <div className="footer-logo-holder">
                        <img src={LOGO} alt="LOGO ICON" />
                     </div>
                     <div className="follow">
                        <p>We'll ensure your order is processed promptly, and you can expect updates on its journey to the chosen drop point. We appreciate your trust in our service!</p>
                     </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6">
                     <h3>Quick link</h3>
                     <ul className="link_icon">
                        <li className="active"> <Link to='/'> <IoIosArrowForward/> Home</Link></li>
                        <li> <Link to='/About'> <IoIosArrowForward /> About </Link></li>
                        <li> <Link to='/Products'> <IoIosArrowForward/> Products</Link></li>
                     </ul>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6">
                     <h3>Social Media Accounts </h3>
                     <div className="follow">
                        <p>Follow us on our social media platforms </p>
                        <ul className="social_icon">
                           <li> <Link to='/'><FaFacebook/></Link></li>
                           <li> <Link to='/'><FaTwitter/></Link></li>
                           <li> <Link to='/'><FaLinkedin/></Link></li>
                           <li> <Link to='/'><FaInstagram/></Link></li>
                        </ul>
                     </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6">
                     <h3>Contact us</h3>
                     <ul className="location_icon">
                        <li className='email'><Link to='/Contact Us' className='d-flex justify-content-center align-item-center'>
                           <MdEmail className='mr-2'/><small>iamchoseninternational@gmail.com</small></Link>
                        </li>
                        <li><FaPhone className='mr-2'/> (02) – 7006-8924</li>
                     </ul>
                  </div>
               </div>
            </div>
            <div className="copyright">
               <div className="container">
                  <div className="row">
                     <div className="col-md-10 offset-md-1">
                        <p>Copyright 2024 All Right Reserved By Chosen DropPoint</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </footer>
  )
}

export default Footer
