import React, { useState, useEffect } from 'react'
import './LoginSignup.css'
import { Link, useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { IoIosArrowUp } from "react-icons/io";
import { FaUser } from "react-icons/fa";
import { FaLock } from "react-icons/fa";
import { IoCameraSharp } from "react-icons/io5";
import Navbar from '../../Components/NavBar/Nabvar';
import { regions, provinces, cities, barangays } from 'select-philippines-address';
import { signInWithEmailAndPassword, getAuth, createUserWithEmailAndPassword} from "firebase/auth";
import {storage, db, auth } from "../../../firebase/config"
import { ref, set , get,  query, orderByChild, equalTo } from "firebase/database";
import { uploadBytes,  ref as sRef } from 'firebase/storage';
import { Modal } from 'react-bootstrap';
import Terms from '../../../Assets/TermsCondition/TermsCondition'
import Policy from '../../../Assets/PrivatePolicy/PrivatePolicy'
import LOGO_green from '../../../Assets/Images/CDP_green.png';
import { compareAsc, parseISO } from 'date-fns';

const LoginSignup = () => {
  
  const [showForm, setshowForm] = useState(false);
  const [step, setStep] = useState(1);
  const [rememberPassword, setRememberPassword] = useState(false);
  const [checkTerms, setCheckTerms] = useState(localStorage.getItem('agreed') === 'true');
  const [showTerms, setShowTerms] = useState(!checkTerms);
  const [checkPolicy, setCheckPolicy] = useState(false);
  const [showPolicy, setShowPolicy] =useState(false);
  const [fileName, setFileName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate(); 
  const [imageFile, setImageFile] = useState(null)
  const [username, setUserName] = useState('')
  const [newEmail, setNewEmail] = useState('')
  const [Pass, setPass] = useState('')
  const [ConfirmPass, setConfirmPass] = useState('')
  const [regionData, setRegionData] = useState([]);
  const [provinceData, setProvinceData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [barangayData, setBarangayData] = useState([]);
  const [errorRegMessage, setErrorRegMessage] = useState('');
  const toggleForm = () => {
      setStep(1);
      setshowForm(!showForm);
  };

  const [isSelectOpen, setSelectOpen] = useState (false);
  const toggleSelect = () => {
    setSelectOpen (!isSelectOpen);
  }

  const [selectedDate, setSelectedDate] = useState(null);
  const [formData, setFormData] = useState({
      firstname: '',
      middlename: '',
      lastname: '',
      KYCIdNum: '',
      IdNum: '',
      phone: '',
      gender: '',
      birthday: '',
      region: '',
      province: '',
      city: '',
      brgy: '',
      street: '',
      landmark: '',
      postalcode: '',
      username:'',
      email: '',
      status: 'Pending'
  });
  useEffect(() => {
    // Update the state based on the value stored in localStorage
    setCheckTerms(localStorage.getItem('agreed') === 'true');
  }, []);
  useEffect(() => {
    fetchRegions();
  }, []);
  const [isIdPictureUploaded, setIsIdPictureUploaded] = useState(false);
  const [messageProceed, setMessageProceed] = useState('');

  const handleSubmit = async  (e) => {
      e.preventDefault();
      formData.username = username
      formData.email = newEmail
      const auth = getAuth();
      const usernameExist = await checkUserName(username);
      if (step === 1) {
        if (usernameExist) {
          setErrorRegMessage("Username is already used. Please choose another one!");
          return;
        }
        if(Pass != ConfirmPass) {
          setErrorRegMessage("Password and confirm password not matched!");
          return;
        }
        nextStep();
      } else if (step === 2) {
        // Add a condition to check for the ID picture here
        if (!isIdPictureUploaded) {
          setMessageProceed("Please upload your Government ID picture before proceeding to the next step.");
          return;
        }
        nextStep();
      } else if (step === 3) {
        if (formData.IdNum.length != 7) {
          setMessageProceed("The ID Number must contain 7 Numbers");
          return; 
        }
        nextStep();
      } else if (step === 4) {
        nextStep();
      } else if (step === 5) {
        if (!usernameExist) {
        if(Pass === ConfirmPass){
        try {
          createUserWithEmailAndPassword(auth, newEmail, ConfirmPass).then(userCredential => {   
            const user = userCredential.user;
            formData.userID = user.uid;
            set(ref(db, `users/${user.uid}`), 
            formData
         ).then( async()=>{
          uploadImage(user.uid)
        const subject = `Your account has been created successfully`
        const email = formData.email
        const response = await fetch('https://cdp-email-bef0d127fa8b.herokuapp.com/register', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email, formData, subject }),
        });
        if (response.ok) {
          console.log('Email sent successfully');
          window.location.reload()
        } else {
          console.error('Failed to send email');
        }
         })
 
            alert('Successfully Registered');
          }).catch(error => {
            switch (error.code) {
               case 'auth/email-already-in-use':
                alert(`email address ${newEmail} already in use.`);
                 break;
               case 'auth/invalid-email':
                alert(`email address ${newEmail} is invalid.`);
                 break;
               case 'auth/operation-not-allowed':
                alert(`error during sign up.`);
                 break;
               case 'auth/weak-password':
                alert('password is not strong enough. add additional characters including special characters and numbers.');
                 break;
               default:
                alert(error.message);
                 break;
             }
         });
       } catch (error) {
          alert('Error occurred during registration');
       }
    }else{
      alert("Password Not Match!")
    }
  } else {
    alert(`Username: "${username}" already exists`);
 }
  };
  }
  const  uploadImage = (userid) =>{
    const storageRef = sRef(storage, `users/${userid}/id/${fileName}`);
    uploadBytes(storageRef, imageFile).then((snapshot) => {
      console.log('Uploaded a blob or file!');
    });
  }
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImageFile(file)
      const fileName = file.name;
      setFileName(fileName); 
      setIsIdPictureUploaded(true);
      setMessageProceed(false);
    }
  };

  const nextStep = () => {
    if (step === 1) {
      if(Pass != ConfirmPass) {
        setErrorRegMessage("Password and confirm password not matched!");
        return;
      }
    }
    if (step === 2) {
      if (!isIdPictureUploaded) {
        setMessageProceed("Please upload your Government ID picture before proceeding to the next step.");
        return; 
      }
    }
    setStep(step + 1);
  };
  if (step === 3) {

  }

  const prevStep = () => {
      setStep(step - 1);
  };

  const checkUserName = async (username) => {
    try {
      const snapshot = await get(query(ref(db, 'users'), orderByChild('username'), equalTo(username)));
      if (snapshot.exists()) {
        console.log(true);
        return true;
      } else {
        console.log(false);
        return false; 
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const checkStatus = async (email) => {
    try {
      const snapshot = await get(query(ref(db, 'users'), orderByChild('email'), equalTo(email)));
      
      if (snapshot.exists()) {
        const userData = snapshot.val();
        const flattenedUserData = Object.entries(userData).reduce((acc, [key, value]) => {
          if (typeof value === 'object' && value !== null) {
            Object.entries(value).forEach(([nestedKey, nestedValue]) => {
              acc[`${nestedKey}`] = nestedValue;
            });
          } else {
            acc[key] = value;
          }
          return acc;
        }, {});
        return flattenedUserData.status;
      } else {
        setErrorMessage('Email/Password not Found');
        return false;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };
  const checkExpiration = async (email) => {
    try {
      const snapshot = await get(query(ref(db, 'users'), orderByChild('email'), equalTo(email)));
      
      if (snapshot.exists()) {
        const userData = snapshot.val();
        const flattenedUserData = Object.entries(userData).reduce((acc, [key, value]) => {
          if (typeof value === 'object' && value !== null) {
            Object.entries(value).forEach(([nestedKey, nestedValue]) => {
              acc[`${nestedKey}`] = nestedValue;
            });
          } else {
            acc[key] = value;
          }
          return acc;
        }, {});
        return flattenedUserData.subscription_Expiration;
      } else {
        setErrorMessage('Email/Password not Found');
        return false;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };
  
  
  const handleLogin = async (e) => {
    e.preventDefault();

    const enteredUsername = e.target.elements.email.value;
    const enteredPassword = e.target.elements.password.value;
    if(await checkStatus(enteredUsername) === "Approved"){
    try {
      const userCredential = await signInWithEmailAndPassword(auth, enteredUsername, enteredPassword);
      if (userCredential.user) {
          if (isSubscriptionExpired(await checkExpiration(enteredUsername))) {
            setErrorMessage('Your subscription has expired. Please renew to continue.');
            return; 
          }

        localStorage.setItem('token', userCredential.user.accessToken);
        localStorage.setItem('user', JSON.stringify(userCredential.user));
        navigate('/MyChosenDropPoint');
      } 
    } catch (error) {
      switch (error.code) {
        case 'auth/too-many-requests':
         setErrorMessage(`Too many Attemps, Reset your Password.`);
          break;
        case 'auth/invalid-email':
          setErrorMessage(`Email address ${newEmail} is invalid. Please check and try again.`);
          break;
        case 'auth/user-not-found':
          setErrorMessage(`Invalid email. No account found for the provided email address. 
                Please check your email or sign up for a new account.`);
          break;
        case 'auth/wrong-password':
          setErrorMessage('Incorrect password. Please try again.');
          break;
        case 'auth/user-disabled':
          setErrorMessage('Account has been disabled by an administrator.');
          break;
        case 'auth/invalid-credential':
            setErrorMessage('Incorrect password. Please try again.');
            break;
        default:
          setErrorMessage(error.message);
          break;
      }
    }
    localStorage.setItem('agreed', 'true');
  }else{
    setErrorMessage(`Please wait for Admin to verify your account`)
  }
  };

  const truncateString = (str, maxLength) => {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + '...';
    }
    return str;
  };
const isSubscriptionExpired = (expirationDate) => {

  if (expirationDate) {
    try {
      const currentDateTime = new Date();
      const subscriptionExpirationDate = new Date(Date.parse(expirationDate));

      const comparisonResult = compareAsc(currentDateTime, subscriptionExpirationDate);
      return comparisonResult === 1; // 1 means currentDateTime is after subscriptionExpirationDate
    } catch (error) {
      console.error('Error parsing date:', error);
      return false;
    }
  }

  return false;
};

  const fetchRegions = () => {
    regions()
      .then(response => {
        setRegionData(response);
        setFormData(prevData => ({ ...prevData, region: response?.region_code || '' }));
      })
      .catch(error => console.error('Error fetching regions:', error));
  };
  const fetchProvinces = region_code => {
    provinces(region_code).then(response => {
      setProvinceData(response);
    });
  };
  const fetchCities = provinceCode => {
    cities(provinceCode).then(response => {
      setCityData(response);
    });
  };
  const fetchBarangays = cityCode => {
    barangays(cityCode).then(response => {
      setBarangayData(response);
    });
  };


  const onChangeRegion = (e) => {
    const selectedRegionCode = e.target.value;
    const selectedRegion = regionData.find((item) => item.region_code === selectedRegionCode);
    setFormData({
      ...formData,
      region:  selectedRegion ? selectedRegion.region_name : '',
    });
    fetchProvinces(selectedRegionCode);
    setProvinceData([]);
    setCityData([]);
    setBarangayData([]);
  };

  const onChangeProvince = (e) => {
    const selectedProvinceCode = e.target.value;
    const selectedProvince = provinceData.find((item) => item.province_code === selectedProvinceCode);
    setFormData({
      ...formData,
      province:  selectedProvince ? selectedProvince.province_name : '',
    });
    fetchCities(selectedProvinceCode);
    setCityData([]); 
    setBarangayData([]);
  };

  const onChangeCity = (e) => {
    const selectedCityCode = e.target.value;
    const selectedCity = cityData.find((item) => item.city_code === selectedCityCode);
    setFormData({
      ...formData,
      city: selectedCity ? selectedCity.city_name : '',
    });
    fetchBarangays(selectedCityCode);
    setBarangayData([]); 
  };

  const onChangeBarangay = (e) => {
    const selectedBarangayCode = e.target.value;
    const selectedBarangay = barangayData.find((item) => item.brgy_code === selectedBarangayCode);
    setFormData({
      ...formData,
      brgy: selectedBarangay ? selectedBarangay.brgy_name : '',
    });
  };
  
  const handleCheckboxChange = () => {
    setCheckTerms(!checkTerms);
    setShowTerms(!checkTerms); 
  };

  const handlePolicy = () => {
    setCheckPolicy(!checkPolicy);
    setShowPolicy(!checkPolicy);
  }

  return (
    <>
    <Navbar/>
    <div id="container" className='container-fluid'>
      <div className={`row ${showForm ? 'sign-up' : 'sign-in'}`}>
      {showForm ? (
        <>
        <div className={`col form-content flex-col ${showForm ? 'sign-up' : 'sign-in'}`}>
          <div className="form-wrapper align-items-center">
            <form onSubmit={handleSubmit} method="" action="" className="form sign-up">
              
              <div className='step-indicator'>
                <div className={`step ${step === 1 ? 'active' : step > 1 ? 'completed' : ''}`}>1</div>
                <div className='line'></div>
                <div className={`step ${step === 2 ? 'active' : step > 2 ? 'completed' : ''}`}>2</div>
                <div className='line'></div>
                <div className={`step ${step === 3 ? 'active' : step > 3 ? 'completed' : ''}`}>3</div>
                <div className='line'></div>
                <div className={`step ${step === 4 ? 'active' : step > 4 ? 'completed' : ''}`}>4</div>
                <div className='line'></div>
                <div className={`step ${step === 5 ? 'active' : step > 5 ? 'completed' : ''}`}>5</div>
              </div>
                
                {step === 1 && (
                  <>
                    <div className='step-title'>
                      <span>Step 1: Account Information</span>
                    </div>
                    {errorRegMessage ? (
                      <div className='alert alert-danger p-2 mt-2'>{errorRegMessage}</div>
                    ):( <></>
                    )}
                    <div className="input-group">
                      <input
                        type="text"
                        placeholder="Username"
                        name='Username'
                        value={username}
                        onChange={(e) => setUserName(e.target.value)}
                        required />
                    </div>
                    {showForm && (
                      <div className="input-group">
                        <input
                          type="email"
                          placeholder="Email"
                          name='Email'
                          value={newEmail}
                          onChange={(e) => {
                            setNewEmail(e.target.value)
                            setErrorRegMessage('');
                          }}
                          required />
                      </div>
                    )}
                    <div className="input-group">
                      <input
                        type="password"
                        placeholder="Password"
                        name='password'
                        value={Pass}
                        onChange={(e) => {
                          setPass(e.target.value);
                          setErrorRegMessage('');
                        }}
                        required />
                    </div>
                    {showForm && (
                      <div className="input-group">
                        <input
                          type="password"
                          placeholder="Confirm password"
                          name='confirmPassword'
                          value={ConfirmPass}
                          onChange={(e) => {
                            setConfirmPass(e.target.value);
                            setErrorRegMessage('');
                          }}
                          required />
                      </div>
                    )}
                    <div className='nextprev-btn'>
                      <button id='form-btn' type="submit" className='next-btn'>Next</button>
                    </div>

                    <p>
                      <span>Already have an account? </span>
                      <b onClick={toggleForm} className="pointer">
                        {showForm ? 'Login here!' : 'Sign up here'}
                      </b>
                    </p>
                  </>
                )}
                {step === 2 && (
                  <>
                  <div className='step-title'>
                    <span>Step 2: Personal Information</span>
                  </div>
                  {messageProceed ? (
                    <div className="alert alert-danger p-2 mt-2">{messageProceed}</div>
                  ) : (
                    <></>
                  )}
                  
                  
                    <div className="input-group">
                      <input
                        type="text"
                        placeholder="First Name"
                        name='firstname'
                        value={formData.firstname}
                        onChange={(e) => setFormData({ ...formData, firstname: e.target.value })}
                        required />
                    </div>
                    <div className="input-group">
                      <input
                        type="text"
                        placeholder="Middle Name"
                        name='middlename'
                        value={formData.middlename}
                        onChange={(e) => setFormData({ ...formData, middlename: e.target.value })}
                        required />
                    </div>
                    <div className="input-group">
                      <input
                        type="text"
                        placeholder="Last Name"
                        name='lastname'
                        value={formData.lastname}
                        onChange={(e) => setFormData({ ...formData, lastname: e.target.value })}
                        required />
                    </div>
                    <div className="input-group row">
                      <input  className='col-6'
                        type="text"
                        placeholder="Government ID Number"
                        name='KYCIdNum'
                        value={formData.KYCIdNum}
                        onChange={(e) => setFormData({ ...formData, KYCIdNum: e.target.value })}
                        required />

                        <input className='upload inputfile'
                        type="file"
                        capture="camera"
                        accept="image/*"
                        name="IdPicture"
                        value={formData.IdPicture}
                        onChange={handleImageUpload}
                        id="IdPicture"/>
                        <label htmlFor="IdPicture" className='col-6 IDpic'>
                          <span>
                            <IoCameraSharp/> {truncateString(fileName, 20) || 'Choose a file...'}
                          </span>
                        </label>
                    </div>
                    <div className='nextprev-btn'>
                      <button id='form-btn' type="button" onClick={prevStep} className='prev-btn'>
                        Back
                      </button>
                      <button id='form-btn' type="submit" className='next-btn'>Next</button>
                    </div>
                  </>
                )}
                {step === 3 && (
                  <>

                    <div className='step-title'>
                      <span>Step 3: Contact Information</span>
                    </div>
                    {messageProceed ? (
                    <div className="alert alert-danger p-2 mt-2">{messageProceed}</div>
                  ) : (
                    <></>
                  )}
                    <div className="input-group">
                                      <input
                      type="text"
                      placeholder="Member ID Number"
                      value={formData.IdNum}
                      onChange={(e) => {
                          const input = e.target.value;
                          // Check if input consists of exactly 6 digits
                          if (/^\d{0,7}$/.test(input)) {
                              // If the input is valid (6 digits or less), update the state
                              setFormData({ ...formData, IdNum: input });
                          }
                          // If the input is more than 6 digits, it won't update the state
                      }}
                      required 
                  />

                    </div>
                    <div className="input-group">
                      <input
                        type="text"
                        placeholder="Phone Number"
                        value={formData.phone}
                        onChange={(e) => {
                          const input = e.target.value;
                          if (input.length <= 11) {
                            setFormData({ ...formData, phone: input });
                          }
                        }}
                        required />
                    </div>
                    <div className="input-group">
                        <select className={`select-control ${isSelectOpen ? 'open' : ''}`} onClick={toggleSelect}
                          value={formData.gender}
                          onChange={(e) => setFormData({ ...formData, gender: e.target.value })}
                          required>
                            <option value="" disabled hidden>Choose your gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                        </select>
                        <IoIosArrowUp id='dropdown-arrow'/>
                    </div>
                    <div className="input-group">
                        <DatePicker className='select-control'
                          selected={selectedDate}

                          placeholderText="Select your Birth date"
                          name='birthday'
                          onChange={(date) => {
                            setSelectedDate(date);
                            setFormData({ ...formData, birthday: date });
                          }}
                          dateFormat="MM/dd/yyyy"
                          required />
                    </div>
                    <div className='nextprev-btn'>
                      <button id='form-btn' type="button" onClick={prevStep} className='prev-btn'>
                        Back
                      </button>
                      <button id='form-btn' type="submit" className='next-btn'>Next</button>
                    </div>
                  </>
                )}
                {step === 4 && (
                  <>
                    <div className='step-title'>
                      <span>Step 4: Address Information</span>
                    </div>
                    <div className='input-group'>
                      <select
                      name='region'
                        onChange={onChangeRegion}
                        required
                      >
                        <option value='' disabled>
                          Select Region
                        </option>
                        {regionData.map(item => (
                          <option
                            key={item.region_code}
                            value={item.region_code}
                          >
                            {item.region_name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className='input-group'>
                      <select
                        onChange={onChangeProvince}
                        required
                      >
                        <option value='' disabled>
                          Select Province
                        </option>
                        {provinceData.map(item => (
                          <option
                            key={item.province_code}
                            value={item.province_code}
                          >
                            {item.province_name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className='input-group'>
                      <select
                        onChange={onChangeCity}
                        required
                      >
                        <option value='' disabled>
                          Select City / Municipality
                        </option>
                        {cityData.map(item => (
                          <option 
                            key={item.city_code} 
                            value={item.city_code}
                          >
                            {item.city_name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className='input-group'>
                      <select
                        onChange={onChangeBarangay}
                        required
                      >
                        <option value='' disabled>
                          Select Barangay
                        </option>
                        {barangayData.map(item => (
                          <option 
                            key={item.brgy_code} 
                            value={item.brgy_code}
                          >
                            {item.brgy_name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className='nextprev-btn'>
                      <button id='form-btn' type="button" onClick={prevStep} className='prev-btn'>
                        Back
                      </button>
                      <button id='form-btn' type="submit"className='next-btn'>Next</button>
                    </div>
                  </>
                )}
                {step === 5 && (
                  <>
                  <div className='step-title'>
                      <span>Step 5: Additional Information</span>
                    </div>
                    <div className="input-group">
                      <input
                        type="text"
                        placeholder="Street"
                        name="street"
                        value={formData.street}
                        onChange={(e) => setFormData({ ...formData, street: e.target.value })}
                        required />
                    </div>
                    <div className="input-group">
                      <input
                        type="text"
                        placeholder="Landmark"
                        name="landmark"
                        value={formData.landmark}
                        onChange={(e) => setFormData({ ...formData, landmark: e.target.value })}
                        required />
                    </div>
                    <div className="input-group">
                      <input
                        type="text"
                        placeholder="Postal Code"
                        name="postalcode"
                        value={formData.postalcode}
                        onChange={(e) => setFormData({ ...formData, postalcode: e.target.value })}
                        required />
                    </div>
                    <div className="d-flex justify-content-start align-items-center" style={{marginBottom: '15px'}}>
                      <input 
                        type="checkbox"
                        id="policy"
                        checked={checkPolicy}
                        onChange={handlePolicy}
                        required/>
                      <label htmlFor="policy" className='mb-0 ml-1'>I accept Private Policy.</label>
                    </div>

                    {showPolicy && (
                      <Modal show={showPolicy} size='xl' centered>
                      <Modal.Header>
                        <Modal.Title>Privacy Policy</Modal.Title>
                        
                      </Modal.Header>
                      <Modal.Body>
                      <section>
                        <Policy/>
                      </section>
                      </Modal.Body>
                      <Modal.Footer className="d-flex justify-content-end">
            
                        <div className="agree-btn">
                          <button
                            type='button'
                            onClick={() => setShowPolicy(false)}
                            className='btn btn-success'
                          >
                             I agree!
                          </button>
                        </div>
            
                      </Modal.Footer>
                      </Modal>
                    )}

                    <div className='nextprev-btn'>
                      <button id='form-btn' type="button" onClick={prevStep} className='prev-btn'>
                        <i className="fa-solid fas fas-arrow-left"></i>
                        Back
                      </button>
                      
                      <button id='form-btn' type="submit" className='submit-btn'>
                        {showForm ? 'Sign Up' : 'Sign in'}
                      </button>
                    </div>
                  </>
                )}
            </form>
          </div>
        </div>
        <div className='col w-100'></div>
       </>
      ) : (
        <>
        <div className={`col form-content flex-col ${showForm ? 'sign-in' : 'sign-up'}`}>
          <div className="form-wrapper align-items-center">
            <form onSubmit={handleLogin} method='' action='' className="form sign-in">
            <div className="logo">
              <img src={LOGO_green} draggable="false" alt="Logo" />
            </div>
              {errorMessage ? (
              <div className='error-message'>
                <p style={{whiteSpace: 'pre-line'}}>{errorMessage}</p>
              </div>
              ):(<></>)}
              <div className="input-group" id='login'>
                <FaUser id='svg_icon'/>
                <input type="email" 
                       placeholder="Email" 
                       name='email'
                       required/>
              </div>
              <div className="input-group" id='login'>
                <FaLock id='svg_icon'/>
                <input type="password" 
                       placeholder="Password" 
                       name='password'
                       required/>
              </div>
              <div className="terms mb-3 ml-3 d-flex justify-content-start">
                <div className='d-flex align-items-center'>
                  <input 
                    type="checkbox"
                    id="terms&con"
                    checked={checkTerms}
                    onChange={handleCheckboxChange}
                    required/>
                  <label htmlFor="terms&con" className='mb-0 ml-1'>I agree with the Terms and Conditions.</label>
                </div>
              </div>
              {showTerms && (
                <Modal show={checkTerms} size='xl' centered>
                <Modal.Header>
                  <Modal.Title>DISTRIBUTOR’S TERM AND CONDITIONS</Modal.Title>
                  
                </Modal.Header>
                <Modal.Body>
                  <section>
                    <Terms/>
                  </section>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-end">
      
                  <div className="agree-btn">
                    <button
                      type='button'
                      onClick={() => setShowTerms(false)}
                      className='btn btn-success'
                    >
                       I agree!
                    </button>
                  </div>
      
                </Modal.Footer>
                </Modal>
              )}
              <button id='form-btn' type='sumbit'>
                Log In
              </button>
              <p className='d-flex align-items-center justify-content-between link-forget'>
                <div className='d-flex align-items-center'>
                  <input 
                    type="checkbox"
                    id="rememberPassword"
                    checked={rememberPassword}
                    onChange={() => setRememberPassword(!rememberPassword)}/>
                  <label htmlFor="rememberPassword" className='mb-0 ml-1'>Remember Password</label>
                </div>
                <Link to='/forgetpassword'>
                  Forgot password?
                </Link>
              </p>
              <p>
                <span>Don't have an account? </span>
                <b onClick={toggleForm} className="pointer">
                  {showForm ? 'Sign in here' : 'Sign up here'}
                </b>
              </p>
            </form>
          </div>
        </div>
        <div className='col w-100'></div>
        </>
      )}  
      </div>

      <div id='form-bg' className={`content-row ${showForm ? 'sign-up' : 'sign-in'} align-items-center flex-col`} >
        <div className={`img ${showForm ? 'sign-up' : 'sign-in'}`}>
          <div className={`text ${showForm ? 'sign-up' : 'sign-in'}`}>
            <h2>{showForm ? 'Welcome to Chosen Drop Point' : 'Greetings!'}</h2>
            <p>
              {showForm ? 'Create your account and start using our services today!' : 'Welcome to Your One-Stop Dropshipping Solution!'}
            </p>
            </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default LoginSignup;
