import React, { useEffect, useState } from 'react';
import './Products.css';
import Breadcrumb from '../../../Assets/Breadcrumb/Breadcrumb';
import Navbar from '../../Components/NavBar/Nabvar';
import Footer from '../../Components/Footer/Footer';
import { message, Popconfirm } from 'antd';
import { Modal } from 'react-bootstrap';
import { regions, provinces, cities, barangays } from 'select-philippines-address';
import { useLocation } from 'react-router-dom';
import { FaMinus, FaPlus } from "react-icons/fa6";
import { FaTrashAlt } from "react-icons/fa";
import { MdShoppingCartCheckout } from "react-icons/md";
import { BiSolidCartAdd } from "react-icons/bi";
import { db,storage } from '../../../firebase/config';
import { get, child, ref, set,serverTimestamp } from 'firebase/database';
import { getDownloadURL, ref as storageRef ,listAll} from 'firebase/storage';
const Products = () => {
  const breadcrumbPaths = [
    { label: 'Home', url: '/' },
    { label: 'Products', url: '/products' },
  ];

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('COD');
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [remainingProducts, setRemainingProducts] = useState([]);
  const [hoveredCard, setHoveredCard] = useState(null);
  const [clickProduct, setClickProduct] = useState(false);
  const [showCartList, setShowCartList] = useState(false);
  const [quantities, setQuantities] = useState({});
  const [cartItems, setCartItems] = useState([]);
  const cartItemCount = cartItems.length;
  const [checkOut, setCheckOut] = useState(false);
  const widerModalClass = 'wide_modal';
  const handleClose = () => setShowCartList(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFormFilled, setIsFormFilled] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [total, setTotal] = useState(0);
  const [shippingForm, setShippingForm] = useState({
    Fullname: '',
    Email: '',
    ContactNum: '',
    PaymentMethod: '',
    region: '',
    regionName: '',
    province: '',
    provinceName: '',
    city: '',
    cityName: '',
    brgy: '',
    brgyName: '',
    street: '',
    landmark: '',
    postalCode: '',
    shippingFee: 0.0,
  });

  useEffect(() => {
    const checkFormFilled = () => {
      const requiredFields = ['Fullname', 'ContactNum', 'Email', 'region', 'province', 'city', 'brgy', 'postalCode'];
      return requiredFields.every(field => !!shippingForm[field]);
    };
    setIsFormFilled(checkFormFilled());
  }, [shippingForm]);

  useEffect(() => {
    get(child(ref(db), 'products')).then(async (snapshot) => {
      if (snapshot.exists()) {
        const productsData = snapshot.val();
        const productsArray = Object.values(productsData);
  
        const productsWithImages = await Promise.all(productsArray.map(async (product) => {
          const imageRef = storageRef(storage, `products/${product.id}/${product.id}.png`); 
          try {
            const imageUrl = await getDownloadURL(imageRef);
            const otherImageRef = storageRef(storage, `products/${product.id}/other`);
            const otherImagesResult = await listAll(otherImageRef);
            const otherImages = await Promise.all(otherImagesResult.items.map(async (item) => {
              return getDownloadURL(item);
            }));
            const limitedOtherImages = otherImages.slice(0, 3);

            const imagesArray = [imageUrl, ...limitedOtherImages];
            return { ...product, image: imageUrl, otherImage:imagesArray };
          } catch (error) {
            console.error('Error fetching image:', error);
          }
        }));
        setRemainingProducts(productsWithImages);
      } else {
        console.log('No data available');
      }
    }).catch((error) => {
      console.error(error);
    });
  }, []);

  const generateOrderID = () => {
    const numericPart = Math.floor(Math.random() * 1000000000).toString().padStart(9, '0');
    const alphanumericCharacter = String.fromCharCode(65 + Math.floor(Math.random() * 26)); 
    const parcelID = `CDP${numericPart}${alphanumericCharacter}`;
    return parcelID;
  };
  const generateInvoiceID = () => {
    const numericPart = Math.floor(Math.random() * 1000000).toString().padStart(6, '0');
    const alphanumericCharacter = String.fromCharCode(65 + Math.floor(Math.random() * 26)); 
    const parcelID = `CDP${numericPart}${alphanumericCharacter}`;
    return parcelID;
  };
  const handleProduct = (product) => {
    const isProductInRemaining = remainingProducts.some((p) => p.id === selectedProduct?.id);
    if (selectedProduct && !isProductInRemaining) {
      setRemainingProducts((prevProducts) => [...prevProducts, selectedProduct]);
    }
    setSelectedProduct({
      ...product,
      image: product.image 
    });

    setRemainingProducts((prevProducts) =>
      prevProducts.filter((p) => p.id !== product.id)
    );

    setQuantities({});
  
    setClickProduct(true);
  };
  
  const handleProceedPurchase = async ()=>{
    setIsLoading(true);
    try {
      await new Promise(resolve => setTimeout(resolve, 1500));
      const orderID = generateOrderID();
      const InvoiceID = generateInvoiceID();
      const timestamp = serverTimestamp(); 
      const orderData = {        
        invoice: {
        From: {
          Name: 'Chosen Drop Point, Inc.',
          Address: 'Unit B-02 Lower 2nd Floor St. Francis Square Building Julia Vargas Avenue cor. Bank Drive Ortigas Center Mandaluyong',
          Phone: '(02) – 7006-8924',
          Email: 'iamchoseninternational@gmail.com',
        },
        To: {
          Name: shippingForm.Fullname,
          Address: shippingForm.landmark+", "+shippingForm.brgyName+", "+ shippingForm.cityName+", "+shippingForm.provinceName +", "+shippingForm.regionName +", '"+shippingForm.postalCode+ "'",
          Phone: shippingForm.ContactNum,
          Email: shippingForm.Email ,
        },
        InvoiceNumber: InvoiceID,
        OrderID: orderID,
        PaymentMethod: selectedPaymentMethod,
        items: cartItems.map((item) => ({
          name: item.name,
          quantity: item.quantity,
          price: item.price,
          productId: item.id,
        })),
        AmountDue: {
          Subtotal: calculateTotal(),
          Shipping:shippingFee,
          Total: calculateTotalCost(),
        },
      },
      address: {
        fullAddress:shippingForm.brgyName+", "+ shippingForm.cityName+", "+shippingForm.provinceName,
        street: shippingForm.street,
        landmark: shippingForm.landmark,
      },
        user: 'N/A',
        seller: 'Company',
        status: "Pending",
        total: calculateTotal(),
        companySales:calculateTotal(),
        sales:0,
        timestamp: timestamp,
        MemberID: 'N/A',
        customer:{
          Email:shippingForm.Email,
          Fullname:shippingForm.Fullname,
          ContactNum:shippingForm.ContactNum,
          PostalCode: shippingForm.postalCode,
          Region: shippingForm.regionName
        },
        items: cartItems.map((item) => ({
          productId: item.id,
          name: item.name,
          quantity: item.quantity,
          price: item.price,
          dp:item.dp,
          TotalPrice: item.price * item.quantity,
        })),

      };
      await set(ref(db, `orders/${orderID}`), orderData);
      setCartItems([]);
      setShippingForm({
        Fullname: '',
        ContactNum: '',
        Email: '',
        region: '',
        regionName: '',
        province: '',
        provinceName: '',
        city: '',
        cityName: '',
        brgy: '',
        brgyName: '',
        street: '',
        landmark: '',
        postalCode: '',
        PaymentMethod: '',
        total: 0.0,
        shippingFee: 0.0,
        totalCost: 0.0
      });
      setCheckOut(false);
      const subject = `Order ${orderID} is Successful wait for Admin Approval`
      const email = shippingForm.Email
      const response = await fetch('https://cdp-email-bef0d127fa8b.herokuapp.com/sendEmail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, orderData, subject }),
      });
      if (response.ok) {
        console.log('Email sent successfully');
      } else {
        console.error('Failed to send email');
      }
      message.success('Ordered Successfully');
      setIsLoading(false); 
    } catch (error) {
      console.error('Error placing order:', error);
      setIsLoading(false);
    }
  };
  const handleHoverImage = (img) => {
    setSelectedProduct((prevProduct) => ({
      ...prevProduct,
      image: img,
    }));
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-PH', { style: 'currency', currency: 'PHP' }).format(amount);
  };

  const handleDecrement = (productId) => {
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [productId]: Math.max((prevQuantities[productId] || 1) - 1, 1),
    }));
  };

  const handleIncrement = (productId) => {
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [productId]: Math.min((prevQuantities[productId] || 1) + 1, 50),
    }));
  };

  const handleAddToCart = (product, quantity) => {
    const existingItemIndex = cartItems.findIndex((item) => item.id === product.id);
    if (existingItemIndex !== -1) {
      const updatedCartItems = [...cartItems];
      updatedCartItems[existingItemIndex].quantity += quantity;
      setCartItems(updatedCartItems);
    } else {
      const newItem = {
        ...product,
        quantity: quantity,
      };
      setCartItems([...cartItems, newItem]);
    }
    message.success('Item added');
    const newTotal = cartItems.reduce((acc, item) => acc + (item?.price || 0) * item.quantity, 0) + (selectedProduct?.price || 0) * quantity;

    setTotal(newTotal);
  };

  
  const handleAddToCartRemaining = (product, quantity) => {
    const existingItemIndex = cartItems.findIndex((item) => item.id === product.id);
    if (existingItemIndex !== -1) {
      const updatedCartItems = [...cartItems];
      updatedCartItems[existingItemIndex].quantity += quantity;
      setCartItems(updatedCartItems);
    } else {
      const newItem = {
        ...product,
        quantity: quantity,
      };
      setCartItems([...cartItems, newItem]);
    }
    message.success('Item added');
    const newTotal = cartItems.reduce((acc, item) => acc + (item?.price || 0) * item.quantity, 0) + (selectedProduct?.price || 0) * quantity;

    setTotal(newTotal);
  };
  const removeFromCart = (productId) => {
    setCartItems((prevItems) =>
      prevItems.filter((item) => item.id !== productId)
    );
    message.success('Item removed');
  };

  const handleRemoveAll = () => {
    setCartItems([]);
    message.success('All Cart Items Are Deleted');
  };

  const calculateTotal = () => {
    return cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
  };

  const handleCheckOut = () => {
    setCheckOut(true);
    setShowCartList(false);
  }
  const handleCancelCheckOut = () => {
    setCheckOut(false);
  }


  const handleOrdersInfo = (e) => {
  
    setShippingForm({...shippingForm, [e.target.name]: e.target.value})
  }
  const [regionData, setRegionData] = useState([]);
  const [provinceData, setProvinceData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [barangayData, setBarangayData] = useState([]);
  const [shippingFee, setShippingFee] = useState(0);

  const fetchRegions = () => {
    regions()
      .then(response => {
        setRegionData(response);
        setShippingForm(prevData => ({ ...prevData, region: response[null]?.region_code || '' }));
      })
      .catch(error => console.error('Error fetching regions:', error));
  };
  const fetchProvinces = region_code => {
    provinces(region_code).then(response => {
      setProvinceData(response);
    });
  };
  const fetchCities = provinceCode => {
    cities(provinceCode).then(response => {
      setCityData(response);
    });
  };
  const fetchBarangays = cityCode => {
    barangays(cityCode).then(response => {
      setBarangayData(response);
    });
  };
  useEffect(() => {
    fetchRegions();
  }, []);
  const onChangeRegion = (e) => {
    const selectedRegionCode = e.target.value;
    const selectedRegion = regionData.find((item) => item.region_code === selectedRegionCode);
    const shippingFee = calculateShippingFee(selectedRegionCode);
    setShippingFee(shippingFee);

    setShippingForm((prevShippingForm) => ({
      ...prevShippingForm,
      region: selectedRegionCode,
      regionName: selectedRegion ? selectedRegion.region_name : '',
      shippingFee: shippingFee,
    }));

    fetchProvinces(selectedRegionCode);
    setProvinceData([]);
    setCityData([]);
    setBarangayData([]);
  };
  const onChangeProvince = (e) => {
    const selectedProvinceCode = e.target.value;
    const selectedProvince = provinceData.find((item) => item.province_code === selectedProvinceCode);
    setShippingForm({
      ...shippingForm,
      province: selectedProvinceCode,
      provinceName: selectedProvince ? selectedProvince.province_name : '',
    });
    fetchCities(selectedProvinceCode);
    setCityData([]); 
    setBarangayData([]);
  };
  const onChangeCity = (e) => {
    const selectedCityCode = e.target.value;
    const selectedCity = cityData.find((item) => item.city_code === selectedCityCode);
    setShippingForm({
      ...shippingForm,
      city: selectedCityCode,
      cityName: selectedCity ? selectedCity.city_name : '',
    });
    fetchBarangays(selectedCityCode);
    setBarangayData([]); 
  };
  const onChangeBarangay = (e) => {
    const selectedBarangayCode = e.target.value;
    const selectedBarangay = barangayData.find((item) => item.brgy_code === selectedBarangayCode);
    setShippingForm({
      ...shippingForm,
      brgy: selectedBarangayCode,
      brgyName: selectedBarangay ? selectedBarangay.brgy_name : '',
    });
  };
  const calculateShippingFee = (selectedRegionCode) => {
    const isNCR = selectedRegionCode === '13';
    if (isNCR) {
      return 100;
    } else  {
      return 150;
    }
  };
  const onChangeMethod = (e) => {
    setSelectedPaymentMethod(e.target.value);
  };
  const calculateTotalCost = () => {
    const subtotal = calculateTotal();
    const totalCost = subtotal + shippingForm.shippingFee;
    return totalCost;
  };

  return (
    <>
    <Navbar/>
    <div className='wrapper' id='product-wrapper'>
      <div className='cartList-btn' onClick={() => setShowCartList(!showCartList)}>
        <div className='badge'>{cartItemCount}</div>
        <MdShoppingCartCheckout className='cart-icon'/>
      </div>

      {showCartList && (
        <div className='offcanvas-right'>
          <div className="card-header">
            <h3>My Cart Lists</h3>
            <button className='btn btn-danger' onClick={handleClose}>X</button>
          </div>
          <div className="card-body">
            <div className="product-list">
              {cartItems.length === 0 ? (
                <div className="empty">
                  <h6>There are no products currently in the cart...</h6>
                </div>
              ) : (
                <>
                  <div className="removeAll-btn">
                  <Popconfirm
                      title="Delete All Items"
                      description="Are you sure to delete all items?"
                      onConfirm={handleRemoveAll}
                      okText="Yes"
                      cancelText="No"
                    >
                       <button className='btn btn-light text-secondary'>
                          REMOVE ALL
                        </button>
                    </Popconfirm>
                  </div>
                  <div className="item-holder">
                  {cartItems.map((cartItem) => (
                    <div key={cartItem.id} className="row">
                      <div className="col-2 d-flex justify-content-center align-items-center">
                        <div className="img-holder">
                          <img src={cartItem.image} alt={cartItem.name} />
                        </div>
                      </div>
                      <div className="col-5 d-flex">
                        <div className='d-flex flex-column'>
                          <span style={{fontSize: '13px'}}>
                            <b>{cartItem.name}</b>
                          </span>
                          <input
                            type='number'
                            min='1'
                            max='50'
                            className='form-control'
                            value={cartItem.quantity}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-5 d-flex flex-column justify-content-between">
                        <p className='float-end ml-auto mb-0' style={{fontSize: '13px'}}>
                          <strong>SubTotal: </strong> {formatCurrency(cartItem.price * cartItem.quantity)}
                        </p>
                        <button
                          className='btn float-end ml-auto'
                          onClick={() => removeFromCart(cartItem.id)}
                        >
                          <FaTrashAlt className='text-danger' />
                        </button>
                      </div>
                    </div>
                  ))}
              </div>
                </>
              )}
              
              
            </div>
          </div>
          <div className="card-footer">
          <span><strong>Total: {formatCurrency(calculateTotal())}</strong></span>
            <button 
              className='btn btn-success checkout-btn' 
              onClick={handleCheckOut}
              disabled={cartItems.length === 0}>
                Check Out
            </button>
          </div>
          
        </div>
      )}

      {cartItems && (
          <Modal show={checkOut} dialogClassName={widerModalClass}>
          <Modal.Header>
            <Modal.Title>Orders Preview</Modal.Title>
            <div className="cancel-btn">
                <button className='btn btn-danger' onClick={handleCancelCheckOut}>
                Cancel
              </button>
              </div>
          </Modal.Header>
          <Modal.Body>
            <section className="checkout">
              
              <div className="row">
                
                <div className="col-12 col-md-4 table-responsive">
                  {/* Order details */}
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th></th>
                        <th>Item</th>
                        <th>Quantity</th>
                        <th>Price</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                    {cartItems.map((item, index) => (
                        <tr key={index}>
                          <td style={{maxWidth: '80px'}}>
                            <img src={item.image} alt={item.name} style={{width: '100%'}}/>
                          </td>
                          <td>{item.name}</td>
                          <td>{item.quantity}</td>
                          <td>{formatCurrency(item.price)}</td>
                          <td>{formatCurrency((item.price * item.quantity))}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="sub-total ">
                    <p className="lead">Amount Due</p>
                    <div className="table-responsive">
                      <table className="table">
                        <tr>
                          <th style={{ width: '50%' }}>Subtotal:</th>
                          <td>{formatCurrency(calculateTotal())}</td>
                        </tr>
                        <tr>
                          <th>Shipping:</th>
                          <td>{formatCurrency(shippingFee)}</td>
                        </tr>
                        <tr>
                          <th>Total Cost:</th>
                          <td>{formatCurrency(calculateTotalCost())}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
                
                
                <div className="col-12 col-md-8">
                  {/* buyer info*/}
                  <div className="card">
                    <div className="card-header border-0">
                      <h5>Contact Info</h5>
                    </div>
                    <div className="card-body">

                      <div className="row">
                        <div className="col-12 col-sm-6">
                          <div className="form-group p-1">
                            <label htmlFor='Fullname'>Recipient Name</label><span className='text-danger'> * (Full Name)</span>
                            <input type="text" 
                                  className="form-control" 
                                  placeholder="Full Name"
                                  name='Fullname'
                                  id='Fullname'
                                  onChange={(e) => {handleOrdersInfo(e)}}
                                  required/>
                          </div>
                        </div>
                        <div className="col-12 col-sm-6">
                          <div className="form-group p-1">
                            <label htmlFor='Email'>Email Address</label><span className='text-danger'> *</span>
                            <input type="text" 
                                  className="form-control" 
                                  placeholder="Email Address"
                                  name='Email'
                                  id='Email'
                                  onChange={(e) => {handleOrdersInfo(e)}}
                                  required/>
                          </div>
                        </div>
                        <div className="col-12 col-sm-6">
                          <div className="form-group p-1">
                            <label htmlFor='ContactNum'>Contact Number</label><span className='text-danger'> *</span>
                            <input type="text" 
                                  className="form-control" 
                                  placeholder="+63 Contact Number"
                                  name='ContactNum'
                                  id='ContactNum'
                                  onChange={(e) => {handleOrdersInfo(e)}}
                                  required/>
                          </div>
                        </div>
                        <div className="col-12 col-sm-6">
                          <div className="form-group d-flex flex-column">
                            <label>Payment Method<span className='text-danger'> *</span></label>
                            {calculateTotal() > 4000 ?
                            <>
                            <div className="form-check form-check-inline p-1">
                              <label className="form-check-label" htmlFor="COD">
                                COD <span className='text-muted'>(maximum limit of 4000.00 php)</span>
                              </label>
                            </div>
                            <div className="form-check form-check-inline p-1">
                            <input
                                    type="radio"
                                    className="form-check-input mb-0"
                                    name="PaymentMethod"
                                    id="bank"
                                    value="bank"
                                    onChange={onChangeMethod}
                                    checked={selectedPaymentMethod === 'bank'}
                                    defaultChecked
                                  />
                              <label className="form-check-label" for="bank">Bank Transfer</label>
                            </div>
                            </> 
                            :
                            <>
                            <div className="form-check form-check-inline p-1">
                            <input
                                    type="radio"
                                    className="form-check-input mb-0"
                                    name='PaymentMethod'
                                    id='COD'
                                    value="COD"
                                    onChange={onChangeMethod}
                                    checked={selectedPaymentMethod === 'COD'}
                                    defaultChecked
                                  />
                              <label className="form-check-label" htmlFor="COD">
                                COD <span className='text-muted'>(maximum limit of 4000.00 php)</span>
                              </label>
                            </div>
                            <div className="form-check form-check-inline p-1">
                            <input
                                    type="radio"
                                    className="form-check-input mb-0"
                                    name="PaymentMethod"
                                    id="bank"
                                    value="bank"
                                    onChange={onChangeMethod}
                                    checked={selectedPaymentMethod === 'bank'}
                                  />
                              <label className="form-check-label" for="bank">Bank Transfer</label>
                            </div>
                            </>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* shipping info*/}
                  <div className="card">
                    <div className="card-header border-0">
                      <h5>Shipping Address</h5>
                    </div>
                    <div className="card-body">
                    <div className="row">
                    <div className="col-12 col-sm-6">
                      <div className='form-group p-1'>
                        <label htmlFor='region'>Region</label><span className='text-danger'> *</span>
                        <select
                          className='form-control'
                          name='region'
                          value={shippingForm.region}
                          onChange={onChangeRegion}
                          required
                        >
                          <option value='' disabled>
                            Select Region
                          </option>
                          {regionData.map(item => (
                            <option
                              key={item.region_code}
                              value={item.region_code}
                            >
                              {item.region_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group p-1">
                        <label htmlFor='province'>Province</label><span className='text-danger'> *</span>
                        <select
                          className='form-control'
                          name='province'
                          value={shippingForm.province}
                          onChange={onChangeProvince}
                          required
                        >
                          <option value='' disabled>
                            Select Province
                          </option>
                          {provinceData.map(item => (
                            <option
                              key={item.province_code}
                              value={item.province_code}
                            >
                              {item.province_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className='form-group p-1'>
                        <label htmlFor='city'>City / Municipal</label><span className='text-danger'> *</span>
                        <select
                          className='form-control'
                          name='city'
                          value={shippingForm.city}
                          onChange={onChangeCity}
                          required
                        >
                          <option value='' disabled>
                            Select City / Municipality
                          </option>
                          {cityData.map(item => (
                            <option 
                              key={item.city_code} 
                              value={item.city_code}
                            >
                              {item.city_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group p-1">
                        <label htmlFor='brgy'>Barangay</label><span className='text-danger'> *</span>
                        <select
                          className='form-control'
                          name='brgy'
                          value={shippingForm.brgy}
                          onChange={onChangeBarangay}
                          required
                        >
                          <option value='' disabled>
                            Select Barangay
                          </option>
                          {barangayData.map(item => (
                            <option 
                              key={item.brgy_code} 
                              value={item.brgy_code}
                            >
                              {item.brgy_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group p-1">
                        <label htmlFor='street'>Street</label><span className='text-danger'> *</span>
                        <input type="text" 
                                className="form-control" 
                                placeholder="Street"
                                name='street'
                                id='street'
                                value={shippingForm.street}
                                onChange={(e) => {handleOrdersInfo(e)}}
                                required/>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group p-1">
                        <label htmlFor='postalCode'>Postal Code</label><span className='text-danger'> *</span>
                        <input type="text" 
                                className="form-control" 
                                placeholder="Postal Code"
                                name='postalCode'
                                id='postalCode'
                                value={shippingForm.postalCode}
                                onChange={(e) => {handleOrdersInfo(e)}}
                                required/>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group p-1">
                        <label htmlFor='landmark'>Landmark</label><span className='text-muted'> (Optional)</span>
                        <textarea type="text" 
                                className="form-control" 
                                placeholder="Landmark / Exact Address / Guidance Instructions for Directions"
                                name='landmark'
                                id='landmark'
                                value={shippingForm.landmark}
                                onChange={(e) => {handleOrdersInfo(e)}}
                                required/>
                      </div>
                    </div>
                  </div>
                    </div>
                  </div>
                </div>

              </div>

            </section>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between">

              <div className="total-cost">
                <h3>Total: {formatCurrency(calculateTotalCost().toFixed(2))}</h3>
              </div>

              <div className="checkout-btn">
                <button
                  type='button'
                  onClick={handleProceedPurchase}
                  className='btn btn-success'
                  disabled={!isFormFilled || isLoading}
                >
                  {isLoading && (
                    <span className="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span>
                  )}
                  {isLoading ? 'Processing...' : 'Proceed Purchase'}
                </button>
              </div>

          </Modal.Footer>
          </Modal>
      )}

      <div className='background-div' id='productpage'>
        <div className='breadcrumb-holder d-flex justify-content-start'>
          <Breadcrumb paths={breadcrumbPaths}/>
        </div>

        <section id='product' className='d-flex align-item-center justify-content-center'>
          <div className='container'>
            <h1 className='header-text d-flex align-item-center justify-content-center'
                data-aos="fade-down"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1000"
                data-aos-once="true">
                  Our products
            </h1>
           
            {selectedProduct && (
              <section id='selected-product'>
                <div className="row">
                  <div className="col-12 col-sm-6 d-flex flex-column justify-content-start align-items-end">
                    <div className="main-image">
                      <div className="main-pic-holder">
                        <img src={selectedProduct.image} alt={selectedProduct.name} />
                      </div>
                    
                      
                      <div className="card-img d-flex justify-content-start">
                        <div className="otherPics">
                          {selectedProduct.otherImage.map((img, index) => (
                            <img
                              key={index}
                              src={img}
                              alt={`Other Pic ${index + 1}`}
                              onMouseEnter={() => handleHoverImage(img)}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="prod-info col-12 col-sm-6">
                    {/* Additional details about the selected product can go here */}
                    <h2>{selectedProduct.name}</h2>
                    <span><strong>Price:</strong> {formatCurrency(selectedProduct.price)}</span>
                    <br/>
                    <span><strong>Description:</strong></span>
                    <pre>{selectedProduct.description}</pre>
                    <div className="addtocart-btn">
                      <div className='quantity-btn align-items-center justify-content-start'>
                        <div className="input-holder">
                          <button
                            type='button'
                            className='minus-btn btn'
                            onClick={() => handleDecrement(selectedProduct.id)}
                          >
                            <FaMinus />
                          </button>
                          <input
                            type='number'
                            min='1'
                            max='50'
                            className='form-control'
                            value={quantities[selectedProduct.id] || 1}
                            onChange={(e) => {
                              const value = parseInt(e.target.value, 10) || 1;
                              setQuantities((prevQuantities) => ({
                                ...prevQuantities,
                                [selectedProduct.id]: value,
                              }));
                            }}
                          />
                          <button
                            type='button'
                            className='plus-btn btn'
                            onClick={() => handleIncrement(selectedProduct.id)}
                          >
                            <FaPlus />
                          </button>
                        </div>
                      </div>
                      <div className="btns d-flex justify-content-between">
                        <button 
                          className='btn btn-success' type='button' 
                          onClick={() => selectedProduct && handleAddToCart(selectedProduct, quantities[selectedProduct.id] || 1)}>
                            <BiSolidCartAdd className='addtocart-icon'/> Add To Cart
                        </button>
                      </div>
                    </div>

                  </div>
                </div>
              </section>
            )}

            <div className='row card-holder d-flex align-item-center'>
              {clickProduct && (
              <div className='col-12' style={{marginLeft: '100px'}}>
                <h1 style={{fontSize: '25px',}}>Other related products</h1>
              </div>
              )}
              {remainingProducts
              .map((product) => (
                <div
                  key={product.id}
                  className={`col-12 col-lg-3 col-md-6 col-sm-6 card`}
                  onClick={() => handleProduct(product)}
                  onMouseEnter={() => setHoveredCard(product.id)}
                  onMouseLeave={() => setHoveredCard(null)}
                >
                  <div>
                    <img src={product.otherImage[0]} alt={product.name} />
                    <h2>{product.name}</h2>
                    <h3>SRP: {formatCurrency(product.price)}</h3>
                    <h4>
                      DP: {formatCurrency(product.dp)}
                      <em>(distributors only)</em>
                    </h4>
                  </div>
                  {(hoveredCard === product.id || selectedProduct?.id === product.id) && (
                    <div
                      className='quantity-btn align-items-center justify-content-start'
                      onClick={(e) => e.stopPropagation()}
                    >
                      <div className="input-holder">
                        <button
                          type='button'
                          className='minus-btn btn'
                          onClick={() => handleDecrement(product.id)}
                        >
                          <FaMinus />
                        </button>
                        <input
                          type='number'
                          min='1'
                          max='50'
                          className='form-control'
                          value={quantities[product.id] || 1}
                          onChange={(e) => {
                            const value = parseInt(e.target.value, 10) || 1;
                            setQuantities((prevQuantities) => ({
                              ...prevQuantities,
                              [product.id]: value,
                            }));
                          }}
                        />
                        <button
                          type='button'
                          className='plus-btn btn'
                          onClick={() => handleIncrement(product.id)}
                        >
                          <FaPlus />
                        </button>
                      </div>
                      <button className='btn addtocart' type='button' 
                        onClick={() => handleAddToCartRemaining(product, quantities[product.id] || 1)}>
                        <BiSolidCartAdd className='addtocart-icon'/>
                      </button>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>

      <Footer />

    </div>
    </>
  );
};

export default Products;
