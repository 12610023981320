import React from 'react'

const TermsCondition = () => {
  return (
    <pre id='terms' style={{whiteSpace: 'pre-line',  fontSize:'15px', fontFamily: 'Roboto'}}>
                         
        As a Distributor of CHOSEN DROP POINT. I understand and agree to the following: 
        <br/>
        <br/>
        A. I am of legal age upon entering into this Distributorship Agreement, otherwise. I will submit a parental consent waiver together with this agreement. 
        <br/>
        B.	This application shall constitute a binding agreement between me and CHOSEN DROP POINT upon receipt and due approval of the Company. 
        <br/>
        C.	I fully understand that this Distributorship Agreement is personal in nature and is not transferrable nor assignable except in the event of my death, where law on intestate succession applies, however. subject to existing Company's policy on qualification as Independent Distributor: 
        <br/>
        D.	I attest that I personally participated and was personally presented with the CHOSEN DROP POINT Business Presentation and Product Orientation prior to selling the CHOSEN DROP POINT products: 
        <br/>
        E.	I hereby agree that I am entitled to all rebates commissions and other incentives provided that I maintain the monthly personal purchase required by the company. 
        <br/>
        F.	I am aware that refunds will not be provided for any packages. 
        G.	I shall be eligible for sales bonuses. discounts, and privileges that may be granted by the company relative to my sales performance or successful delivery of products. Relevant to my sales activity. I further understand that sales bonuses, rebates and/or commissions are in accordance with the compensation scheme established in the CHOSEN DROP POINT Marketing Plan. provided, that I have achieved such sales performance in good faith, and that I have not violated any of the provisions of the Distributorship Agreement, Code of Ethics, and Company Policies: 
        <br/> 
        G.	I am aware that all commissions are subject to 10% withholding tax and PhP100 processing fee. 
        <br/> 
        H.	This Distributorship Agreement limits a no employee - employer relationship between me and CHOSEN DROP POINT; neither that I may claim to be a legal representative of CHOSEN DROP POINT nor bind CHOSEN DROP POINT in any agreements other than those stipulated herein: 
        <br/> 
        I.	As Independent Distributor. I am bound to abide by the Company's rules and regulations and faithfully uphold the Code of Conduct and Ethical Standard. Should I commit any form of violation and be subjected to the necessary sanctions and penalties as provided in the Code of Conduct and Ethical Standard. I voluntarily release CHOSEN DROP POINT from any liabilities these sanctions and penalties may cause consequently. 
        <br/> 
        J.	I understand that I should secure an express written approval from the management of CHOSEN DROP POINT or any of its authorized representatives prior to making any form of advertisement in mainstream media, social networking sites. internet, and online media, including. but not limited to audio, visual, and printed materials, other than the Company's existing advertisement and marketing materials and postings on its official website and official social media pages: 
        <br/>
        K.	I am aware that making any misrepresentations, revisions, modifications. or alterations of the Company's trademark, brand, logos, marketing plan, products, advertisement, marketing materials, and other company provided marketing tools. is strictly prohibited. Otherwise. it shall be deemed violation of the Company's rules, regulations, and policies: 
        <br/>
        L.	As a general rule, should my spouse join CHOSEN DROP POINT as an Independent Distributor, he/she shall automatically be under my sales team. Otherwise, this shall constitute violation of the Cross-lining provision as defined on the Code of and Ethical Standard. 
        <br/>
        M.	I affirm to exclusively join the sponsor I personally indicated in this Application Form or adhere to the "six-month-rule” with no activities, no sales in the event I decided to sign-up to another sponsor line. 
        <br/>
        N.	I shall diligently settle to the designated government agency/local government unit all due taxes from the sales bonuses I have earned from CHOSEN DROP POINT. 
        <br/>
        O.	fully aware that the CHOSEN DROP POINT reserves the right to modify, revise, and 
        update its existing policies and business plan for the interest of the company and its Distributors with 30 days prior notice: 
        <br/>  
        P.	I conform to conduct all my sales activities in accordance with the existing laws of the Republic of the Philippines and release CHOSEN DROP POINT from any liability arising from my own personal actions: 
        <br/> 
        Q.	Upload, post, transmit or otherwise make available any Content that is unlawful, harmful, threatening, abusive, harassing, alarming, distressing, tortuous, defamatory, vulgar, obscene, libelous, invasive of another's privacy, hateful, or racially, ethnically or otherwise objectionable against the company at any social media platform will subject to penalty amounting 100,000 pesos (first offense) 500,000 pesos (second offense), 1million third offense) deductible on distributor’s commission 50% every withdrawal. 
        <br/>
        R.	Cross lining may subject for penalty amounting to 100,000 pesos. 
        <br/>
        I hereby certify that the above information are true and correct to the best of my knowledge. In case of any violation of the terms and ons herein stipulated, I hereby agree and authorize CHOSEN DROP POINT to revoke. deactivate, and/or suspend my privileges as an indent Distributor without prejudice to any charges, criminal and/or civil, that CHOSEN DROP POINT may charge against me, without limitation to any false information I have provided herein. 
        <br/>
        I fully understand and further confirm this authority that I am conferring CHOSEN DROP POINT to do so is with my own free will and violation. without any force, intimidation or undue influence employed upon me. 
    </pre>
  );
};

export default TermsCondition;