import TopNavbar from './Components/TopNavbar/TopNavbar';
import SideNavbar from './Components/SideNavbar/SideNavbar';
import Footer from './Components/Footer/Footer';
import Main from './Components/Main/Main';
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ProtectUser from '../firebase/ProtectUser'; // Adjust the path based on your folder structure
import DashBoard from './Pages/DashBoard';
import OrderHistory from './Pages/OrderHistory';
import InputOrder from './Pages/InputOrder';
import ProductLink from './Pages/ProductLink';
import WalletDetails from './Pages/WalletDetails';
import CashOutHistory from './Pages/CashOutHistory';
import MyAccount from './Pages/MyAccount';
function ClientPage(profile) {
  return (
    <div className='wrapper'>
      <TopNavbar profile={profile}/>
      <SideNavbar/>
      <Main />
      <Footer />
    </div>
  )
}

export default ClientPage;
