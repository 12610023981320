import React, { useState } from 'react';
import './Navbar.css'; // Import your custom CSS
import { Link } from 'react-router-dom';
import LOGO from '../../../Assets/Images/CDP_white.png'

const Navbar = () => {
    const [openMenu, setOpenMenu] = useState(false);

    const toggleMenu = () => {
        setOpenMenu(!openMenu);
    }

    const [activeMenu, setActiveMenu] = useState();

    const handleMenuClick = (menuItem) => {
        if (activeMenu !== menuItem) {
            setActiveMenu(menuItem);
        } 
        setOpenMenu(false);
    };

  return (
    <header className="header_section">
      <div className="container-fluid">
        <nav className="navbar navbar-fixed-top navbar-expand-lg custom_nav-container">
          <Link to='/' className="navbar-brand" href="index.html">
            <div className='logo-container'>
              <img src={LOGO} alt="LOGO ICON" />
            </div>
          </Link>

          <button
            className={`navbar-toggler ${openMenu ? 'active' : ''}`}
            type="button"
            onClick={toggleMenu}
          >
            <div className="bar1"></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
            
          </button>

          <div className={`collapse navbar-collapse ${openMenu ? 'show' : ''}`}>
            <ul className="navbar-nav">
              <li className={activeMenu === 'home' ? 'nav-item active' : 'nav-item'}>
                <Link to='/' className="nav-link" onClick={() => handleMenuClick('home')}>
                  Home
                  {activeMenu === 'home'? <div className='line'></div>: <></>}
                </Link>
              </li>
              <li className={activeMenu === 'About' ? 'nav-item active' : 'nav-item'}>
                <Link to='/about' className="nav-link" onClick={() => handleMenuClick('About')}>
                  About
                  {activeMenu === 'About'? <div className='line'></div>: <></>}
                </Link>
              </li>
              <li className={activeMenu === 'Products' ? 'nav-item active' : 'nav-item'}>
                <Link to='/products' className="nav-link" onClick={() => handleMenuClick('Products')}>
                  Products
                  {activeMenu === 'Products'? <div className='line'></div>: <></>}
                </Link>
              </li>
              <li className={activeMenu === 'Contactus' ? 'nav-item active' : 'nav-item'}>
                <Link to='/contact Us' className="nav-link" onClick={() => handleMenuClick('Contactus')}>
                  Contact Us
                  {activeMenu === 'Contactus'? <div className='line'></div>: <></>}
                </Link>
              </li>
              <li className={activeMenu === 'Login' ? 'nav-item active' : 'nav-item'}>
                <Link to='/login' className="nav-link" onClick={() => handleMenuClick('Login')}>
                  Login
                  {activeMenu === 'Login'? <div className='line'></div>: <></>}
                </Link>
              </li>
              
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Navbar;
