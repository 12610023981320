// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage"
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDLO64ddj-x-Y-kkct-FhVYehOxvntQOqE",
  authDomain: "chosen-drop-point-db-f0293.firebaseapp.com",
  databaseURL: "https://chosen-drop-point-db-f0293-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "chosen-drop-point-db-f0293",
  storageBucket: "chosen-drop-point-db-f0293.appspot.com",
  messagingSenderId: "108341475360",
  appId: "1:108341475360:web:6e008e2daf7270a970a445",
  measurementId: "G-701E6L7CZF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getDatabase(app);
export const storage = getStorage(app);
export const auth = getAuth(app);