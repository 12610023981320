import React, { useState, useEffect } from 'react'
import './TopNavbar.css'
import { IoMenu, IoNotifications  } from "react-icons/io5";
import { FaRegClock } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import { auth, db, storage } from '../../../firebase/config';
import { ref as sRef, listAll, getDownloadURL } from 'firebase/storage';
import { child, get, ref } from 'firebase/database';
import { PropagateLoader } from 'react-spinners';
import defaultprofile from '../../../Assets/Images/default.png'
import { useUserContext } from '../../context/UserContext';

const TopNavbar = (profile) => {
  const { userPhotoUrl } = useUserContext();
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [isNotificationVisible, setNotificationVisible] = useState(false);
  const [clickedNotificationIndices, setClickedNotificationIndices] = useState([]);
  const [userData, setUserData] = useState(null);
  const [latestPhotoUrl, setLatestPhotoUrl] = useState(null);
  const handleNotifBtn = () => {
    setNotificationVisible(!isNotificationVisible);
  };
  const email = JSON.parse(localStorage.getItem('user'))
  // Fetch the logged-in user information based on user ID (for example, UserID 1)
  
  useEffect(() => {
    const fetchLatestPhoto = async () => {
      try {
        const listProfile = sRef(storage, `users/${email.uid}/profile`);
        // Get a list of all items in the profile folder
        const listResult = await listAll(listProfile);
        
        // Find the latest image (assumed to be the last item in the list)
        const latestImage = listResult.items[listResult.items.length - 1];

        // Get the download URL of the latest image
        const url = await getDownloadURL(latestImage);
        // Set the state with the latest image URL
        setLatestPhotoUrl(url);
      } catch (error) {

      }
    };
    fetchLatestPhoto();
    
  });

  useEffect(() => {
    const fetchUserData = async () => {
      get(child(ref(db), `users/${email.uid}`)).then((snapshot) => {
        if (snapshot.exists()) {
          setUserData(snapshot.val())
        } else {
          console.log("No data available");
        }
      }).catch((error) => {
        console.error(error);
      });
    }
    fetchUserData();
  }, []);
  // Notification toggle and state
  const notifications = loggedInUser ? loggedInUser.Notifications : [];

  const handleNotificationClick = (index) => {
    if (!clickedNotificationIndices.includes(index)) {
      setClickedNotificationIndices((prevIndices) => [...prevIndices, index]);
    }
  };

  return (
    <nav className="main-header navbar navbar-expand fixed">

      <ul className="navbar-nav">
        <li className="nav-item" >
          <div className="nav-link menuToggle" data-widget="pushmenu" role="button">
            <IoMenu/>
          </div>
        </li>
        <li className="nav-item d-none d-sm-inline-block">
        </li>
      </ul>

      <ul className="navbar-nav ml-auto">
        {/* <li className="nav-item dropdown" >
          <div className="nav-link notif-btn" data-toggle="dropdown" onClick={handleNotifBtn}>
            <IoNotifications className='bell-icon'/>
            <span className="badge badge-danger navbar-badge">{notifications.length - clickedNotificationIndices.length}</span>
          </div>
          {isNotificationVisible && 
            <div className="dropdown-menu dropdown-menu-lg" style={{display: 'block'}}>
              <span className="dropdown-header">{notifications.length - clickedNotificationIndices.length} Notifications</span>
              <div className="dropdown-divider"></div>
              {notifications.map((notification, index) => (
              <div key={index} onClick={() => handleNotificationClick(index)} className="dropdown-item">
                <li className={`media-body ${clickedNotificationIndices.includes(index) ? 'clicked' : ''}`}>
                    <h3 className="dropdown-item-title">{notification.message}</h3>
                    <p className='text-sm text-muted'><FaRegClock/> {notification.timestamp}</p>
                </li>
              </div>
              ))}
              <div className="dropdown-footer"></div>
            </div>
          }
        </li> */}

        {email && (
        <li className="nav-item dropdown">
          <Link to='MyAccount' className="nav-link profile">
          {latestPhotoUrl ? (
                        <div>
                          <img src={latestPhotoUrl} alt="Latest Uploaded" />
                        </div>
                      ) : (
                          <img src={defaultprofile} alt="Latest Uploaded" />
                      )}
            <div className='info'>
            {userData ? (
                        <h1>{userData.firstname} {userData.lastname}</h1>
                      ) : (
                        <PropagateLoader  size={10} color="#36d7b7" />
                      )}
              <p><em>{email.email}</em></p>
            </div> 
          </Link>
        </li>
         )}
      </ul>

  </nav>
  )
}

export default TopNavbar;
