import React, { useEffect, useState } from 'react'
import { IoBagHandle } from "react-icons/io5";
import { FaCoins, FaWallet } from "react-icons/fa";
import { VscGraph } from "react-icons/vsc";
import { get, ref } from 'firebase/database';
import { db } from '../../../firebase/config';

const StatsBox = () => {
    const user = JSON.parse(localStorage.getItem('user'))
    const [totalSales, setTotalSales] = useState(0)
    const [salesCount, setSalesCount] = useState(0)
    const [TotalOrders, setTotalOrders] = useState(0)
    const [walletBalance, setWalletBalance] = useState(0)
    useEffect(() => {
        const fetchOrderItems = async () => {
            try {
                const ordersRef = ref(db, 'orders');
                const snapshot = await get(ordersRef);
    
                if (snapshot.exists()) {
                    const orderItemsArray = Object.entries(snapshot.val())
                        .filter(([orderId, orderData]) => (orderData.user === user.uid) && (orderData.status === 'Delivered'))
                        .map(([orderId, orderData]) => ({
                            orderId: orderId,
                            ...orderData,
                        }))
                        .sort((b, a) => b.timestamp - a.timestamp);
    
                    let overallSalesSum = 0;
                    let productSoldCount = 0;
    
                    orderItemsArray.forEach((order, index) => {
                        if ('sales' in order) {
                            overallSalesSum += order.sales;
                            productSoldCount += order.items.reduce((sum, product) => sum + product.quantity, 0);
                        } else {
                            console.warn(`Order ${index + 1} does not have a valid sales property.`);
                        }
                    });
                    setSalesCount(productSoldCount);
                    setTotalSales(overallSalesSum);
                    setTotalOrders(orderItemsArray.length);
                }
            } catch (error) {
                console.error(error);
            }
        };
    
        fetchOrderItems();
    }, []);
    
  useEffect(() => {
    const fetchOrderItems = async () => {
        try {
            const ordersRef = ref(db, 'orders');
            const snapshot = await get(ordersRef);

            if (snapshot.exists()) {
                const orderItemsArray = Object.entries(snapshot.val())
                    .filter(([orderId, orderData]) => (orderData.user === user.uid) && (orderData.status=== 'Delivered'))
                    .map(([orderId, orderData]) => ({
                        orderId: orderId,
                        ...orderData,
                    }))
                    .sort((b, a) => b.timestamp - a.timestamp);
                let overallSalesSum = 0;

                orderItemsArray.forEach((order, index) => {
                    if ('sales' in order) {
                        overallSalesSum += order.sales;
                    } else {
                        console.warn(`Order ${index + 1} does not have a valid sales property.`);
                    }
                });
                try {
                    const pendingRef = ref(db, 'request');
                    const snapshot = await get(pendingRef);
                    let totalApprovedAmount = 0;
                    if (snapshot.exists()) {
                      const requestData = snapshot.val();
                      const userUid = user.uid;
    
                
                      Object.keys(requestData).forEach((requestID) => {
                        const request = requestData[requestID];
                        if (request.user === userUid && request.status === 'Approved') {
                          totalApprovedAmount += request.amount || 0;
                        }
                      })
                    }
                    setWalletBalance(overallSalesSum - totalApprovedAmount);
                  } catch (error) {
                    console.error(error);
                  }
            }
        } catch (error) {
            console.error(error);
        }
    };

    fetchOrderItems();
}, []);



  return (
    <div className="row">
        <div className="col-lg-3 col-6">
            <div className="small-box bg-info">
                <div className="inner">
                    <h3>{TotalOrders}</h3>
                    <p>Orders</p>
                </div>
                <div className="icon">
                    <IoBagHandle/>
                </div>
            </div>
        </div>

        <div className="col-lg-3 col-6">
            <div className="small-box bg-pink">
                <div className="inner">
                    <h3>{salesCount.toLocaleString()}</h3>
                    <p>Total Product Sold</p>
                </div>
                <div className="icon">
                    <VscGraph/>
                </div>
            </div>
        </div>

        <div className="col-lg-3 col-6">
            <div className="small-box bg-success">
            <div className="inner">
                    <h3>
                        <small height={'6px'}>₱</small>
                       {totalSales.toLocaleString()}
                        <small height={'6px'}>.00</small>
                    </h3>
                    <p>Total Income</p>
                </div>
                <div className="icon">
                    <FaCoins/>
                </div>
            </div>
        </div>
        
        <div className="col-lg-3 col-6">
            <div className="small-box bg-warning">
                <div className="inner">
                    <h3>
                        <small height={'6px'}>₱</small>
                        {walletBalance.toLocaleString()}
                        <small height={'6px'}>.00</small>
                    </h3>
                    <p>Total Wallet</p>
                </div>
                <div className="icon">
                    <FaWallet/>
                </div>
            </div>
        </div>

    </div>
  )
}

export default StatsBox
