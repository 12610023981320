import React, { useEffect, useState } from 'react'
import './ContactUs.css'
import Navbar from '../../Components/NavBar/Nabvar';
import Breadcrumb from '../../../Assets/Breadcrumb/Breadcrumb';
import banner from '../../../Assets/Images/contact_banner.jpg'
import { useLocation } from 'react-router-dom';
import emailjs  from '@emailjs/browser';
import { message } from 'antd';
const ContactUs = () => {
    const breadcrumbPaths = [
        { label: 'Home', url: '/' },
        { label: 'Contact Us', url: '/Contact Us' },
      ];
      const location = useLocation();

      useEffect(() => {
        window.scrollTo(0, 0);
      }, [location.pathname]);

    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        email: '',
        message: '',
    });
    

    const handleSubmit = async(e) => {
        e.preventDefault();
        emailjs.send('service_g8twh7p', 'template_asy9asf', formData, 'Qfej3rifSybWXKaLp');
        setFormData({
            ...formData,
            firstname: '',
            lastname: '',
            email: '',
            message: '',
        })
        message.success('Email Sent!')
    }
  return (
    <>
    <Navbar/>
    <div className='wrapper' id='contactus-wrapper'>
        <div className='background-div' id='contactus'>
            <div className='breadcrumb-holder d-flex justify-content-start'>
                <Breadcrumb paths={breadcrumbPaths}/>
            </div>

            <section id='contactus' className='d-flex align-item-center justify-content-center'>
                <div className='container'>
                    <div className="container-holder">
                        <div data-aos="zoom-in"
                             data-aos-easing="ease-out-cubic"
                             data-aos-duration="600"
                             data-aos-once="true"
                             className="header-row row d-flex align-item-center justify-content-end">
                            <img src={banner} alt="" />
                        </div>
                        
                        <div data-aos="zoom-in-up"
                             data-aos-easing="ease-out-cubic"
                             data-aos-duration="360"
                             data-aos-once="true"
                             className="form-container">
                            <form onSubmit={handleSubmit} method="" action="">
                                <div className="form-holder row">

                                    <h1>Full Name <span>*</span></h1>
                                    <div className="fullname d-flex align-item-center justify-content-start">
                                        
                                        <label htmlFor="Fname" className='fname-container'>
                                            <input type="text" 
                                                name="Fname form-control" 
                                                className='form-control'
                                                placeholder="First Name"
                                                value={formData.firstname}
                                                onChange={(e) => setFormData({ ...formData, firstname: e.target.value })}
                                                required/>
                                        </label>
                                    
                                    
                                        <label htmlFor="Lname" className='lname-container'>
                                            <input  type="text" 
                                                    name="Lname"
                                                    className='form-control' 
                                                    placeholder="Last Name"
                                                    value={formData.lastname}
                                                    onChange={(e) => setFormData({ ...formData, lastname: e.target.value })}
                                                    required/>
                                        </label>
                                        
                                    </div>

                                    <h1>Email Address <span>*</span></h1>
                                    <div className='emailadd'>
                                        <label htmlFor="Email">
                                            <input type="email" 
                                                   name="Email"
                                                   className='form-control'
                                                   placeholder='Enter Your Email Here'
                                                   value={formData.email}
                                                   onChange={(e) => setFormData({ ...formData, email: e.target.value })} 
                                                   required/>
                                        </label>
                                    </div>

                                    <h1>Suggestions and Questions <span>*</span></h1>
                                    <div className="textbox">
                                        <label htmlFor="question-box">
                                            <textarea type="textbox"
                                                   name='question-box' 
                                                   className='form-control'
                                                   placeholder='Write your message here...'
                                                   value={formData.message}
                                                   onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                                                   required/>
                                        </label>
                                    </div>
                                </div>
                                <div className='button'>
                                    <button type="submit"
                                        className='btn btn-success'>Submit
                                    </button>
                                </div>
                                
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
    </>
  );
};

export default ContactUs;
