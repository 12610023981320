import React, { useEffect, useState } from 'react';
import './StyleSheets/PageStyle.css';
import Breadcrumb from '../../Assets/Breadcrumb/Breadcrumb';
import { FaMinus, FaPlus, FaCartPlus  } from "react-icons/fa6";
import { MdDeleteForever } from "react-icons/md";
import { Modal, Form } from 'react-bootstrap';
import { regions, provinces, cities, barangays } from 'select-philippines-address';
import {storage, db } from "../../firebase/config"
import { get, child, ref, set,serverTimestamp, remove } from 'firebase/database';
import { getDownloadURL, ref as storageRef } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
const InputOrder = () => {
  const [products, setProducts] = useState([])
  const user = JSON.parse(localStorage.getItem('user'))
  const initialProduct = products[0] || {};
  const [shipping, setShipping] = useState()
  const [selectedItem, setSelectedItem] = useState(initialProduct);
  const [cartItems, setCartItems] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [total, setTotal] = useState(0);
  const [showCheckOut, setShowCheckOut] = useState(false);
  const widerModalClass = 'wide_modal';
  const [shippingFee, setShippingFee] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [regionData, setRegionData] = useState([]);
  const [provinceData, setProvinceData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [barangayData, setBarangayData] = useState([]);
  const [CashBondBalance, setCashBondBalance] = useState(0)
  const [ReturnedCharges, setReturnCharges] =useState()
  useEffect(() => {
    const fetchOrderItems = async () => {
        try {
            const ordersRef = ref(db, 'orders');
            const snapshot = await get(ordersRef);
            if (snapshot.exists()) {
                    const orderRetrurnedItemsArray = Object.entries(snapshot.val())
                    .filter(([orderId, orderData]) => (orderData.user === user.uid) && (orderData.status=== 'Returned'))
                    .map(([orderId, orderData]) => ({
                        orderId: orderId,
                        ...orderData,
                    }))
                    .sort((b, a) => b.timestamp - a.timestamp);
                let returnedShipping= 0;
                orderRetrurnedItemsArray.forEach((order, index) => {
                  returnedShipping += order.invoice.AmountDue.Shipping;
              });
                setReturnCharges(returnedShipping)
            }
        } catch (error) {
            console.error(error);
        }
    };

    fetchOrderItems();
}, []);
  const breadcrumbPaths = [
    { label: 'Home', url: '/MyChosenDropPoint' },
    { label: 'Input Order', url: '/InputOrder' },
  ];
  const [Address, setAddress] = useState({
    Address: '' ,
    Landmark: ''
  })
  const [userInformation, setUserInfo] = useState({
    Email:user.email,
    Fullname:'',
    ContactNum:'',
    Region:'',
    PostalCode:''
  });
  const navigate = useNavigate();
  const generateOrderID = () => {
    const numericPart = Math.floor(Math.random() * 1000000000).toString().padStart(9, '0');
    const alphanumericCharacter = String.fromCharCode(65 + Math.floor(Math.random() * 26)); 
    const parcelID = `CDP${numericPart}${alphanumericCharacter}`;
  return parcelID;
  };
  const generateInvoiceID = () => {
    const numericPart = Math.floor(Math.random() * 1000000).toString().padStart(6, '0');
    const alphanumericCharacter = String.fromCharCode(65 + Math.floor(Math.random() * 26)); 
    const parcelID = `CDP${numericPart}${alphanumericCharacter}`;
  return parcelID;
    };
 useEffect(() => {
    get(child(ref(db), 'products')).then(async (snapshot) => {
      if (snapshot.exists()) {
        const productsData = snapshot.val();
        const productsArray = Object.values(productsData);
  
        const productsWithImages = await Promise.all(productsArray.map(async (product) => {
          const imageExtensions = ['png', 'jpg']; 
  
          let imageUrl = null;
          for (const extension of imageExtensions) {
            const imageRef = storageRef(storage, `products/${product.id}/${product.id}.${extension}`);
            try {
              imageUrl = await getDownloadURL(imageRef);
              break;
            } catch (error) {
              console.error(`Error fetching ${extension} image:`, error);
            }
          }
  
          return imageUrl ? { ...product, image: imageUrl } : { ...product, image: null };
        }));
        setProducts(productsWithImages);
      } else {
        console.log('No data available');
      }
    }).catch((error) => {
      console.error(error);
    });
  }, []);
  useEffect(()=>{
    setSelectedItem(initialProduct)
  },[products])
  useEffect(() => {
    const fetchCartItems = async () => {
      try {
        const cartRef = ref(db, `cart/${user.uid}`);
        const snapshot = await get(cartRef);

        if (snapshot.exists()) {
          const cartItemsArray = Object.entries(snapshot.val())
            .map(([productId, productData]) => ({
              id: productId,
              ...productData,
            }))
            .sort((a, b) => b.timestamp - a.timestamp);
          const newTotal = cartItemsArray.reduce((acc, item) => {
            return acc + item.dp * item.quantity;
          }, 0);

          setCartItems(cartItemsArray);
          setTotal(newTotal);
        } else {
          setCartItems([]);
          setTotal(0);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchCartItems();

  }, [user]);
  
  useEffect(() => {
    const getUserInfo = async () => {
      try {
        const userInfoRef = ref(db, `users/${user.uid}`);
        const snapshot = await get(userInfoRef);
  
        if (snapshot.exists()) {
          const userData = snapshot.val();
          setUserInfo({...userInformation,
            Email:user.email,
            Fullname:userData.firstname+ " " + userData.middlename + " " + userData.lastname,
            ContactNum:userData.phone,
            PostalCode: userData.postalcode,
            Region: userData.region,
            MemberID: userData.KYCIdNum
          });
          setAddress({...Address, 
            Address: userData.street + userData.brgy + ", " + userData.city+ ", " + userData.province ,
            Landmark: userData.landmark
          })

        } else {
          setUserInfo(null); 
        }
      } catch (error) {
        console.error(error);
      }
    };
    getUserInfo();
  
  }, []);
  useEffect(() => {
    const cashbondInfo = async () => {
      try {
        const cashbondInfoRef = ref(db, 'cashbond');
        const snapshot = await get(cashbondInfoRef);
  
        if (snapshot.exists()) {
          const cashbondArray = Object.entries(snapshot.val())
            .filter(([cashbondID, cashbondData]) => cashbondData.user === user.uid)
            .map(([cashbondID, cashbondData]) => ({
              cashbondID: cashbondID,
              amount: cashbondData.amount,
              ...cashbondData,
            }));
          if (cashbondArray.length > 0) {
            const totalCashBondBalance = cashbondArray
              .filter(
                (cashbond, index) =>
                  cashbond.status === 'Approved' &&
                  !isNaN(parseFloat(cashbond.amount))
              )
              .reduce((sum, cashbond) => sum + parseFloat(cashbond.amount), 0);
            setCashBondBalance(totalCashBondBalance);
          } else {
            setCashBondBalance(null);
          }
        } else {
          setCashBondBalance(null);
        }
      } catch (error) {
        console.error(error);
      }
    };
  
    cashbondInfo();
  }, []);
  const handlePurchase = async () => {
    try {
      const orderID = generateOrderID();
      const InvoiceID = generateInvoiceID();
      const timestamp = serverTimestamp(); 
      const orderData = {        
        invoice: {
        From: {
          Name: 'Chosen Drop Point, Inc.',
          Address: 'Unit B-02 Lower 2nd Floor St. Francis Square Building Julia Vargas Avenue cor. Bank Drive Ortigas Center Mandaluyong',
          Phone: '(02) – 7006-8924',
          Email: 'iamchoseninternational@gmail.com',
        },
        To: {
          Name: userInformation.Fullname,
          Address: ` ${ shippingForm.street}, ${shippingForm.brgyName}, ${shippingForm.cityName},${shippingForm.provinceName} ,${shippingForm.postalCode} "${ shippingForm.landmark}"`,
          Phone: userInformation.ContactNum,
          Email: userInformation.Email ,
        },
        InvoiceNumber: InvoiceID,
        OrderID: orderID,
        PaymentMethod: shippingForm.PaymentMethod,
        items: cartItems.map((item) => ({
          productId: item.productId,
          name: item.name,
          quantity: item.quantity,
          price: item.price,
          dp: item.dp
        })),
        AmountDue: {
          Subtotal: total,
          Shipping:shipping,
          Total: total + shipping,
        },
      },
      address: {
        fullAddress:shippingForm.brgyName+", "+ shippingForm.cityName+", "+shippingForm.provinceName,
        street: shippingForm.street,
        landmark: shippingForm.landmark,
      },
        user: user.uid,
        seller: userInformation.Fullname,
        status: "Pending",
        total: total,
        sales: 0,
        companySales:total,
        timestamp: timestamp,
        MemberID: userInformation.MemberID,
        customer:{
          Email:userInformation.Email,
          Fullname:userInformation.Fullname,
          ContactNum:userInformation.ContactNum,
          PostalCode: userInformation.PostalCode,
          Region: userInformation.Region
        },
        items: cartItems.map((item) => ({
          productId: item.productId,
          name: item.name,
          quantity: item.quantity,
          dp:item.dp,
          price:item.price,
          TotalPrice: item.dp * item.quantity,
        })),
      };
      
      await set(ref(db, `orders/${orderID}`), orderData);
      const cartRef = ref(db, `cart/${user.uid}`);
      await remove(cartRef);
      navigate('/MyChosenDropPoint/OrderHistoy')
      const subject = `Order ${orderID} is Successful wait for Admin Approval`
      const email = userInformation.Email
      const response = await fetch('https://cdp-email-bef0d127fa8b.herokuapp.com/sendEmail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, orderData, subject }),
      });
      if (response.ok) {
        console.log('Email sent successfully');
      } else {
        console.error('Failed to send email');
      }
      message.success('Order placed successfully!');
    } catch (error) {
      console.error('Error placing order:', error);
    }
  };
  
  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-PH', { style: 'currency', currency: 'PHP' }).format(value);
  };

  const handleProductClick = (product) => {
    setSelectedItem(product);
  const quantityInput = document.getElementById('quantityInput');
    if (quantityInput) {
      quantityInput.value = 1;
    }
  };

  const handleQuantityChange = (e) => {
    const newQuantity = parseInt(e.target.value, 10);
    setQuantity(newQuantity);
  };

  const handleIncrement = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };

  const handleRemoveFromCart = async(index) => {

    try {
      await remove(ref(db, `cart/${user.uid}/${index}`));
      const updatedCartItems = [...cartItems];
      updatedCartItems.splice(index, 1);
      setCartItems(updatedCartItems);
      const newTotal = updatedCartItems.reduce((acc, item) => {
        return acc + item.price * item.quantity;
      }, 0);
      setTotal(newTotal);
    } catch (error) {
      console.error('Error removing item from the cart:', error);
    }
  };

  
  const handleAddToCart = async (event, item, itemquantity) => {
    event.preventDefault();
    const cartItemRef = ref(db, `cart/${user.uid}/${item.id}`);
    const cartItemSnapshot = await get(cartItemRef);
    const timestamp = serverTimestamp(); 
    if (cartItemSnapshot.exists()) {
      const existingQuantity = cartItemSnapshot.val().quantity || 0;
      const newQuantity = existingQuantity + itemquantity;
      set(ref(db, `cart/${user.uid}/${item.id}`), {
        productId: item.id,
        name: item.name,
        quantity: newQuantity,
        price: item.price,
        timestamp: timestamp,
        image: item.image,
        dp: item.dp
      });
    } else {
      set(cartItemRef, {
        productId: item.id,
        quantity: itemquantity,
        price: item.price,
        name: item.name,
        timestamp: timestamp,
        image: item.image,
        dp: item.dp
      });
    }
  };

  const handleCheckOut = () => {
      setShowCheckOut(true);
      setTotalCost(total + shippingFee);
  }

  const closeModal = () => {
    setShowCheckOut(false);
  };

  const [shippingForm, setShippingForm] = useState({
    Fullname: userInformation?.Fullname || '',
    ContactNum: '',
    MemberID: '',
    region: '',
    regionName: '',
    province: '',
    provinceName: '',
    city: '',
    cityName: '',
    brgy: '',
    brgyName: '',
    street: '',
    landmark: '',
    postalCode: '',
    PaymentMethod: 'COD',
  });
  const handleOrdersInfo = (e) => {
    setShippingForm({...shippingForm, [e.target.name]: e.target.value})
  }
   const fetchRegions = () => {
     regions()
       .then(response => {
         setRegionData(response);
         setShippingForm(prevData => ({ ...prevData, region: response[null]?.region_code || '' }));
       })
       .catch(error => console.error('Error fetching regions:', error));
   };
   const fetchProvinces = region_code => {
     provinces(region_code).then(response => {
       setProvinceData(response);
     });
   };
   const fetchCities = provinceCode => {
     cities(provinceCode).then(response => {
       setCityData(response);
     });
   };
   const fetchBarangays = cityCode => {
     barangays(cityCode).then(response => {
       setBarangayData(response);
     });
   };
   useEffect(() => {
     fetchRegions();
   }, []);
   const onChangeRegion = (e) => {
     const selectedRegionCode = e.target.value;
     const selectedRegion = regionData.find((item) => item.region_code === selectedRegionCode);
     const shippingFee = calculateShippingFee(selectedRegionCode);
     setShippingFee(shippingFee);
     setShippingForm((prevShippingForm) => ({
       ...prevShippingForm,
       region: selectedRegionCode,
       regionName: selectedRegion ? selectedRegion.region_name : '',
       shippingFee: shippingFee,
     }));
     setShipping(shippingFee)
     setTotalCost(total + shippingFee);
     fetchProvinces(selectedRegionCode);
     setProvinceData([]);
     setCityData([]);
     setBarangayData([]);
   };
   const onChangeProvince = (e) => {
     const selectedProvinceCode = e.target.value;
     const selectedProvince = provinceData.find((item) => item.province_code === selectedProvinceCode);
     setShippingForm({
       ...shippingForm,
       province: selectedProvinceCode,
       provinceName: selectedProvince ? selectedProvince.province_name : '',
     });
     fetchCities(selectedProvinceCode);
     setCityData([]);
     setBarangayData([]);
   };
   
   const onChangeCity = (e) => {
     const selectedCityCode = e.target.value;
     const selectedCity = cityData.find((item) => item.city_code === selectedCityCode);
     setShippingForm({
       ...shippingForm,
       city: selectedCityCode,
       cityName: selectedCity ? selectedCity.city_name : '',
     });
     fetchBarangays(selectedCityCode);
     setBarangayData([]); 
   };

   const onChangeBarangay = (e) => {
     const selectedBarangayCode = e.target.value;
     const selectedBarangay = barangayData.find((item) => item.brgy_code === selectedBarangayCode);
     setShippingForm({
       ...shippingForm,
       brgy: selectedBarangayCode,
       brgyName: selectedBarangay ? selectedBarangay.brgy_name : '',
     });
   };
 
   const calculateShippingFee = (selectedRegionCode) => {
     const isNCR = selectedRegionCode === '13'; 
     if (isNCR) {
       return 100;
     } else {
       return 150;
     } 
   };

  return (
    <div id="InputOrderContent">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Input Order</h1>
              <span>Please provide the necessary details below to initiate your order with us.</span>
            </div>
            <div className="col-sm-6">
              <div className='container breadcrum-holder d-flex justify-content-end'>
                <Breadcrumb paths={breadcrumbPaths} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="content">
          <div className="container-fluid row">

            <div className='col-12 col-sm-7'>
              <div className="card card-selectedItem p-3">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 col-sm-4">
                      <h3 className='d-inline-block d-sm-none'>{selectedItem.name}</h3>
                      <div className="col-12">
                        <img src={selectedItem.image} alt={selectedItem.name} className='product-image' />
                      </div>
                    </div>
                    <div className="col-12 col-sm-8">
                      <h3 className='my-3'>{selectedItem.name}</h3>
                      <pre id='description'>
                        {selectedItem.description}
                      </pre>
                      <hr/>
                      <div className="py-2 px-3 mt-4">
                        <h4>Quantity</h4>
                        <div className="quantity-btn align-items-center justify-content-start">
                          <input
                            type="number"
                            min="1"
                            max="50"
                            value={quantity}
                            onChange={handleQuantityChange}
                            className="form-control"
                          />
                          <FaMinus className="minus-btn" onClick={handleDecrement} />
                          <FaPlus className="plus-btn" onClick={handleIncrement} />
 
                        </div>
                      </div>
                      
                      <div className="py-2 px-3 mt-4">
                        <h2 className="mb-0">Price</h2>
                        {selectedItem.dp !== undefined && (
                          <h4>₱ {selectedItem.dp}</h4>
                        )}
                      </div>
                      <div className="mt-4">
                      <button className="btn btn-primary btn-lg align-items-center" onClick={(e) => handleAddToCart(e, selectedItem, quantity)}>
                          <FaCartPlus className='mr-2'/> Add to cart
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-5">
              <div className="card card-cartItems p-2">
                <div className="card-header border-0">
                  <h3>Cart Items</h3>
                </div>
                <div className="card-body table-responsive">
                  <table className='table table-head-fixed table-bordered'>
                    <thead>
                      <tr>
                        <th>Item</th>
                        <th>Price</th>
                        <th>Quantity</th>
                        <th>Sub Total</th>
                        <th>Action</th>
                        
                      </tr>
                    </thead>
                    <tbody>
                      {cartItems.map((item, index) => (
                        <tr key={index}>
                          <td>{item.name}</td>
                          <td>{formatCurrency(item.dp)}</td>
                          <td>{item.quantity}</td>
                          <td>{formatCurrency((item.dp * item.quantity).toFixed(2))}</td>
                          <td>
                            <div onClick={() => handleRemoveFromCart(item.id)} 
                                 className='btn btn-danger align-items-center'>
                                  <MdDeleteForever className='btn-danger'/>
                            </div>
                          </td>
                        </tr>
                      ))}
                      
                    </tbody>
                    
                  </table>
                </div>
                
                <div className="card-footer d-flex justify-content-between align-items-center">
                  <h4 className='mb-0'>Total Price: <small>{formatCurrency(total.toFixed(2))}</small></h4>
                  <button className="btn btn-success btn-lg ml-auto" onClick={handleCheckOut} 
                    disabled={((CashBondBalance - ReturnedCharges) < 150 ) || (cartItems.length === 0 )}>
                    Checkout now
                  </button>
                </div>
                {
                        (CashBondBalance - ReturnedCharges) < 150 ?
                        <div className='text-center text-danger'>
                        <p style={{whiteSpace: 'pre-line'}}>*Your Cashbond is Less than <b>₱150.00</b> you can't Proceed to Checkout</p>
                      </div>
                        :
                        <></>
                      }
                {showCheckOut && (
                <Form>
                  <Modal show={showCheckOut} 
                        dialogClassName={widerModalClass}
                        centered>
                    <Modal.Header>
                      <Modal.Title>Orders Preview</Modal.Title>
                      <div className="cancel-btn">
                          <button className='btn btn-danger' onClick={closeModal}>
                          Cancel
                        </button>
                        </div>
                    </Modal.Header>
                      <Modal.Body>
                      <section className="checkout">
                        
                        <div className="row">
                          
                          {/* Order details */}
                          <div className="col-12 col-sm-4 table-responsive">
                            <table className="table table-striped">
                              <thead>
                                <tr>
                                  <th></th>
                                  <th>Item</th>
                                  <th>Quantity</th>
                                  <th>Price</th>
                                  <th>Total</th>
                                </tr>
                              </thead>
                              <tbody>
                              {cartItems.map((item, index) => (
                                  <tr key={index}>
                                    <td style={{maxWidth: '80px'}}>
                                      <img src={item.image} alt={item.name} style={{width: '100%'}}/>
                                    </td>
                                    <td>{item.name}</td>
                                    <td>{item.quantity}</td>
                                    <td>{formatCurrency(item.dp)}</td>
                                    <td>{formatCurrency((item.dp * item.quantity).toFixed(2))}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            <div className="sub-total ">
                              <p className="lead">Amount Due</p>
                              <div className="table-responsive">
                                <table className="table">
                                  <tr>
                                    <th style={{ width: '50%' }}>Subtotal:</th>
                                    <td>{formatCurrency(total.toFixed(2))}</td>
                                  </tr>
                                  <tr>
                                    <th>Shipping:</th>
                                    <td>{formatCurrency(shippingFee.toFixed(2))}</td>
                                  </tr>
                                  <tr>
                                    <th>Total Cost:</th>
                                    <td>{formatCurrency(totalCost.toFixed(2))}</td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                          </div>
                          
                            {/* buyer info*/}
                          <div className="col-12 col-sm-8">
                            <div className="card">
                              <div className="card-header border-0">
                                <h5>Contact Info</h5>
                              </div>
                              <div className="card-body">

                                <div className="row">
                                  <div className="col-12 col-sm-6">
                                    <div className="form-group p-1">
                                      <label htmlFor='Fullname'>Full Name</label>
                                      <input type="text" 
                                              className="form-control" 
                                              placeholder="Full Name"
                                              name='Fullname'
                                              id='Fullname'
                                              value={userInformation.Fullname}
                                              onChange={(e) => {handleOrdersInfo(e)}}
                                              disabled/>
                                    </div>
                                  </div>
                                  <div className="col-12 col-sm-6">
                                    <div className="form-group p-1">
                              
                                      <label htmlFor='Email'>Email Address</label>
                                      <input type="text" 
                                              className="form-control" 
                                              placeholder="Email Address"
                                              name='Email'
                                              value={user.email}
                                              id='Email'
                                              disabled/>
                                    </div>
                                  </div>
                                  <div className="col-12 col-sm-6">
                                    <div className="form-group p-1">
                                      <label htmlFor='Address'>Address</label>
                                      <input type="text" 
                                              className="form-control" 
                                              placeholder="Address"
                                              name='Address'
                                              id='Address'
                                              onChange={(e) => {handleOrdersInfo(e)}}
                                              value={Address.Address }
                                              disabled
                                              />
                                    </div>
                                  </div>
                                  <div className="col-12 col-sm-6">
                                    <div className="form-group p-1">
                                      <label htmlFor='ContactNum'>Contact Number</label>
                                      <input type="text" 
                                              className="form-control" 
                                              placeholder="+63 Contact Number"
                                              name='ContactNum'
                                              id='ContactNum'
                                              value={userInformation.ContactNum}
                                              onChange={(e) => {handleOrdersInfo(e)}}
                                              disabled/>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* shipping info*/}
                            <div className="card">
                              <div className="card-header border-0">
                                <h5>Shipping Details</h5>
                              </div>
                              <div className="card-body">
                                <div className="row">
                                  <div className="col-12 col-sm-6">
                                    <div className="form-group p-1">
                                      <label htmlFor='Fullname'>Recipient Name</label><span className='text-danger'> * (Full Name)</span>
                                      <input type="text" 
                                              className="form-control" 
                                              placeholder="Full Name"
                                              name='Fullname'
                                              id='Fullname'
                                              value={userInformation.Fullname}
                                              onChange={(e) => {handleOrdersInfo(e)}}
                                              required/>
                                    </div>
                                  </div>
                                  <div className="col-12 col-sm-6">
                                    <div className="form-group d-flex flex-column">
                                      <label>Payment Method<span className='text-danger'> *</span></label>
                                      {totalCost > 4000 ? 
                                      <>
                                      <div className="form-check form-check-inline p-1">
                                      <label className="form-check-label" htmlFor="COD">
                                          COD <span className='text-muted'>(maximum limit of 4000.00 php)</span>
                                      </label>
                                      </div>
                                        <div className="form-check form-check-inline p-1">
                                        <input type="radio" 
                                                className="form-check-input mb-0"
                                                name="PaymentMethod"
                                                id="bank"
                                                onChange={(e) => {handleOrdersInfo(e)}}
                                                value="bank" 
                                                defaultChecked
                                                required
                                                />
                                        <label className="form-check-label" for="bank">Bank Transfer</label>
                                      </div>
                                      </>
                                    :
                                    <>
                                    <div className="form-check form-check-inline p-1">
                                      <input
                                          type="radio"
                                          className="form-check-input mb-0"
                                          name='PaymentMethod'
                                          id='COD'
                                          value="COD"
                                          onChange={(e) => { handleOrdersInfo(e) }}
                                          defaultChecked
                                          required
                                      />
                                      <label className="form-check-label" htmlFor="COD">
                                          COD <span className='text-muted'>(maximum limit of 4000.00 php)</span>
                                      </label>
                                  </div>
                                  <div className="form-check form-check-inline p-1">
                                    <input type="radio" 
                                            className="form-check-input mb-0"
                                            name="PaymentMethod"
                                            id="bank"
                                            onChange={(e) => {handleOrdersInfo(e)}}
                                            value="bank" 
                                            required
                                            />
                                    <label className="form-check-label" for="bank">Bank Transfer</label>
                                  </div>
                                  </>
                                   }

                                    </div>
                                    
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-12 col-sm-6">
                                    <div className='form-group p-1'>
                                      <label htmlFor='region'>Region</label><span className='text-danger'> *</span>
                                      <select
                                        className='form-control'
                                        name='region'
                                        value={shippingForm.region}
                                        onChange={onChangeRegion}
                                        required
                                      >
                                        <option value='' disabled>
                                          Select Region
                                        </option>
                                        {regionData.map(item => (
                                          <option
                                            key={item.region_code}
                                            value={item.region_code}
                                          >
                                            {item.region_name}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-12 col-sm-6">
                                    <div className="form-group p-1">
                                      <label htmlFor='province'>Province</label><span className='text-danger'> *</span>
                                      <select
                                        className='form-control'
                                        name='province'
                                        value={shippingForm.province}
                                        onChange={onChangeProvince}
                                        required
                                      >
                                        <option value='' disabled>
                                          Select Province
                                        </option>
                                        {provinceData.map(item => (
                                          <option
                                            key={item.province_code}
                                            value={item.province_code}
                                          >
                                            {item.province_name}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-12 col-sm-6">
                                    <div className='form-group p-1'>
                                      <label htmlFor='city'>City / Municipal</label><span className='text-danger'> *</span>
                                      <select
                                        className='form-control'
                                        name='city'
                                        value={shippingForm.city}
                                        onChange={onChangeCity}
                                        required
                                      >
                                        <option value='' disabled>
                                          Select City / Municipality
                                        </option>
                                        {cityData.map(item => (
                                          <option 
                                            key={item.city_code} 
                                            value={item.city_code}
                                          >
                                            {item.city_name}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-12 col-sm-6">
                                    <div className="form-group p-1">
                                      <label htmlFor='brgy'>Barangay</label><span className='text-danger'> *</span>
                                      <select
                                        className='form-control'
                                        name='brgy'
                                        value={shippingForm.brgy}
                                        onChange={onChangeBarangay}
                                        required
                                      >
                                        <option value='' disabled>
                                          Select Barangay
                                        </option>
                                        {barangayData.map(item => (
                                          <option 
                                            key={item.brgy_code} 
                                            value={item.brgy_code}
                                          >
                                            {item.brgy_name}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-12 col-sm-6">
                                    <div className="form-group p-1">
                                      <label htmlFor='street'>Street</label><span className='text-danger'> *</span>
                                      <input type="text" 
                                              className="form-control" 
                                              placeholder="Street"
                                              name='street'
                                              id='street'
                                              value={shippingForm.street}
                                              onChange={(e) => {handleOrdersInfo(e)}}
                                              required/>
                                    </div>
                                  </div>
                                  <div className="col-12 col-sm-6">
                                    <div className="form-group p-1">
                                      <label htmlFor='postalCode'>Postal Code</label><span className='text-danger'> *</span>
                                      <input type="text" 
                                              className="form-control" 
                                              placeholder="Postal Code"
                                              name='postalCode'
                                              id='postalCode'
                                              value={shippingForm.postalCode}
                                              onChange={(e) => {handleOrdersInfo(e)}}
                                              required/>
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div className="form-group p-1">
                                      <label htmlFor='landmark'>Landmark</label><span className='text-muted'> (Optional)</span>
                                      <textarea type="text" 
                                              className="form-control" 
                                              placeholder="Landmark / Exact Address / Guidance Instructions for Directions"
                                              name='landmark'
                                              id='landmark'
                                              value={shippingForm.landmark}
                                              onChange={(e) => {handleOrdersInfo(e)}}
                                              required/>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>

                      </section>
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-between">
    
                        <div className="total-cost">
                          <h3>Total: {formatCurrency(totalCost.toFixed(2))}</h3>
                        </div>
                        <div className="checkout-btn">
                        <button type='submit' onClick={handlePurchase} className='btn btn-success' disabled={
                          shippingForm.brgy == '' ||
                          shippingForm.postalCode == '' ||
                          shippingForm.street == ''
                          }>
                              Proceed Purchase
                        </button>
                      </div>
        
                    </Modal.Footer>
                  </Modal>
                </Form>
                )}
              </div>
            </div>

            <div className="col-12">
              <div className='card card-otherItem p-3'>
                <div className="card-header border-0 align-items-center">
                  <h2 className='my-3'>Other Products</h2>
                </div>
                <div className='card-body row'>
                  {products
                    .filter((otherProduct) => otherProduct !== selectedItem)
                    .map((otherProduct, index) => (
                      <div
                        key={index}
                        className="col-12 col-sm-3 p-4 d-flex"
                        onClick={() => handleProductClick(otherProduct)}
                        style={{ cursor: 'pointer' }}
                      >
                        <div className='item-holder d-flex flex-column'>
                          <div className="image-holder">
                            <img src={otherProduct.image} alt={otherProduct.name} />
                          </div>
                          <div>
                            <h4>{otherProduct.name}</h4>
                            {otherProduct.dp !== undefined && (
                              <span>₱ {otherProduct.dp}</span>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>

          </div>
      </div>
    </div>
  );
}

export default InputOrder;
