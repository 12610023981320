import React, { useState, useEffect } from 'react';
import './Homepage.css'
import 'aos/dist/aos.css';
import AOS from 'aos';
import Hero from '../../../Assets/Hero/Hero';
import Footer from '../../Components/Footer/Footer';
import image_CDP from '../../../Assets/Images/CDP_green.png';
import Navbar from '../../Components/NavBar/Nabvar';
import { useLocation } from 'react-router-dom';
import { get, child, ref, set,serverTimestamp } from 'firebase/database';
import { db,storage } from '../../../firebase/config';
import { regions, provinces, cities, barangays } from 'select-philippines-address';
import { message, Popconfirm } from 'antd';
import { Modal } from 'react-bootstrap';
function HomePage() {

  useEffect(() => {
    AOS.init();
  }, []);
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
   }, [location.pathname]);
   const widerModalClass = 'wide_modal';
   const [selectedPackage, setSelectedPackage] = useState(null);
   const [selectedPackageDetails, setSelectedPackageDetails] = useState({});
   const [showCheckoutModal, setShowCheckoutModal] = useState(false);
   const [isLoading, setIsLoading] = useState(false);
   const [isFormFilled, setIsFormFilled] = useState(false);
   const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('bank');
   const [shippingFee, setShippingFee] = useState(0);
   const [shippingForm, setShippingForm] = useState({
     Fullname: '',
     Email: '',
     ContactNum: '',
     PaymentMethod: '',
     region: '',
     regionName: '',
     province: '',
     provinceName: '',
     city: '',
     cityName: '',
     brgy: '',
     brgyName: '',
     street: '',
     landmark: '',
     postalCode: '',
     shippingFee: 0.0,
   });

   useEffect(() => {
    const checkFormFilled = () => {
      const requiredFields = ['Fullname', 'ContactNum', 'Email', 'region', 'province', 'city', 'brgy', 'postalCode'];
      return requiredFields.every(field => !!shippingForm[field]);
    };
    setIsFormFilled(checkFormFilled());
  }, [shippingForm]);

  useEffect(() => {
    get(child(ref(db), 'products')).then(async (snapshot) => {
      if (snapshot.exists()) {
        const productsData = snapshot.val();
        const productsArray = Object.values(productsData);

      } else {
        console.log('No data available');
      }
    }).catch((error) => {
      console.error(error);
    });
  }, []);

  const packagePrices = {
    silver: {
      packageID: 'Silver',
      price: 3988.0,
      product: [
        { name: 'Chosen Pure Barley Powder', quantity: 4 },
      ],
    },
    gold: {
      packageID: 'Gold',
      price: 8888.0,
      product: [
        { name: 'Chosen Pure Barley Powder', quantity: 9 },
      ],
    },
    platinum: {
      packageID: 'Platinum',
      price: 14888.0,
      product: [
        { name: 'Chosen Pure Barley Powder', quantity: 14 },
        { name: 'Chosen Choco Barley', quantity: 1 },
        { name: 'Chosen 15in1 Latte Coffee', quantity: 1 },
        { name: 'Ohayo Premium Kojic Soap', quantity: 1 },
      ],
    },
    jade: {
      packageID: 'Jade',
      price: 198888,
      product: [
        { name: 'Chosen Pure Barley Powder', quantity: 200 },
        { name: 'Chosen Choco Barley', quantity: 10 },
        { name: 'Chosen 15in1 Latte Coffee', quantity: 10 },
        { name: 'Ohayo Premium Kojic Soap', quantity: 10 },
      ],
    },
  };
  
  const handleGetNow = (packageId) => {
    setSelectedPackage(packageId);
    const selectedPackageDetails = packagePrices[packageId];
  
    if (selectedPackageDetails) {
        // Set the state or perform other actions as needed
        const packagePrice = packagePrices[selectedPackage];
        console.log(selectedPackageDetails)
      setSelectedPackageDetails(selectedPackageDetails);
      setShowCheckoutModal(true);
    } else {
      console.error("Invalid packageId:", packageId);
    }
  };

  const handleCancelCheckOut = () => {
    setSelectedPackage(null);
    setShowCheckoutModal(false);
    setShippingFee(0)
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-PH', { style: 'currency', currency: 'PHP' }).format(amount);
  };

  const generateOrderID = () => {
    const numericPart = Math.floor(Math.random() * 1000000000).toString().padStart(9, '0');
    const alphanumericCharacter = String.fromCharCode(65 + Math.floor(Math.random() * 26)); 
    const parcelID = `CDP${numericPart}${alphanumericCharacter}`;
    return parcelID;
  };
  const generateInvoiceID = () => {
    const numericPart = Math.floor(Math.random() * 1000000).toString().padStart(6, '0');
    const alphanumericCharacter = String.fromCharCode(65 + Math.floor(Math.random() * 26)); 
    const parcelID = `CDP${numericPart}${alphanumericCharacter}`;
    return parcelID;
  };
  const handleProceedPurchase = async ()=>{
    setIsLoading(true);
    try {
      await new Promise((resolve) => setTimeout(resolve, 1500));
      const orderID = generateOrderID();
      const InvoiceID = generateInvoiceID();
      const timestamp = serverTimestamp();
      const orderData = {        
        invoice: {
        From: {
          Name: 'Chosen Drop Point, Inc.',
          Address: 'Unit B-02 Lower 2nd Floor St. Francis Square Building Julia Vargas Avenue cor. Bank Drive Ortigas Center Mandaluyong',
          Phone: '(02) – 7006-8924',
          Email: 'iamchoseninternational@gmail.com',
        },
        To: {
          Name: shippingForm.Fullname,
          Address: shippingForm.landmark+", "+shippingForm.brgyName+", "+ shippingForm.cityName+", "+shippingForm.provinceName +", "+shippingForm.regionName +", '"+shippingForm.postalCode+ "'",
          Phone: shippingForm.ContactNum,
          Email: shippingForm.Email ,
        },
        InvoiceNumber: InvoiceID,
        OrderID: orderID,
        PaymentMethod: selectedPaymentMethod,
        items: selectedPackageDetails.product.map((item) => ({
          name: item.name,
          quantity: item.quantity,
          price: 0,
          dp: 0,
          TotalPrice:0,
        })),
        AmountDue: {
          Subtotal: selectedPackageDetails.price,
          Shipping:shippingFee,
          Total: calculateTotalCost(),
        },
      },
      address: {
        fullAddress:shippingForm.brgyName+", "+ shippingForm.cityName+", "+shippingForm.provinceName,
        street: shippingForm.street,
        landmark: shippingForm.landmark,
      },
        user: 'N/A',
        seller: 'Company',
        status: "Pending",
        total: selectedPackageDetails.price + shippingFee,
        companySales:selectedPackageDetails.price,
        sales:0,
        timestamp: timestamp,
        package:selectedPackageDetails.packageID,
        MemberID: 'N/A',
        customer:{
          Email:shippingForm.Email,
          Fullname:shippingForm.Fullname,
          ContactNum:shippingForm.ContactNum,
          PostalCode: shippingForm.postalCode,
          Region: shippingForm.regionName
        },
        items: {
           0: {
          name: selectedPackageDetails.packageID,
          quantity: 1,
          price:selectedPackageDetails.price,
          dp:selectedPackageDetails.price,
          TotalPrice:selectedPackageDetails.price,
        }
      }
,

      };
      await set(ref(db, `orders/${orderID}`), orderData);
      const products = selectedPackageDetails.product
      const subject = `Order ${orderID} is Successful wait for Admin Approval`
      const email = shippingForm.Email
      const response = await fetch('https://cdp-email-bef0d127fa8b.herokuapp.com/sendEmailPackage', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, products,  orderData, subject }),
      });
      if (response.ok) {
        console.log('Email sent successfully');
      } else {
        console.error('Failed to send email');
      }
      
      setSelectedPackageDetails([]);
      setShippingForm({
        Fullname: '',
        ContactNum: '',
        Email: '',
        region: '',
        regionName: '',
        province: '',
        provinceName: '',
        city: '',
        cityName: '',
        brgy: '',
        brgyName: '',
        street: '',
        landmark: '',
        postalCode: '',
        PaymentMethod: '',
        total: 0.0,
        shippingFee: 0.0,
        totalCost: 0.0
      });
      setShowCheckoutModal(false);
      message.success('Ordered Successfully');
      setIsLoading(false); 
    } catch (error) {
      console.error('Error placing order:', error);
      setIsLoading(false);
    }
  };
  const handleOrdersInfo = (e) => {
    setShippingForm({...shippingForm, [e.target.name]: e.target.value})
  }
  const [regionData, setRegionData] = useState([]);
  const [provinceData, setProvinceData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [barangayData, setBarangayData] = useState([]);
  const fetchRegions = () => {
    regions()
      .then(response => {
        setRegionData(response);
        setShippingForm(prevData => ({ ...prevData, region: response[null]?.region_code || '' }));
      })
      .catch(error => console.error('Error fetching regions:', error));
  };
  const fetchProvinces = region_code => {
    provinces(region_code).then(response => {
      setProvinceData(response);
    });
  };
  const fetchCities = provinceCode => {
    cities(provinceCode).then(response => {
      setCityData(response);
    });
  };
  const fetchBarangays = cityCode => {
    barangays(cityCode).then(response => {
      setBarangayData(response);
    });
  };
  useEffect(() => {
    fetchRegions();
  }, []);
  const onChangeRegion = (e) => {
    const selectedRegionCode = e.target.value;
    const selectedRegion = regionData.find((item) => item.region_code === selectedRegionCode);
    const shippingFee = calculateShippingFee(selectedRegionCode);
    setShippingFee(shippingFee);

    setShippingForm((prevShippingForm) => ({
      ...prevShippingForm,
      region: selectedRegionCode,
      regionName: selectedRegion ? selectedRegion.region_name : '',
      shippingFee: shippingFee,
    }));

    fetchProvinces(selectedRegionCode);
    setProvinceData([]);
    setCityData([]);
    setBarangayData([]);
  };
  const onChangeProvince = (e) => {
    const selectedProvinceCode = e.target.value;
    const selectedProvince = provinceData.find((item) => item.province_code === selectedProvinceCode);
    setShippingForm({
      ...shippingForm,
      province: selectedProvinceCode,
      provinceName: selectedProvince ? selectedProvince.province_name : '',
    });
    fetchCities(selectedProvinceCode);
    setCityData([]); 
    setBarangayData([]);
  };
  const onChangeCity = (e) => {
    const selectedCityCode = e.target.value;
    const selectedCity = cityData.find((item) => item.city_code === selectedCityCode);
    setShippingForm({
      ...shippingForm,
      city: selectedCityCode,
      cityName: selectedCity ? selectedCity.city_name : '',
    });
    fetchBarangays(selectedCityCode);
    setBarangayData([]); 
  };
  const onChangeBarangay = (e) => {
    const selectedBarangayCode = e.target.value;
    const selectedBarangay = barangayData.find((item) => item.brgy_code === selectedBarangayCode);
    setShippingForm({
      ...shippingForm,
      brgy: selectedBarangayCode,
      brgyName: selectedBarangay ? selectedBarangay.brgy_name : '',
    });
  };
  const calculateShippingFee = (selectedRegionCode) => {
    const isNCR = selectedRegionCode === '13';
    if (isNCR) {
      return 50;
    } else  {
      return 100;
    }
  };
  const onChangeMethod = (e) => {
    setSelectedPaymentMethod(e.target.value);
  };

  const calculateTotalCost = () => {
    if (selectedPackageDetails && shippingFee) {
      return selectedPackageDetails.price + shippingFee;
    }
    return 0; // Or handle it differently based on your requirements
  };


  return (
    <>
    <Navbar/>
    <div className='wrapper' id='home-wrapper'>
      <Hero />
      <section className='container-fluid'>

        <div className="section1">

          <div className='header'>
            <h1  data-aos="fade-down"
                 data-aos-easing="linear"
                 data-aos-duration="1000"
                 data-aos-once="true">
                  Welcome to Chosen Drop Point
            </h1>
          </div>

          <div className='row'>

            <div className="col-md-6 content-left">
              <img src={image_CDP} alt=''
                   data-aos="fade-up" 
                   data-aos-delay="0" 
                   data-aos-duration="2000" 
                   data-aos-once="true"/>
            </div>

            <div className="col-md-6 content-right">
              <p data-aos="fade-left" 
                 data-aos-easing="linear" 
                 data-aos-duration="1000" 
                 data-aos-once="true">
                Chosen Drop Point is a cutting-edge platform designed to simplify 
                and enhance your delivery experience. Say goodbye to the hassle of 
                tracking packages or waiting around for couriers to arrive at your 
                doorstep. With our innovative service, you can effortlessly discover
                and select the most convenient drop points in your neighborhood. 
                Whether you're a dedicated online shopper, a busy professional, 
                or a small business owner, we're here to make your life easier.</p>
            </div>
          </div>
        </div>

        <div className="section2">

          <div className='header'>
            <h1 data-aos="fade"
                data-aos-easing="linear"
                data-aos-duration="2000"
                data-aos-once="true">
                  Packages and Pricing
            </h1>
          </div>

          <div className='row' id='price-holder'>

            <div id='silver'
                 data-aos="fade-right" 
                 data-aos-duration="1000" 
                 data-aos-once="true" 
                 className="col-md-6 lg-4">
            
                <h4 className="col-md-4">Silver</h4>
                  <p className="font-weight-bold">₱ <span className="display-2 font-weight-bold">3,988</span>.00</p>
                  <ul className="list-unstyled">
                      <li>4 boxes Chosen Pure Barley</li>
                      <li>Product value: ₱ 4,000.00</li>
                  </ul>
             
                  <a onClick={() => handleGetNow('silver')}>Get Now</a>
            </div>

            <div id='platinum'
                 data-aos="fade-up" 
                 data-aos-duration="1500" 
                 data-aos-once="true" 
                 className="col-md-6 lg-4">
                  
                 <h4 className="col-md-6">Platinum</h4>
                  <p className="font-weight-bold">₱ <span className="display-2 font-weight-bold">14,888</span>.00</p>
                  <ul className="list-unstyled">
                      <li>14 boxes Chosen Pure Barley</li>
                      <li>1 box Chosen Choco Barley</li>
                      <li>1 box Chosen 15in1 Latte Coffee</li>
                      <li>1 pc Ohayo Premium Kojic Soap</li>
                      <li>Product value: ₱ 15,030.00</li>
                  </ul>
                 
                  <a onClick={() => handleGetNow('platinum')}>Get Now</a>
            </div>

            <div id='jade'
                 data-aos="zoom-in-down" 
                 data-aos-duration="2500" 
                 data-aos-once="true" 
                 className="col-md-6 lg-4">
                  
                 <h4 className="col-md-4">Jade</h4>
                  <p className="font-weight-bold">₱ <span className="display-2 font-weight-bold">198,888</span>.00</p>
                  <ul className="list-unstyled">
                      <li>200 boxes Chosen Pure Barley</li>
                      <li>10 boxes Chosen Choco Barley</li>
                      <li>10 boxes Chosen 15in1 Latte Coffee</li>
                      <li>10 pcs Ohayo Premium Kojic Soap</li>
                      <li>Get 10 Vouchers</li>
                      <li>Product Value ₱210,300</li>
                  </ul>
                  
                  <a onClick={() => handleGetNow('jade')}>Get Now</a>
            </div>
           
            <div id='gold'
                 data-aos="fade-left" 
                 data-aos-duration="1000" 
                 data-aos-once="true" 
                 className="col-md-6 lg-4">
                  
                 <h4 className="col-md-4">Gold</h4>
                  <p className="font-weight-bold">₱<span className="display-2 font-weight-bold">8,888</span>.00</p>
                  <br/>
                  <ul className="list-unstyled">
                      <li>9 boxes Chosen Pure Barley</li>
                      <li>Product Value ₱9,000.00</li>
                      <br/>
                  </ul>
   
                  <a onClick={() => handleGetNow('gold')}>Get Now</a>
            </div>
            
            
          </div>

        </div>

        <Modal show={showCheckoutModal} onHide={handleCancelCheckOut}  dialogClassName={widerModalClass}>
          <Modal.Header>
            <Modal.Title>Orders Preview</Modal.Title>
            <div className="cancel-btn">
                <button className='btn btn-danger' onClick={handleCancelCheckOut}>
                Cancel
              </button>
              </div>
          </Modal.Header>
          <Modal.Body>
            <section className="checkout">
              
              <div className="row">
                
                <div className="col-12 col-md-4 table-responsive">
                  {/* Order details */}
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Item</th>
                        <th>Quantity</th>
                      </tr>
                    </thead>
                    <tbody>
                    {selectedPackageDetails.product &&
                      selectedPackageDetails.product.map((item, index) => (
                        <tr key={index}>
                          <td>{item.name}</td>
                          <td>{item.quantity}</td>
                        </tr>
                      ))}
                  </tbody>
                  </table>
                  <div className="sub-total ">
                    <p className="lead">Amount Due</p>
                    <div className="table-responsive">
                      <table className="table">
                        <tr>
                          <th style={{ width: '50%' }}>Subtotal:</th>
                          <td>{formatCurrency(selectedPackageDetails.price)}</td>
                        </tr>
                        <tr>
                          <th>Shipping:</th>
                          <td>{formatCurrency(shippingFee)}</td>
                        </tr>
                        <tr>
                          <th>Total Cost:</th>
                          <td>{formatCurrency(calculateTotalCost())}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
                
                
                <div className="col-12 col-md-8">
                  {/* buyer info*/}
                  <div className="card">
                    <div className="card-header border-0">
                      <h5>Contact Info</h5>
                    </div>
                    <div className="card-body">

                      <div className="row">
                        <div className="col-12 col-sm-6">
                          <div className="form-group p-1">
                            <label htmlFor='Fullname'>Recipient Name</label><span className='text-danger'> * (Full Name)</span>
                            <input type="text" 
                                  className="form-control" 
                                  placeholder="Full Name"
                                  name='Fullname'
                                  id='Fullname'
                                  onChange={(e) => {handleOrdersInfo(e)}}
                                  required/>
                          </div>
                        </div>
                        <div className="col-12 col-sm-6">
                          <div className="form-group p-1">
                            <label htmlFor='Email'>Email Address</label><span className='text-danger'> *</span>
                            <input type="text" 
                                  className="form-control" 
                                  placeholder="Email Address"
                                  name='Email'
                                  id='Email'
                                  onChange={(e) => {handleOrdersInfo(e)}}
                                  required/>
                          </div>
                        </div>
                        <div className="col-12 col-sm-6">
                          <div className="form-group p-1">
                            <label htmlFor='ContactNum'>Contact Number</label><span className='text-danger'> *</span>
                            <input type="text" 
                                  className="form-control" 
                                  placeholder="+63 Contact Number"
                                  name='ContactNum'
                                  id='ContactNum'
                                  onChange={(e) => {handleOrdersInfo(e)}}
                                  required/>
                          </div>
                        </div>
                        <div className="col-12 col-sm-6">
                          <div className="form-group d-flex flex-column">
                            <label>Payment Method<span className='text-danger'> *</span></label>
                            <div className="form-check form-check-inline p-1">
                            <input
                                    type="radio"
                                    className="form-check-input mb-0"
                                    name="PaymentMethod"
                                    id="bank"
                                    value="bank"
                                    onChange={onChangeMethod}
                                    checked={selectedPaymentMethod === 'bank'}
                                  />
                              <label className="form-check-label" for="bank">Bank Transfer</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* shipping info*/}
                  <div className="card">
                    <div className="card-header border-0">
                      <h5>Shipping Address</h5>
                    </div>
                    <div className="card-body">
                    <div className="row">
                    <div className="col-12 col-sm-6">
                      <div className='form-group p-1'>
                        <label htmlFor='region'>Region</label><span className='text-danger'> *</span>
                        <select
                          className='form-control'
                          name='region'
                          value={shippingForm.region}
                          onChange={onChangeRegion}
                          required
                        >
                          <option value='' disabled>
                            Select Region
                          </option>
                          {regionData.map(item => (
                            <option
                              key={item.region_code}
                              value={item.region_code}
                            >
                              {item.region_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group p-1">
                        <label htmlFor='province'>Province</label><span className='text-danger'> *</span>
                        <select
                          className='form-control'
                          name='province'
                          value={shippingForm.province}
                          onChange={onChangeProvince}
                          required
                        >
                          <option value='' disabled>
                            Select Province
                          </option>
                          {provinceData.map(item => (
                            <option
                              key={item.province_code}
                              value={item.province_code}
                            >
                              {item.province_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className='form-group p-1'>
                        <label htmlFor='city'>City / Municipal</label><span className='text-danger'> *</span>
                        <select
                          className='form-control'
                          name='city'
                          value={shippingForm.city}
                          onChange={onChangeCity}
                          required
                        >
                          <option value='' disabled>
                            Select City / Municipality
                          </option>
                          {cityData.map(item => (
                            <option 
                              key={item.city_code} 
                              value={item.city_code}
                            >
                              {item.city_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group p-1">
                        <label htmlFor='brgy'>Barangay</label><span className='text-danger'> *</span>
                        <select
                          className='form-control'
                          name='brgy'
                          value={shippingForm.brgy}
                          onChange={onChangeBarangay}
                          required
                        >
                          <option value='' disabled>
                            Select Barangay
                          </option>
                          {barangayData.map(item => (
                            <option 
                              key={item.brgy_code} 
                              value={item.brgy_code}
                            >
                              {item.brgy_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group p-1">
                        <label htmlFor='street'>Street</label><span className='text-danger'> *</span>
                        <input type="text" 
                                className="form-control" 
                                placeholder="Street"
                                name='street'
                                id='street'
                                value={shippingForm.street}
                                onChange={(e) => {handleOrdersInfo(e)}}
                                required/>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group p-1">
                        <label htmlFor='postalCode'>Postal Code</label><span className='text-danger'> *</span>
                        <input type="text" 
                                className="form-control" 
                                placeholder="Postal Code"
                                name='postalCode'
                                id='postalCode'
                                value={shippingForm.postalCode}
                                onChange={(e) => {handleOrdersInfo(e)}}
                                required/>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group p-1">
                        <label htmlFor='landmark'>Landmark</label><span className='text-muted'> (Optional)</span>
                        <textarea type="text" 
                                className="form-control" 
                                placeholder="Landmark / Exact Address / Guidance Instructions for Directions"
                                name='landmark'
                                id='landmark'
                                value={shippingForm.landmark}
                                onChange={(e) => {handleOrdersInfo(e)}}
                                required/>
                      </div>
                    </div>
                  </div>
                    </div>
                  </div>
                </div>

              </div>

            </section>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between">

              <div className="total-cost">
                <h3>Total: {formatCurrency(calculateTotalCost())}</h3>
              </div>

              <div className="checkout-btn">
                <button
                  type='button'
                  onClick={handleProceedPurchase}
                  className='btn btn-success'
                  disabled={!isFormFilled || isLoading}
                >
                  {isLoading && (
                    <span className="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span>
                  )}
                  {isLoading ? 'Processing...' : 'Proceed Purchase'}
                </button>
              </div>

          </Modal.Footer>
        </Modal>

      </section>
      <Footer/>
    </div>
    </>
  )
}

export default HomePage;
