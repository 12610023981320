import React from 'react'
import { Link } from 'react-router-dom'
import './Breadcrumb.css'

const Breadcrumb = ({paths}) => {
  return (
    
    <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
            {paths.map((path, index) => (
            <li key={index} className={`breadcrumb-item ${index === paths.length - 1 ? 'active' : ''}`} aria-current={index === paths.length - 1 ? 'page' : null}>
                {index < paths.length - 1 ? (
                <Link to={path.url}>{path.label}</Link>
                ) : (
                    <span>{path.label}</span>
                )}
            </li>
            ))}
        </ol>
    </nav>
  );
;}

export default Breadcrumb;
