import React, { useEffect, useState } from 'react'
import './StyleSheets/PageStyle.css'
import Breadcrumb from '../../Assets/Breadcrumb/Breadcrumb';
import { Table, Input } from 'antd';
import { IoMdArrowDropdown } from 'react-icons/io';
import { db } from "../../firebase/config"
import { get, ref, query, orderByChild, equalTo } from 'firebase/database';
import moment from 'moment';
const { Search } = Input;
const CashOutHistory = () => {

    const breadcrumbPaths = [
        { label: 'Home', url: '/MyChosenDropPoint' },
        { label: 'Cash-out Request', url: '/CashOutRequest' },
      ];
      const user = JSON.parse(localStorage.getItem('user'))
      const [requestTable, setRequestTable] = useState([]);
     
      useEffect(() => {
        const requestFetch = async () => {
          try {
            // Assuming 'request' is the root node for requests in your database
            const requestRef = ref(db, 'request');
    
            // Query only for requests where user is user.uid
            const userQuery = query(requestRef, orderByChild('user'), equalTo(user?.uid));
    
            const snapshot = await get(userQuery);
    
            if (snapshot.exists()) {
              // Convert the snapshot data to an array
              const requestArray = Object.entries(snapshot.val())
                .map(([requestID, requestData]) => ({
                  requestID,
                  ...requestData
                }))
                .sort((a, b) => b.timestamp - a.timestamp);
    
              setRequestTable(requestArray);
            } else {
              setRequestTable([]);
            }
          } catch (error) {
            console.error(error);
          }
        };
    
        requestFetch();
      }, [user?.uid]);
    const sortInfo = useState({});
    
    const columns = [
      {
        title: "Request #",
        dataIndex: "RequestID",
        key: "RequestID",
      },
      {
        title: "Date",
        dataIndex: "timestamp",
        key: "timestamp",
        render: (timestamp) => moment(timestamp).format('MMM. DD, YYYY'),
      },
      {
        title: "Recipient",
        dataIndex: "Recipient",
        key: "Recipient",
      },
      {
        title: "Payment Method",
        dataIndex: "Method",
        key: "Method",
        render: (method) => `via ${method}`,
        sortRequest: sortInfo.columnKey === 'Method' && sortInfo.request,

        filters: [
          { text: 'GCash', value: 'GCash' },
          { text: 'Bank Account', value: 'Bank Account' },
          { text: 'Palawan', value: 'Palawan' },
        ],
        onFilter: (value, record) => record.Method === value,
        filterIcon: (filtered) => (
          <IoMdArrowDropdown style={{ color: filtered ? '#1890ff' : undefined, fontSize: '20px' }} />
        ),
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        render: (text, record) => (
          <span>
            {new Intl.NumberFormat('en-PH', {
              style: 'currency',
              currency: 'PHP',
            }).format(record.amount)}
          </span>
        ),
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (status) => {
          let alertClass = '';
    
          switch (status) {
            case 'Pending':
              alertClass = 'alert alert-secondary';
              break;
            case 'Approved':
              alertClass = 'alert alert-success';
              break;
            case 'Declined':
              alertClass = 'alert alert-danger';
              break;
            default:
              alertClass = 'alert';
          }
    
          return <div className={alertClass}>{status}</div>;
        },
      },
      
    ];
    const [searchText, setSearchText] = useState('');
    const handleSearch = (value) => {
      setSearchText(value);
    };
    const filteredTableData = requestTable.filter((record) => {
      const lowerSearchText = searchText.toLowerCase();
      return (
        record.RequestID.toLowerCase().includes(lowerSearchText) ||
        record.status.toLowerCase().includes(lowerSearchText)
      );
    });

  return (
    <div id='cashOutContent'>
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
            <h1 className="m-0">Cash-Out History</h1>
              <span>View a detailed record of your past cash-out transactions for a comprehensive overview of your financial activities.</span>
            </div>
            <div className="col-sm-6">
              <div className='container breadcrumb-holder d-flex justify-content-end'>
                <Breadcrumb paths={breadcrumbPaths} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="content">
        <div className="container-fluid row">
          <div className="col-12">
            <div className="card card-container">
              <div className="card-header">
                <div className="card-title">
                  <h3>Transaction History</h3>
                </div>
                <div className="card-tools d-flex">
                  {/* <div className="input-group input-group-sm mr-3" style={{ width: '200px' }}>
                    <input
                      type="text"
                      name="table_search"
                      className="form-control float-right"
                      id="cash-out-search"
                      placeholder="Search"
                      // value={searchTerm}
                      // onChange={handleSearch}
                    />
                    <IoSearchSharp/>
                  </div> */}
                  <Search
                    className='mr-2'
                    placeholder="Search Request Here..."
                    allowClear
                    size="large"
                    width="auto"
                    onSearch={handleSearch}
                  />
                </div>
              </div>

              <div className="card-body table-responsive p-1">
                <Table 
                  columns={columns}
                  dataSource={filteredTableData}
                  pagination={{ pageSize: 10 }}
                  scroll={{ y: 600, x: 1500 }}
                  style={{ minHeight: '48vh' }}
                />
                {/* <table className="table table-striped">
                  <thead>
                    <tr>
                        <th>Request #</th>
                        <th>Date</th>
                        <th>Amount</th>
                        <th>Member ID</th>
                        <th>Recipient</th>
                        <th>Payment Method</th>
                        <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                  {Array.isArray(requestTable) && requestTable.map((requestData, requestID) => (
                    <tr key={requestID}>
                      <td>{requestData.RequestID}</td>
                      <td>{moment(requestData.timestamp).format('MMM. DD, YYYY')}</td>
                      <td>{requestData.amount}</td>
                      <td>{requestData.MemberID}</td>
                      <td>{requestData.Recipient}</td>
                      <td>Via {requestData.Method}</td>
                      <td className='alert_warning'> {requestData.status} </td>
                    </tr>
                  ))}
                  </tbody>
                </table> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CashOutHistory;
