import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { FaArrowUp, FaArrowDown  } from "react-icons/fa";
import { get, ref } from 'firebase/database';
import { db } from '../../../firebase/config';
const { Option } = Select;
const TotalIncome = () => {
  const [selectedYear, setSelectedYear] = useState('Select Year');
  const fontSize = window.innerWidth > 600 ? 13 : 8;

  const handleYearChange = (value) => {
    setSelectedYear(parseInt(value));
  };

const user = JSON.parse(localStorage.getItem('user'))
const [user1IncomeData,setUserIncome] = useState([]);
useEffect(() => {
  const fetchOrderItems = async () => {
    try {
      const ordersRef = ref(db, 'orders');
      const snapshot = await get(ordersRef);

      if (snapshot.exists()) {
        const orderItemsArray = Object.entries(snapshot.val())
          .filter(([orderId, orderData]) => (orderData.user === user.uid) && (orderData.status === 'Delivered'))
          .map(([orderId, orderData]) => ({
            orderId: orderId,
            ...orderData,
          }))
          .sort((b, a) => b.timestamp - a.timestamp);
        const transformedData = [];
        orderItemsArray.forEach((order) => {
          const year = new Date(order.timestamp).getFullYear();
          const month = new Date(order.timestamp).toLocaleString('en-US', { month: 'short' });
          let yearObject = transformedData.find(entry => entry.year === year);
          if (!yearObject) {
            yearObject = {
              year: year,
              months: [],
            };
            transformedData.push(yearObject);
          }
          let monthObject = yearObject.months.find(entry => entry.month === month);
          if (!monthObject) {
            monthObject = {
              month: month,
              income: 0,
            };
            yearObject.months.push(monthObject);
          }
          monthObject.income += order.sales || 0;
        });

        setUserIncome(transformedData);
        if (transformedData.length > 0) {
          setSelectedYear(transformedData[0].year);
      } else {
          const currentYear = new Date().getFullYear();
          setSelectedYear(currentYear);
      }
      
      }
    } catch (error) {
      console.error(error);
    }
  };
  fetchOrderItems();
}, [user.uid]);

  const selectedYearData = user1IncomeData.find((item) => item.year === selectedYear)?.months || [];

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-PH', { style: 'currency', currency: 'PHP' }).format(value);
  };

  const totalIncomePerYear = user1IncomeData.map((year) => ({
    year: year.year,
    total: year.months.reduce((acc, month) => acc + month.income, 0),
  }));

  const selectedYearIndex = totalIncomePerYear.findIndex((item) => item.year === selectedYear);
  const selectedYearTotalIncome = selectedYearIndex !== -1 ? totalIncomePerYear[selectedYearIndex].total : 0;
  const lastYearTotalIncome = selectedYearIndex > 0 ? totalIncomePerYear[selectedYearIndex - 1].total : 0;
let percentageChange;
if (lastYearTotalIncome !== 0) {
  percentageChange = ((selectedYearTotalIncome - lastYearTotalIncome) / lastYearTotalIncome) * 100;
} else {
  // Set the percentage change to 0 when lastYearTotalIncome is zero
  percentageChange = 0;
}

// Determine arrow icon and text colr
let arrowIcon, textColor;
if (percentageChange > 0) {
  arrowIcon = <FaArrowUp style={{fontSize: '14px', marginRight: '2px'}}/>;
  textColor = 'text-success';
} else if (percentageChange < 0) {
  arrowIcon = <FaArrowDown style={{fontSize: '14px', marginRight: '2px'}}/>;
  textColor = 'text-danger';
} else {
  // No arrow icon when percentageChange is 0
  arrowIcon = null;
  textColor = 'text-muted';
}

  return (
    <div className="card" style={{ maxHeight: '130vh', minHeight: '55vh' }}>
      <div className="card-header border-0 row d-flex justify-content-between align-items-center">
        <div className="card-title col-md-6 col-12">
          <span style={{ float: 'left' }}>Total Income Revenue</span>
        </div>
        <div className='card-select col-md-6 col-12'>
          <span>Select Year: </span>
          <Select defaultValue={selectedYear.toString()} style={{ width: 120 }} onChange={handleYearChange}>
            {user1IncomeData.map((item) => (
              <Option key={item.year} value={item.year.toString()}>
                {item.year}
              </Option>
            ))}
          </Select>
        </div>
      </div>
      <div className="d-flex justify-content-between pl-4 pr-4">
        <p className="d-flex flex-column">
          <span className='text-bold text-lg'>{formatCurrency(selectedYearTotalIncome)}</span>
          <span>Total Income per year</span>
        </p>
        <p className="ml-auto d-flex flex-column text-right">
          <span className={`d-flex align-items-center justify-content-end ${textColor}`}>
            {arrowIcon} {Math.abs(percentageChange).toFixed(1)}%
          </span>
          <span className="text-muted">Since last year</span>
        </p>
      </div>

      <div className="chart-container d-flex justify-content-center align-items-end">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={selectedYearData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis tickFormatter={(value) => formatCurrency(value)} tick={{ fontSize }} />
            <Tooltip formatter={(value) => formatCurrency(value)} />
            <Legend />
            <Line type="monotone" dataKey="income" stroke="#8884d8" activeDot={{ r: 5 }} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default TotalIncome;
