import React from 'react'
import './Hero.css'
import {Carousel} from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link } from 'react-router-dom';
import banner1 from '../Images/CDP_banner.jpg'
import banner2 from '../Images/CDP_banner2.jpg'
import banner3 from '../Images/CDP_banner3.jpg'
import banner4 from '../Images/CDP_banner4.jpg'
import banner5 from '../Images/CDP_banner5.jpg'
const Hero = () => {

  return (
    <section className='slider-section'>
      <Carousel 
          interval={4000} 
          showArrows={false} 
          infiniteLoop={true} 
          autoPlay={true} 
          showStatus={false}>

          <div id='img-fluid banner-holder'>
              <img src={banner1} alt="Banner" />
          </div>

          <div id='img-fluid banner-holder'>
              <img src={banner2} alt="Banner" />
          </div>

          <div id='img-fluid banner-holder'>
              <img src={banner3} alt="Banner" />
          </div>

          <div id='img-fluid banner-holder'>
              <img src={banner4} alt="Banner" />
          </div>

          <div id='img-fluid banner-holder'>
              <img src={banner5} alt="Banner" />
          </div>

          {/* <div id='carousel-item'>
            <div className='text-item'>
              <p>
                <span>J</span>oin with us
              </p>
              <em>"Delivering Dreams, One Product at a Time – Your Trusted Dropshipping Partner"</em>
              
            </div>
              
            <div className='link-btn'>
              <Link to='/login'><button className='link-login'>Let's get Start</button></Link>
              <button className='link-want'>Want to learn more?</button>
            </div>
              
          </div> */}

      </Carousel>
    </section>
  );
}

export default Hero;
