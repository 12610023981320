
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Table, Input, Tooltip, Button } from 'antd';
import { IoMdDownload, IoMdArrowDropdown } from 'react-icons/io';
import { CSVLink } from 'react-csv';
import { FaPrint, FaEye } from 'react-icons/fa6';
import Breadcrumb from '../../Assets/Breadcrumb/Breadcrumb';
import LOGO from '../../Assets/Images/CDP_green.png';
import Gcash_LOGO from '../../Assets/Images/GCash-Emblem.png';
import Palawan_LOGO from '../../Assets/Images/palawan-logo.png';
import MasterCard from '../../Assets/Images/mastercard.png';
import './StyleSheets/PageStyle.css';
import { db } from "../../firebase/config"
import { get, ref } from 'firebase/database';
import moment from 'moment';
const { Search } = Input;

const OrderHistory = () => {
  const breadcrumbPaths = [
    { label: 'Home', url: '/MyChosenDropPoint' },
    { label: 'Order History', url: '/OrderHistory' },
  ];
  const user = JSON.parse(localStorage.getItem('user'))
  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-PH', { style: 'currency', currency: 'PHP' }).format(value);
  };
  const [tableData, setTableData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const widerModalClass = 'wide_modal';
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [toTable, SetToTable] = useState([]);
  const sortInfo = useState({});

  const columns = [
    {
      title: 'Order No',
      dataIndex: 'orderId',
      key: 'orderId',
      width: '10%',
    },
    {
      title: 'Date Order',
      dataIndex: 'timestamp',
      key: 'timestamp',
      render: (timestamp) => moment(timestamp).format('MMM. DD, YYYY'),
    },
    {
      title: 'Customer',
      dataIndex: 'customer',
      key: 'customer',
      render: (customer) => {
        return customer.Fullname;
      },
    },
    {
      title: 'Items (Quantity)',
      dataIndex: 'items',
      key: 'quantity',
      render: (items) => items.map((product) => (
        <div key={product.name}>
          {`${product.name} (${product.quantity})`}
        </div>
      )),
    },
    {
      title: 'Total Amount',
      dataIndex: 'total',
      key: 'total',
      width: '10%',
      render: (total) => formatCurrency(total.toFixed(2)),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      sortOrder: sortInfo.columnKey === 'status' && sortInfo.order,
      filters: [
        { text: 'Pending', value: 'Pending' },
        { text: 'Approved', value: 'Approved' },
        { text: 'Delivered', value: 'Delivered' },
        { text: 'Returned', value: 'Returned' },
        { text: 'Cancelled', value: 'Cancelled' },
      ],
      onFilter: (value, record) => record.status === value,
      filterIcon: (filtered) => (
        <IoMdArrowDropdown style={{ color: filtered ? '#1890ff' : undefined, fontSize: '20px' }} />
      ),
      render: (status) => {
        let alertClass = '';
  
        switch (status) {
          case 'Pending':
            alertClass = 'alert alert-secondary';
            break;
          case 'Approved':
            alertClass = 'alert alert-light';
            break;
          case 'Delivered':
            alertClass = 'alert alert-success';
            break;
          case 'Returned':
            alertClass = 'alert alert-warning';
          break;
          case 'Cancelled':
            alertClass = 'alert alert-danger';
          break;
          default:
            alertClass = 'alert';
        }
        return <div className={alertClass}>{status}</div>;
      },

    },
    {
      title: 'View Invoice',
      dataIndex: 'invoice',
      key: 'invoice',
      width: '7%',
      render: (_, invoice) => (
        <Button 
          onClick={() => openModal(invoice.invoice)}
          disabled={invoice.status === 'Cancelled' || invoice.status === 'Pending' || invoice.status === 'Declined' }>
        <FaEye className='mr-2'/> View
      </Button>
      ),
    },
  ];
  
  useEffect(() => {
    const fetchOrderItems = async () => {
      try {
        const ordersRef = ref(db, 'orders');
        const snapshot = await get(ordersRef);
  
        if (snapshot.exists()) {
          const orderItemsArray = Object.entries(snapshot.val())
            .filter(([orderId, orderData]) => orderData.user === user.uid)
            .map(([orderId, orderData]) => ({
              orderId: orderId,
              ...orderData,
            }))
            .sort((b, a) => b.timestamp - a.timestamp);
          setTableData(orderItemsArray);
          const formattedData = orderItemsArray.map((order) => ({
            orderID: order.orderId,
            date: moment(order.timestamp).format('MMM. DD, YYYY'),
            customer: order.customer?.Fullname,
            orders: order.items.map((product) => `${product.name} (${product.quantity})`).join(', '),
            total: formatCurrency(order.total.toFixed(2)),
          }));
          SetToTable(formattedData);
          
        } else {
          SetToTable([]);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchOrderItems();
  }, [user.uid]);
  const csvHeaders = [
    { label: 'Order No.', key: 'orderID' },
    { label: 'Date Order', key: 'date' },
    { label: 'Customer', key: 'customer' },
    { label: 'Item (Quality)', key: 'orders' },
    { label: 'Total Amount', key: 'total' },
  ];
  
  const handleSearch = (value) => {
    setSearchText(value);
  };

  const filteredTableData = tableData.filter((record) => {
    const lowerSearchText = searchText.toLowerCase();
    return (
      record.orderId.toLowerCase().includes(lowerSearchText)
    );
  });

  const openModal = (index) => {
    setSelectedInvoice(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

const calculateTotalCost = () => {
  const subtotal = selectedInvoice.AmountDue.Subtotal;
  const shipping = selectedInvoice.AmountDue.Shipping;

  return subtotal + shipping;
};

  return (
    <div id="OrderHistoryContent">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Order History</h1>
              <span>Check out your recent orders and invoices.</span>
            </div>
            <div className="col-sm-6">
              <div className="container breadcrumb-holder d-flex justify-content-end">
                <Breadcrumb paths={breadcrumbPaths} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="content">
        <div className="container-fluid row">
          <div className="col-12">
            <div className="card card-container">
              <div className="card-header">
                <div className="card-title">
                  <h3>Order Lists</h3>
                </div>
                <div className="card-tools d-flex">
                  <Search
                    className='mr-2'
                    placeholder="Search Order here..."
                    allowClear
                    size="large"
                    width="auto"
                    onSearch={handleSearch}
                  />

                  <div className="downloadBtn mr-2">
                    <CSVLink
                      data={toTable}
                      headers={csvHeaders}
                      filename={'order_history.csv'}
                    >
                      <Tooltip placement="top" title="Download Table">
                        <button type="button" className="btn btn-primary">
                          <IoMdDownload className="btn-icon" />
                        </button>
                      </Tooltip>
                    </CSVLink>
                  </div>
                </div>
              </div>

              <div className="card-body table-responsive p-1">
                <Table
                  className="table-hover"
                  columns={columns}
                  dataSource={filteredTableData}
                  pagination={{ pageSize: 10 }}
                  scroll={{ y: 600, x: 1500 }}
                  style={{ minHeight: '48vh' }}
                />
                {selectedInvoice  && (
                <Modal show={isModalOpen} dialogClassName={widerModalClass}>
                  <Modal.Header>
                    <Modal.Title>
                      <strong>
                        Order:{' '}
                        {selectedInvoice !== null ? (
                          <span>#{selectedInvoice.InvoiceNumber}</span>
                        ) : null}
                      </strong>
                    </Modal.Title>
                    <div className="row">
                      <div className="col-12 d-flex justify-content-end align-items-center">
                        <button className="btn btn-primary" onClick={() => window.print()}>
                          <FaPrint /> Print Invoice
                        </button>
                      </div>
                    </div>
                  </Modal.Header>
                  <Modal.Body>
                    <section className="invoice" id="print-section">
                      <div className="row">
                        <div className="col-12">
                          <div className="page-header">
                            <img src={LOGO} alt="LOGO ICON" />
                            <h6 class="mb-0 text-right">Date: {
                            moment(selectedInvoice?.approvedDate).format('MMM. DD, YYYY')
                            }</h6>
                          </div>
                        </div>
                      </div>

                      <div className="row invoice-info justify-content-between align-items-start">
                        <div className="col-sm-4 invoice-col">
                          <span>From:</span>
                          <address>
                            <strong>Chosen Drop Point</strong>
                            <br />
                            Unit B-02 Lower 2nd Floor St. Francis Square Building,
                            <br />
                            Julia Vargas Avenue cor. Bank Drive
                            Ortigas Center Mandaluyong City.
                            <br />
                            Phone: (02) – 7006-8924
                            <br />
                            Email: iamchoseninternational@gmail.com
                          </address>
                        </div>
                        <div className="col-sm-4 invoice-col">
                          <span>To:</span>
                          <address>
                            <strong>{selectedInvoice.To.Name}</strong>
                            <br />
                            {selectedInvoice.To.Address}
                            <br />
                            Phone: {selectedInvoice.To.Phone}
                            <br />
                            Email: {selectedInvoice.To.Email}
                            <br />
                          </address>
                        </div>
                        <div className="col-sm-4 invoice-col">
                          <b>Invoice #{selectedInvoice.InvoiceNumber}</b><br />
                          <br />
                          <b>Order ID:</b> {selectedInvoice.OrderID}<br />
                          <b>Payment Method:</b> {selectedInvoice.PaymentMethod}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12 table-responsive">
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                <th>Qty</th>
                                <th>Product</th>
                                <th>Product ID</th>
                                <th>Subtotal</th>
                              </tr>
                            </thead>
                            <tbody>
                            {selectedInvoice.items?.map((item, itemIndex) => (
                            <tr key={itemIndex}>
                              <td>{item.quantity}</td>
                              <td>{item.name}</td>
                              <td>{item.productId}</td>
                              <td>{formatCurrency((item.price * item.quantity).toFixed(2))}</td>
                            </tr>
                          ))}
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-6">
                          <p className="lead">Accepted Payment Methods:</p>
                          <img src={Gcash_LOGO} alt="Gcash" className='m-1'/>
                          <img src={Palawan_LOGO} alt="Palawan" className='m-1'/>
                          <img src={MasterCard} alt="MasterCard" className='m-1'/>
                          <p className="text-muted well well-sm shadow-none" style={{marginTop: '10px'}}>
                          As part of our commitment to excellence, we offer secure and convenient payment methods, including Gcash, Palawan, and MasterCard. Your trust in our services drives us to continuously improve and provide a seamless shopping experience.
                          </p>
                        </div>
                       
                        <div className="col-6">
                          <p className="lead">Total Amount {formatCurrency(calculateTotalCost())}</p>
                          <div className="table-responsive">
                            <table className="table">
                              <tr>
                                <th style={{ width: '50%' }}>Subtotal:</th>
                                <td>{formatCurrency(selectedInvoice.AmountDue.Subtotal)}</td>
                              </tr>
                              <tr>
                                <th>Shipping:</th>
                                <td>{formatCurrency(selectedInvoice.AmountDue.Shipping)}</td>
                              </tr>
                              <tr>
                                <th>Total:</th>
                                <td>{formatCurrency(selectedInvoice.AmountDue.Total)}</td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>
                    </section>
                  </Modal.Body>
                  <Modal.Footer>
                    <button className="btn btn-danger" onClick={closeModal}>
                      Close
                    </button>
                  </Modal.Footer>
                </Modal>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderHistory;
