import React, { useState, useEffect } from 'react';
import './LinkPage.css';
import Hero from '../Assets/Hero/Hero'
import { Link } from 'react-router-dom'
import { FaMinus, FaPlus, FaCartPlus  } from "react-icons/fa6";
import { MdDeleteForever } from "react-icons/md";
import { Modal } from 'react-bootstrap';
import LOGO from '../Assets/Images/CDP_white_bigger.png'
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram, FaPhone } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { regions, provinces, cities, barangays } from 'select-philippines-address';
import { useParams } from 'react-router-dom'; 
import { storage, db } from '../firebase/config';
import { get, child, ref, set,serverTimestamp } from 'firebase/database';
import { getDownloadURL, ref as storageRef ,listAll} from 'firebase/storage';
import {  Result, message } from 'antd';

const LinkPage = () => {
  const [products, setProducts] = useState([])
  const initialProduct = products[0] || {};
  const [selectedItem, setSelectedItem] = useState(initialProduct);
  const [cartItems, setCartItems] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [total, setTotal] = useState(0);
  const [showCheckOut, setShowCheckOut] = useState(false);
  const widerModalClass = 'wide_modal';
  const { sellerID } = useParams();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('COD');
  const [remainingProducts, setRemainingProducts] = useState([]);
  const [userInformation, setUserInfo] = useState({
  });
  const [TotalSales, setTotalSales] = useState(0)
  const [CashBondBalance, setCashBondBalance] = useState(0)
  const [ReturnedCharges, setReturnCharges] =useState()
  const [Address, setAddress] = useState({
    Address: '' ,
    Landmark: ''
  })
  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-PH', { style: 'currency', currency: 'PHP' }).format(value);
  };
  useEffect(() => {
    const cashbondInfo = async () => {
      try {
        const cashbondInfoRef = ref(db, 'cashbond');
        const snapshot = await get(cashbondInfoRef);
  
        if (snapshot.exists()) {
          const cashbondArray = Object.entries(snapshot.val())
            .filter(([cashbondID, cashbondData]) => cashbondData.user === sellerID)
            .map(([cashbondID, cashbondData]) => ({
              cashbondID: cashbondID,
              amount: cashbondData.amount,
              ...cashbondData,
            }));
          if (cashbondArray.length > 0) {
            const totalCashBondBalance = cashbondArray
              .filter(
                (cashbond, index) =>
                  cashbond.status === 'Approved' &&
                  !isNaN(parseFloat(cashbond.amount))
              )
              .reduce((sum, cashbond) => sum + parseFloat(cashbond.amount), 0);
            setCashBondBalance(totalCashBondBalance);
          } else {
            setCashBondBalance(null);
          }
        } else {
          setCashBondBalance(null);
        }
      } catch (error) {
        console.error(error);
      }
    };
  
    cashbondInfo();
  }, []);

  useEffect(() => {
    const fetchOrderItems = async () => {
        try {
            const ordersRef = ref(db, 'orders');
            const snapshot = await get(ordersRef);
            if (snapshot.exists()) {
                    const orderRetrurnedItemsArray = Object.entries(snapshot.val())
                    .filter(([orderId, orderData]) => (orderData.user === sellerID) && (orderData.status=== 'Returned'))
                    .map(([orderId, orderData]) => ({
                        orderId: orderId,
                        ...orderData,
                    }))
                    .sort((b, a) => b.timestamp - a.timestamp);
                let returnedShipping= 0;
                orderRetrurnedItemsArray.forEach((order, index) => {
                  returnedShipping += order.invoice.AmountDue.Shipping;
              });
                setReturnCharges(returnedShipping)
            }
        } catch (error) {
            console.error(error);
        }
    };

    fetchOrderItems();
}, []);
  useEffect(() => {
    get(child(ref(db), 'products')).then(async (snapshot) => {
      if (snapshot.exists()) {
        const productsData = snapshot.val();
        const productsArray = Object.values(productsData);
  
        const productsWithImages = await Promise.all(productsArray.map(async (product) => {
          const imageRef = storageRef(storage, `products/${product.id}/${product.id}.png`);
          try {
            const imageUrl = await getDownloadURL(imageRef);
            const otherImageRef = storageRef(storage, `products/${product.id}/other`);
            const otherImagesResult = await listAll(otherImageRef);
            const otherImages = await Promise.all(otherImagesResult.items.map(async (item) => {
              return getDownloadURL(item);
            }));
            const limitedOtherImages = otherImages.slice(0, 3);
            const imagesArray = [imageUrl, ...limitedOtherImages];
            return { ...product, image: imageUrl, otherImage:imagesArray };
          } catch (error) {
            console.error('Error fetching image:', error);
          }
        }));
        setProducts(productsWithImages);
      } else {
        console.log('No data available');
      }
    }).catch((error) => {
      console.error(error);
    });
  }, []);

  useEffect(() => {
    setSelectedItem(initialProduct)
   },[products])
  
  useEffect(() => {
    const getUserInfo = async () => {
      try {
        const userInfoRef = ref(db, `users/${sellerID}`);
        const snapshot = await get(userInfoRef);
  
        if (snapshot.exists()) {
          const userData = snapshot.val();
          setUserInfo({...userInformation,
            Fullname:userData.firstname+ " " + userData.middlename + " " + userData.lastname,
            MemberID: userData.KYCIdNum
          });
        } else {
          setUserInfo(null); 
        }
      } catch (error) {
        console.error(error);
      }
    };
    getUserInfo();
  
  }, []);
  const handleProductClick = (product) => {
    setSelectedItem(product);
    if (selectedItem) {
      setRemainingProducts((prevProducts) => [...prevProducts, selectedItem]);
    }
  
  const quantityInput = document.getElementById('quantityInput');
    if (quantityInput) {
      quantityInput.value = 1;
    }
  };
  const [selectedImage, setSelectedImage] = useState(null);
  const handleHoverImage = (img) => {
    setSelectedItem((prevProduct) => ({
      ...prevProduct,
      image: img,
    }));
    setSelectedImage(img);
  };

  const handleQuantityChange = (e) => {
    const newQuantity = parseInt(e.target.value, 10);
    setQuantity(newQuantity);
  };

  const handleIncrement = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };

  const handleRemoveFromCart = (index) => {
    const updatedCartItems = [...cartItems];
    updatedCartItems.splice(index, 1);
    setCartItems(updatedCartItems);
    const newTotal = updatedCartItems.reduce((acc, item) => acc + item.price * item.quantity, 0);
    const newDP = updatedCartItems.reduce((acc, item) => acc + item.dp * item.quantity, 0);
    setTotalSales(newTotal-newDP)
    setTotal(newTotal);
  };

  const handleAddToCart = () => {
    const existingItemIndex = cartItems.findIndex((item) => item.id === selectedItem.id);
    if (existingItemIndex !== -1) {
      const updatedCartItems = [...cartItems];
      updatedCartItems[existingItemIndex].quantity += quantity;
      setCartItems(updatedCartItems);
    } else {
      const newItem = {
        ...selectedItem,
        quantity: quantity,
      };
      setCartItems([...cartItems, newItem]);
    }
  
    // Calculate newTotal based on updated cartItems
    const updatedCartItems = existingItemIndex !== -1 ? [...cartItems] : [...cartItems, { ...selectedItem, quantity }];
    const newTotal = updatedCartItems.reduce((acc, item) => acc + item.price * item.quantity, 0);
    const newDP = updatedCartItems.reduce((acc, item) => acc + item.dp * item.quantity, 0);
    
    setTotalSales(newTotal - newDP);
    setTotal(newTotal);
  };
  const handleCheckOut = (e) => {
    e.preventDefault()
    setTotalCost(total + shippingFee);
    setShowCheckOut(true);
  };
  const closeModal = () => {
    setShowCheckOut(false);
  };
  const [shippingFee, setShippingFee] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [shipping, setShipping] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [shippingForm, setShippingForm] = useState({
    Fullname: '',
    ContactNum: '',
    Email: '',
    region: '',
    regionName: '',
    province: '',
    provinceName: '',
    city: '',
    cityName: '',
    brgy: '',
    brgyName: '',
    street: '',
    landmark: '',
    postalCode: '',
    PaymentMethod: '',
    total: 0.0,
    shippingFee: 0.0,
    totalCost: 0.0
  });
  const generateOrderID = () => {
    const numericPart = Math.floor(Math.random() * 1000000000).toString().padStart(9, '0');
    const alphanumericCharacter = String.fromCharCode(65 + Math.floor(Math.random() * 26)); 
    const parcelID = `CDP${numericPart}${alphanumericCharacter}`;
    return parcelID;
    };
    const generateInvoiceID = () => {
      const numericPart = Math.floor(Math.random() * 1000000).toString().padStart(6, '0');
      const alphanumericCharacter = String.fromCharCode(65 + Math.floor(Math.random() * 26)); 
      const parcelID = `CDP${numericPart}${alphanumericCharacter}`;
      return parcelID;
      };
  const handleProceedPurchase = async ()=>{
    setIsLoading(true);
    try {
      await new Promise(resolve => setTimeout(resolve, 1500));
      const orderID = generateOrderID();
      const InvoiceID = generateInvoiceID();
      const timestamp = serverTimestamp(); 
      const orderData = {        
        invoice: {
        From: {
          Name: 'Chosen Drop Point, Inc.',
          Address: 'Unit B-02 Lower 2nd Floor St. Francis Square Building Julia Vargas Avenue cor. Bank Drive Ortigas Center Mandaluyong',
          Phone: '(02) – 7006-8924',
          Email: 'iamchoseninternational@gmail.com',
        },
        To: {
          Name: shippingForm.Fullname,
          Address: shippingForm.landmark+", "+shippingForm.brgyName+", "+ shippingForm.cityName+", "+shippingForm.provinceName +", "+shippingForm.regionName +", '"+shippingForm.postalCode+ "'",
          Phone: shippingForm.ContactNum,
          Email: shippingForm.Email ,
        },
        InvoiceNumber: InvoiceID,
        OrderID: orderID,
        PaymentMethod: selectedPaymentMethod,
        items: cartItems.map((item) => ({
          name: item.name,
          quantity: item.quantity,
          price: item.price,
          productId: item.id,
          dp:item.dp
        })),
        AmountDue: {
          Subtotal: total,
          Shipping:shipping,
          Total: total+shipping,
        },
      },
      address: {
        fullAddress:shippingForm.brgyName+", "+ shippingForm.cityName+", "+shippingForm.provinceName,
        street: shippingForm.street,
        landmark: shippingForm.landmark,
      },
        user: sellerID,
        seller: userInformation.Fullname,
        status: "Pending",
        total: total,
        sales: TotalSales,
        companySales: total- TotalSales,
        timestamp: timestamp,
        MemberID: userInformation.MemberID,
        customer:{
          Email:shippingForm.Email,
          Fullname:shippingForm.Fullname,
          ContactNum:shippingForm.ContactNum,
          PostalCode: shippingForm.postalCode,
          Region: shippingForm.regionName
        },
        items: cartItems.map((item) => ({
          productId: item.id,
          name: item.name,
          quantity: item.quantity,
          price: item.price,
          dp:item.dp,
          TotalPrice: item.dp * item.quantity,
        })),
      };
      await set(ref(db, `orders/${orderID}`), orderData);
      setCartItems([]);
      setShippingForm({
        Fullname: '',
        ContactNum: '',
        Email: '',
        region: '',
        regionName: '',
        province: '',
        provinceName: '',
        city: '',
        cityName: '',
        brgy: '',
        brgyName: '',
        street: '',
        landmark: '',
        postalCode: '',
        PaymentMethod: '',
        total: 0.0,
        shippingFee: 0.0,
        totalCost: 0.0
      });
      setShowCheckOut(false);
      const subject = `Order ${orderID} is Successful wait for Admin Approval`
      const email = shippingForm.Email
      const response = await fetch('https://cdp-email-bef0d127fa8b.herokuapp.com/sendEmail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, orderData, subject }),
      });
      if (response.ok) {
        console.log('Email sent successfully');
      } else {
        console.error('Failed to send email');
      }
      
      message.success('Ordered Successfully');
      setTotal(0);
      setIsLoading(false); 
    } catch (error) {
      console.error('Error placing order:', error);
      setIsLoading(false);
    }
  };
  const handleOrdersInfo = (e) => {
    setShippingForm({...shippingForm, [e.target.name]: e.target.value})
  }
  const [regionData, setRegionData] = useState([]);
  const [provinceData, setProvinceData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [barangayData, setBarangayData] = useState([]);

  const fetchRegions = () => {
    regions()
      .then(response => {
        setRegionData(response);
        setShippingForm(prevData => ({ ...prevData, region: response[null]?.region_code || '' }));
      })
      .catch(error => console.error('Error fetching regions:', error));
  };
  const fetchProvinces = region_code => {
    provinces(region_code).then(response => {
      setProvinceData(response);
      
    });
  };
  const fetchCities = provinceCode => {
    cities(provinceCode).then(response => {
      setCityData(response);
    });
  };
  const fetchBarangays = cityCode => {
    barangays(cityCode).then(response => {
      setBarangayData(response);
    });
  };
  useEffect(() => {
    fetchRegions();
  }, []);
  const onChangeRegion = (e) => {
    const selectedRegionCode = e.target.value;
    const selectedRegion = regionData.find((item) => item.region_code === selectedRegionCode);
    const shippingFee = calculateShippingFee(selectedRegionCode);
    setShippingFee(shippingFee);
    setShippingForm((prevShippingForm) => ({
      ...prevShippingForm,
      region: selectedRegionCode,
      regionName: selectedRegion ? selectedRegion.region_name : '',
      shippingFee: shippingFee,
    }));
    setTotalCost(total + shippingFee);
    fetchProvinces(selectedRegionCode);
    setProvinceData([]);
    setCityData([]);
    setBarangayData([]);
  };
  const onChangeProvince = (e) => {
    const selectedProvinceCode = e.target.value;
    const selectedProvince = provinceData.find((item) => item.province_code === selectedProvinceCode);
    setShippingForm({
      ...shippingForm,
      province: selectedProvinceCode,
      provinceName: selectedProvince ? selectedProvince.province_name : '',
    });
    fetchCities(selectedProvinceCode);
    setCityData([]);
    setBarangayData([]);
  };
  const onChangeCity = (e) => {
    const selectedCityCode = e.target.value;
    const selectedCity = cityData.find((item) => item.city_code === selectedCityCode);
    setShippingForm({
      ...shippingForm,
      city: selectedCityCode,
      cityName: selectedCity ? selectedCity.city_name : '',
    });
    fetchBarangays(selectedCityCode);
    setBarangayData([]);
  };
  const onChangeBarangay = (e) => {
    const selectedBarangayCode = e.target.value;
    const selectedBarangay = barangayData.find((item) => item.brgy_code === selectedBarangayCode);
    setShippingForm({
      ...shippingForm,
      brgy: selectedBarangayCode,
      brgyName: selectedBarangay ? selectedBarangay.brgy_name : '',
    });
  };
  const onChangeMethod = (e) => {
    setSelectedPaymentMethod(e.target.value);
  };
  const calculateShippingFee = (selectedRegionCode) => {
    const isNCR = selectedRegionCode === '13'; 
    if (isNCR) {
      setShipping(100)
      return 100;
    } else {
      setShipping(150)
      return 150;
    }
  };
  const [isFormFilled, setIsFormFilled] = useState(false);
  useEffect(() => {
    const checkFormFilled = () => {
      const requiredFields = ['Fullname', 'ContactNum', 'Email', 'region', 'province', 'city', 'brgy', 'postalCode'];
      return requiredFields.every(field => !!shippingForm[field]);
    };
    setIsFormFilled(checkFormFilled());
  }, [shippingForm]);

  return (

    <div className='linkPage-wrapper'>
      <section id='LinkPageContent'>
      {userInformation && (CashBondBalance - ReturnedCharges > 150) ?
        <div className="container-fluid p-0">
          <div className="banner-holder">
            <Hero/>
          </div>
          <div className="content p-5">
            <div className="header mt-4">
              <h1>Welcome to Chosen Drop Point</h1>
              <h2>Please select an item from our collection below.</h2>
            </div>
            <form method="" action="">
              <div className="container-fluid row p-5">

                <div className='col-12 col-lg-7'>
                  <div className="card card-selectedItem p-3">
                    {selectedItem && (
                    <div className="card-body">
                      <div className="row">
                        <div className="col-12 col-sm-5">
                            <div className="mainImage-holder d-flex justify-content-start align-items-center">
                              <div className="image">
                               <img src={selectedItem.image} alt={selectedItem.name} className='product-image'/>
                              </div>
                            </div>
                            {selectedItem.otherImage ? 
                             <div className="other-image d-flex justify-content-start align-items-center">
                              {selectedItem.otherImage.map((img, index) => (
                                <div className={`image-holder ${selectedImage ? 'selected' : ''}`}>
                                  <img
                                    key={index}
                                    src={img}
                                    alt={`Other Pic ${index + 1}`}
                                    onMouseEnter={() => handleHoverImage(img)}
                                    onClick={() => handleHoverImage(img)}
                                  />
                                </div>
                              ))}
                            </div> :
                            <p>no more Image</p>}
                        </div>
                        <div className="col-12 col-sm-7">
                          <h3 className='my-3'>{selectedItem.name}</h3>
                          <pre>
                            {selectedItem.description}
                          </pre>
                          <hr/>
                          <div className="py-2 px-3 mt-4">
                            <h4>Quantity</h4>
                            <div className="quantity-btn align-items-center justify-content-start">
                              <input
                                type="number"
                                min="1"
                                max="50"
                                value={quantity}
                                onChange={handleQuantityChange}
                                className="form-control"
                              />
                              <FaMinus className="minus-btn" onClick={handleDecrement} />
                              <FaPlus className="plus-btn" onClick={handleIncrement} />
    
                            </div>
                          </div>
                          
                          <div className="py-2 px-3 mt-4">
                            <h2 className="mb-0">Price</h2>
                            {selectedItem.price !== undefined && (
                              <h4>₱ {selectedItem.price}</h4>
                            )}
                          </div>
                          <div className="mt-4">
                            <div className="btn btn-primary btn-lg align-items-center" onClick={handleAddToCart}>
                              <FaCartPlus className='mr-2'/> Add to cart
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    )}
                  </div>
                </div>

                <div className="col-12 col-lg-5">
                  <div className="card card-cartItems p-2">
                    <div className="card-header border-0">
                      <h3>Cart Items</h3>
                    </div>
                    <div className="card-body table-responsive">
                      <table className='table table-head-fixed table-bordered'>
                        <thead>
                          <tr>
                            <th>Item</th>
                            <th>Quantity</th>
                            <th>Price</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {cartItems.map((item, index) => (
                            <tr key={index}>
                              <td>{item.name}</td>
                              <td>{item.quantity}</td>
                              <td>{formatCurrency(item.price)}</td>
                              <td>
                                <div onClick={() => handleRemoveFromCart(index)} 
                                    className='btn btn-danger align-items-center'>
                                      <MdDeleteForever className='btn-danger'/>
                                </div>
                              </td>
                            </tr>
                          ))}
                          
                        </tbody>
                        
                      </table>
                    </div>
                    
                    <div className="card-footer">
                      <div className="check-btn d-flex justify-content-between align-items-center">
                        <div className="subTotal d-flex mt-4">
                          <h4>Total Price: <small>{formatCurrency(total)}</small></h4>
                        </div>
                        <button
                          className="btn btn-success btn-lg "
                          onClick={handleCheckOut}
                          disabled={cartItems.length === 0}
                        >
                          Checkout now
                        </button>
                      </div>
                    </div>
                    {showCheckOut && (
                      <Modal show={showCheckOut} dialogClassName={widerModalClass}>
                        <Modal.Header>
                          <Modal.Title>Orders Preview</Modal.Title>
                          <div className="cancel-btn">
                              <button className='btn btn-danger' onClick={closeModal}>
                              Cancel
                            </button>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                          <section className="checkout">
                            
                            <div className="row">
                              
                              <div className="col-12 col-md-4 table-responsive">
                                {/* Order details */}
                                <table className="table table-striped">
                                  <thead>
                                    <tr>
                                      <th></th>
                                      <th>Item</th>
                                      <th>Quantity</th>
                                      <th>Price</th>
                                      <th>Total</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                  {cartItems.map((item, index) => (
                                      <tr key={index}>
                                        <td style={{maxWidth: '80px'}}>
                                          <img src={item.image} alt={item.name} style={{width: '100%'}}/>
                                        </td>
                                        <td>{item.name}</td>
                                        <td>{item.quantity}</td>
                                        <td>{formatCurrency(item.price)}</td>
                                        <td>{formatCurrency((item.price * item.quantity))}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                                <div className="sub-total ">
                                  <p className="lead">Amount Due</p>
                                  <div className="table-responsive">
                                    <table className="table">
                                      <tr>
                                        <th style={{ width: '50%' }}>Subtotal:</th>
                                        <td>{formatCurrency(total)}</td>
                                      </tr>
                                      <tr>
                                        <th>Shipping:</th>
                                        <td>{formatCurrency(shippingFee)}</td>
                                      </tr>
                                      <tr>
                                        <th>Total Cost:</th>
                                        <td>{formatCurrency(totalCost)}</td>
                                      </tr>
                                    </table>
                                  </div>
                                </div>
                              </div>
                              
                              
                              <div className="col-12 col-md-8">
                                {/* buyer info*/}
                                <div className="card">
                                  <div className="card-header border-0">
                                    <h5>Contact Info</h5>
                                  </div>
                                  <div className="card-body">

                                    <div className="row">
                                      <div className="col-12 col-sm-6">
                                        <div className="form-group p-1">
                                          <label htmlFor='Fullname'>Recipient Name</label><span className='text-danger'> * (Full Name)</span>
                                          <input type="text" 
                                                className="form-control" 
                                                placeholder="Full Name"
                                                name='Fullname'
                                                id='Fullname'
                                                onChange={(e) => {handleOrdersInfo(e)}}
                                                required/>
                                        </div>
                                      </div>
                                      <div className="col-12 col-sm-6">
                                        <div className="form-group p-1">
                                          <label htmlFor='Email'>Email Address</label><span className='text-danger'> *</span>
                                          <input type="text" 
                                                className="form-control" 
                                                placeholder="Email Address"
                                                name='Email'
                                                id='Email'
                                                onChange={(e) => {handleOrdersInfo(e)}}
                                                required/>
                                        </div>
                                      </div>
                                      <div className="col-12 col-sm-6">
                                        <div className="form-group p-1">
                                          <label htmlFor='ContactNum'>Contact Number</label><span className='text-danger'> *</span>
                                          <input type="text" 
                                                className="form-control" 
                                                placeholder="+63 Contact Number"
                                                name='ContactNum'
                                                id='ContactNum'
                                                onChange={(e) => {handleOrdersInfo(e)}}
                                                required/>
                                        </div>
                                      </div>
                                      <div className="col-12 col-sm-6">
                                        <div className="form-group d-flex flex-column">
                                          <label>Payment Method<span className='text-danger'> *</span></label>
                                        {totalCost > 4000 ?
                                        <>                                          
                                        <div className="form-check form-check-inline p-1">
                                            <label className="form-check-label" htmlFor="COD">
                                              COD <span className='text-muted'>(maximum limit of 4000.00 php)</span>
                                            </label>
                                          </div>
                                          <div className="form-check form-check-inline p-1">
                                          <input
                                                  type="radio"
                                                  className="form-check-input mb-0"
                                                  name="PaymentMethod"
                                                  id="bank"
                                                  value="bank"
                                                  onChange={onChangeMethod}
                                                  checked={selectedPaymentMethod === 'bank'}
                                                  defaultChecked
                                                />
                                            <label className="form-check-label" for="bank">Bank Transfer</label>
                                          </div>
                                          </>
                                          :
                                          <>                                          
                                          <div className="form-check form-check-inline p-1">
                                            <input
                                                    type="radio"
                                                    className="form-check-input mb-0"
                                                    name='PaymentMethod'
                                                    id='COD'
                                                    value="COD"
                                                    onChange={onChangeMethod}
                                                    checked={selectedPaymentMethod === 'COD'}
                                                    defaultChecked
                                                  />
                                              <label className="form-check-label" htmlFor="COD">
                                                COD <span className='text-muted'>(maximum limit of 4000.00 php)</span>
                                              </label>
                                            </div>
                                            <div className="form-check form-check-inline p-1">
                                            <input
                                                    type="radio"
                                                    className="form-check-input mb-0"
                                                    name="PaymentMethod"
                                                    id="bank"
                                                    value="bank"
                                                    onChange={onChangeMethod}
                                                    checked={selectedPaymentMethod === 'bank'}
                                                  />
                                              <label className="form-check-label" for="bank">Bank Transfer</label>
                                            </div>
                                            </>
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {/* shipping info*/}
                                <div className="card">
                                  <div className="card-header border-0">
                                    <h5>Shipping Address</h5>
                                  </div>
                                  <div className="card-body">
                                  <div className="row">
                                  <div className="col-12 col-sm-6">
                                    <div className='form-group p-1'>
                                      <label htmlFor='region'>Region</label><span className='text-danger'> *</span>
                                      <select
                                        className='form-control'
                                        name='region'
                                        value={shippingForm.region}
                                        onChange={onChangeRegion}
                                        required
                                      >
                                        <option value='' disabled>
                                          Select Region
                                        </option>
                                        {regionData.map(item => (
                                          <option
                                            key={item.region_code}
                                            value={item.region_code}
                                          >
                                            {item.region_name}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-12 col-sm-6">
                                    <div className="form-group p-1">
                                      <label htmlFor='province'>Province</label><span className='text-danger'> *</span>
                                      <select
                                        className='form-control'
                                        name='province'
                                        value={shippingForm.province}
                                        onChange={onChangeProvince}
                                        required
                                      >
                                        <option value='' disabled>
                                          Select Province
                                        </option>
                                        {provinceData.map(item => (
                                          <option
                                            key={item.province_code}
                                            value={item.province_code}
                                          >
                                            {item.province_name}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-12 col-sm-6">
                                    <div className='form-group p-1'>
                                      <label htmlFor='city'>City / Municipal</label><span className='text-danger'> *</span>
                                      <select
                                        className='form-control'
                                        name='city'
                                        value={shippingForm.city}
                                        onChange={onChangeCity}
                                        required
                                      >
                                        <option value='' disabled>
                                          Select City / Municipality
                                        </option>
                                        {cityData.map(item => (
                                          <option 
                                            key={item.city_code} 
                                            value={item.city_code}
                                          >
                                            {item.city_name}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-12 col-sm-6">
                                    <div className="form-group p-1">
                                      <label htmlFor='brgy'>Barangay</label><span className='text-danger'> *</span>
                                      <select
                                        className='form-control'
                                        name='brgy'
                                        value={shippingForm.brgy}
                                        onChange={onChangeBarangay}
                                        required
                                      >
                                        <option value='' disabled>
                                          Select Barangay
                                        </option>
                                        {barangayData.map(item => (
                                          <option 
                                            key={item.brgy_code} 
                                            value={item.brgy_code}
                                          >
                                            {item.brgy_name}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-12 col-sm-6">
                                    <div className="form-group p-1">
                                      <label htmlFor='street'>Street</label><span className='text-danger'> *</span>
                                      <input type="text" 
                                             className="form-control" 
                                             placeholder="Street"
                                             name='street'
                                             id='street'
                                             value={shippingForm.street}
                                             onChange={(e) => {handleOrdersInfo(e)}}
                                             required/>
                                    </div>
                                  </div>
                                  <div className="col-12 col-sm-6">
                                    <div className="form-group p-1">
                                      <label htmlFor='postalCode'>Postal Code</label><span className='text-danger'> *</span>
                                      <input type="text" 
                                             className="form-control" 
                                             placeholder="Postal Code"
                                             name='postalCode'
                                             id='postalCode'
                                             value={shippingForm.postalCode}
                                             onChange={(e) => {handleOrdersInfo(e)}}
                                             required/>
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div className="form-group p-1">
                                      <label htmlFor='landmark'>Landmark</label><span className='text-muted'> (Optional)</span>
                                      <textarea type="text" 
                                             className="form-control" 
                                             placeholder="Landmark / Exact Address / Guidance Instructions for Directions"
                                             name='landmark'
                                             id='landmark'
                                             value={shippingForm.landmark}
                                             onChange={(e) => {handleOrdersInfo(e)}}
                                             required/>
                                    </div>
                                  </div>
                                </div>
                                  </div>
                                </div>
                              </div>

                            </div>

                          </section>
                        </Modal.Body>
                        <Modal.Footer className="d-flex justify-content-between">
      
                            <div className="total-cost">
                              <h3>Total: {formatCurrency(totalCost.toFixed(2))}</h3>
                            </div>

                            <div className="checkout-btn">
                              <button
                                type='button'
                                onClick={handleProceedPurchase}
                                className='btn btn-success'
                                disabled={!isFormFilled || isLoading}
                              >
                                {isLoading && (
                                  <span className="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span>
                                )}
                                {isLoading ? 'Processing...' : 'Proceed Purchase'}
                              </button>
                            </div>
          
                        </Modal.Footer>
                      </Modal>
                    )}
                  </div>
                </div>

                <div className="col-12">
                  <div className='card card-otherItem p-3'>
                    <div className="card-header border-0 align-items-center">
                      <h2 className='my-3'>Other Products</h2>
                    </div>
                    <div className='card-body row'>
                      {products
                        .filter((otherProduct) => otherProduct.id !== selectedItem.id)
                        .map((otherProduct, index) => (
                          <div
                            key={index}
                            className="col-12 col-sm-3 p-4 d-flex"
                            onClick={() => handleProductClick(otherProduct)}
                            style={{ cursor: 'pointer' }}
                          >
                            <div className='item-holder d-flex flex-column'>
                              <div className="image-holder d-flex justify-content-center align-items-center">
                                <img src={otherProduct.image} alt={otherProduct.name} />
                              </div>
                              <div>
                                <h4>{otherProduct.name}</h4>
                                {otherProduct.price !== undefined && (
                                  <span>₱ {otherProduct.price}</span>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>

              </div>
            </form>
          </div>
          
        </div>
       :  <Result
       status="404"
       title="404"
       subTitle="Sorry, the page you visited does not exist. Please Check the Link and Try Again"
     />
       }
      </section>
      <footer>
        <div className="footer">
            <div className="container">
               <div className="row justify-content-around">
                  <div className="col-lg-3 col-md-6 col-sm-6">
                     <div className="footer-logo-holder">
                        <img src={LOGO} alt="LOGO ICON" />
                     </div>
                     <div className="follow">
                        <p>We'll ensure your order is processed promptly, and you can expect updates on its journey to the chosen drop point. We appreciate your trust in our service!</p>
                     </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6">
                     <h3>Social Media Accounts </h3>
                     <div className="follow">
                        <p>Follow us on our social media platforms </p>
                        <ul className="social_icon">
                           <li> <a to='/'><FaFacebook/></a></li>
                           <li> <a to='/'><FaTwitter/></a></li>
                           <li> <a to='/'><FaLinkedin/></a></li>
                           <li> <a to='/'><FaInstagram/></a></li>
                        </ul>
                     </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6">
                     <h3>Contact us</h3>
                     <ul className="location_icon">
                        <li className='email'><Link to='/Contact Us' className='d-flex justify-content-center align-item-center'>
                           <MdEmail className='mr-2'/><small>iamchoseninternational@gmail.com</small></Link>
                        </li>
                        <li><FaPhone className='mr-2'/> (02) – 7006-8924</li>
                     </ul>
                  </div>
               </div>
            </div>
            <div className="copyright">
               <div className="container">
                  <div className="row">
                     <div className="col-md-10 offset-md-1">
                        <p>Copyright 2023 All Right Reserved By Chosen DropPoint</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </footer>
    </div>
  );
};

export default LinkPage;
