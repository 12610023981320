import React from 'react'
import './StyleSheets/PageStyle.css'
import StatsBox from './Content/StatsBox'
import TotalIncome from './Content/TotalIncome';
import TotalSales from './Content/TotalSales';

const DashBoard = () => {

  return (
    <>
      <div id='dashboardContent'>
        <div className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">Dashboard</h1>
                    </div>
                </div>
            </div>
        </div>
        <div className="content">
            <div className="container-fluid">
                <StatsBox/>
                <div className='row'>
                    <div className="col-lg-6 mt-4">
                        <TotalSales/>
                    </div>
                    <div className="col-lg-6 mt-4">
                        <TotalIncome/>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
};

export default DashBoard;
