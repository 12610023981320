import React, { useEffect, useState } from 'react';
import './StyleSheets/PageStyle.css'
import Gcash from '../../Assets/Images/GCash-Emblem.png'
import Palawan from '../../Assets/Images/palawan-logo.png'
import MasterCard from '../../Assets/Images/mastercard.png'
import { PiWalletBold } from "react-icons/pi";
import { IoIosSend } from "react-icons/io";
import Breadcrumb from '../../Assets/Breadcrumb/Breadcrumb';
import Modal from 'react-bootstrap/Modal';
import { message } from 'antd';
import { get, ref, set,serverTimestamp } from 'firebase/database';
import { db } from "../../firebase/config"
import moment from 'moment';
const WalletDetails = () => {
  const breadcrumbPaths = [
      { label: 'Home', url: '/MyChosenDropPoint' },
      { label: 'Wallet Details', url: '/WalletDetails' },
  ];
  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-PH', { style: 'currency', currency: 'PHP' }).format(value);
  }; 
  const [selectedMethod, setSelectedMethod] = useState(null);
  const [showFieldsForm, setShowFieldForm] = useState(true);
  const [paymentSelection, setPaymentSelection] = useState(true);
  const [paymentSelected, setPaymentSelected] = useState(false);
  const [cashBondForm, setCashBondForm] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [additionalInfo, setAdditionalInfo] = useState('')
  const [CheckPending, SetPending] = useState()
  const [walletBalance, setWalletBalance] = useState(0);
  const [CashBondBalance, setCashBondBalance] = useState(0)
  const [CashbondRequest, setCashbondRequest] = useState(0)
  const [enteredAmount, setEnteredAmount] = useState('');
  const user = JSON.parse(localStorage.getItem('user'))
  const [userInformation, setUserInfo] = useState();
  const [userFullName, setFullname] = useState()
  const [cashOutMessage, setCashOutMessage] = useState()
  const [cashbondStatus, setcashbondStatus] = useState()
  const [ReturnedCharges, setReturnCharges] =useState()
  const [GcashInfo, setGcashInfo] = useState({
    AccountName: '',
    GcashNumber: ''
  })
  const [PalawanInfo, setPalawanInfo] = useState({
    FullName: '',
    PhoneNumber: ''
  })
  const [BankInfo, setBankInfo] = useState({
    AccountName: '',
    AccountNumber:'',
    PhoneNumber: ''
  })

  const handleMethodSelection = (method) => {
    setSelectedMethod(method);
  }
  const handleCashBondForm = () => {
    setCashBondForm(true);
  }
  const handleMethodSubmit = async(e) => {
    e.preventDefault();
    const CashOutInfo = {    
      Method: selectedMethod,
      Recipient: BankInfo.AccountName || GcashInfo.AccountName || PalawanInfo.FullName,
      BankAccountNumber: BankInfo.AccountNumber || 'N/A',
      PhoneNumber: BankInfo.PhoneNumber || GcashInfo.GcashNumber || PalawanInfo.PhoneNumber,
    };
    await set(ref(db, `cashout_method/${user.uid}`), CashOutInfo);
    setPaymentSelection(false); 
    setShowFieldForm(false);  
    setPaymentSelected(true); 
  };

  useEffect(() => {
    const fetchMethod = async () => {
      try {

        const methodRef = ref(db, `cashout_method/${user.uid}`);
        const snapshot = await get(methodRef);
  
        if (snapshot.exists()) {


          setPaymentSelection(false);
          setShowFieldForm(false);
          setPaymentSelected(true);
  
          const methods = Object.values(snapshot.val()).map((methodInfo) => methodInfo);
          setSelectedMethod(methods[1]);
            if (methods[1] === 'GCash') {
              setGcashInfo({
               AccountName:methods[3]
              ,GcashNumber: methods[2] 
            });
            }
            if (methods[1] === 'Palawan') {
              setPalawanInfo({
                FullName:methods[3]
              ,PhoneNumber: methods[2] 
            });
          }
            if (methods[1] === 'Bank-Account') {
              setBankInfo({
                AccountName:methods[3],
                AccountNumber:methods[1],
                PhoneNumber: methods[2]
            });
            }
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchMethod();

  }, [selectedMethod]);

  useEffect(() => {
    const fetchOrderItems = async () => {
        try {
            const ordersRef = ref(db, 'orders');
            const snapshot = await get(ordersRef);
            const timestamp = serverTimestamp();

            if (snapshot.exists()) {
                const orderItemsArray = Object.entries(snapshot.val())
                    .filter(([orderId, orderData]) => (orderData.user === user.uid) && (orderData.status=== 'Delivered'))
                    .map(([orderId, orderData]) => ({
                        orderId: orderId,
                        ...orderData,
                    }))
                    .sort((b, a) => b.timestamp - a.timestamp);
                    const orderRetrurnedItemsArray = Object.entries(snapshot.val())
                    .filter(([orderId, orderData]) => (orderData.user === user.uid) && (orderData.status=== 'Returned'))
                    .map(([orderId, orderData]) => ({
                        orderId: orderId,
                        ...orderData,
                    }))
                    .sort((b, a) => b.timestamp - a.timestamp);
                let overallSalesSum = 0;
                let returnedShipping= 0;
                orderItemsArray.forEach((order, index) => {
                    if ('sales' in order) {
                        overallSalesSum += order.sales;
                    } else {
                        console.warn(`Order ${index + 1} does not have a valid sales property.`);
                    }
                });
                orderRetrurnedItemsArray.forEach((order, index) => {
                  returnedShipping += order.invoice.AmountDue.Shipping;
              });
              try {
                const pendingRef = ref(db, 'request');
                const snapshot = await get(pendingRef);
                let totalApprovedAmount = 0;
                if (snapshot.exists()) {
                  const requestData = snapshot.val();
                  const userUid = user.uid;
                  Object.keys(requestData).forEach((requestID) => {
                    const request = requestData[requestID];
                    if (request.user === userUid && request.status === 'Approved') {
                      totalApprovedAmount += request.amount || 0;
                    }
                  });
                }
                setWalletBalance(overallSalesSum - totalApprovedAmount);
              } catch (error) {
                console.error(error);
              }
                setReturnCharges(returnedShipping)
            }
        } catch (error) {
            console.error(error);
        }
    };

    fetchOrderItems();
}, []);

const fetchPendings = async () => {
  try {
    const pendingRef = ref(db, 'request');
    const snapshot = await get(pendingRef);
    if (snapshot.exists()) {
      const requestData = snapshot.val();
      const userUid = user.uid;
      SetPending(true);
      Object.keys(requestData).forEach((requestID) => {
        const request = requestData[requestID];
        if (request.user === userUid && request.status === 'Pending') {
          SetPending(false);
        }
      });
    }
  } catch (error) {
    console.error(error);
  }
};
  const handleAmountChange = (e) => {
    const enteredValue = parseFloat(e.target.value);
    if (!isNaN(enteredAmount) && enteredValue > walletBalance) {
      setEnteredAmount(walletBalance.toFixed(2));
    } else {
      setEnteredAmount(e.target.value);
    }
  };

  const formatBankAccountNumber = (event) =>{
    const input = event.target;
    const value = input.value.replace(/\D/g, '');
    const formattedValue = value.replace(/(\d{4})/g, '$1 ').trim(); 
    input.value = formattedValue;
  }
  const generateRequestID = () => {
    const numericPart = Math.floor(Math.random() * 1000000000).toString().padStart(9, '0');
    const alphanumericCharacter = String.fromCharCode(65 + Math.floor(Math.random() * 26)).padStart(3, '0'); 
    const parcelID = `${alphanumericCharacter}${numericPart}`;
    return parcelID;
    };
    useEffect(() => {
      const getUserInfo = async () => {
        try {
          const userInfoRef = ref(db, `users/${user.uid}`);
          const snapshot = await get(userInfoRef);
    
          if (snapshot.exists()) {
            const userData = snapshot.val();
            setUserInfo(userData);
            setFullname(`${userData.firstname} ${userData.middlename} ${userData.lastname}`)
          } else {
            setUserInfo(null);
            setFullname(null)
          }
        } catch (error) {
          console.error(error);
        }
      };

      getUserInfo();
    }, []); 

useEffect(() => {
  const cashbondInfo = async () => {
    try {
      const cashbondInfoRef = ref(db, 'cashbond');
      const snapshot = await get(cashbondInfoRef);

      if (snapshot.exists()) {
        const cashbondArray = Object.entries(snapshot.val())
          .filter(([cashbondID, cashbondData]) => cashbondData.user === user.uid)
          .map(([cashbondID, cashbondData]) => ({
            cashbondID: cashbondID,
            amount: cashbondData.amount,
            ...cashbondData,
          }));
        if (cashbondArray.length > 0) {
          const totalCashBondBalance = cashbondArray
            .filter(
              (cashbond, index) =>
                cashbond.status === 'Approved' &&
                !isNaN(parseFloat(cashbond.amount))
            )
            .reduce((sum, cashbond) => sum + parseFloat(cashbond.amount), 0);
          setCashBondBalance(totalCashBondBalance);
          const lastStatus = cashbondArray[cashbondArray.length - 1].status;
          setcashbondStatus(lastStatus);
        } else {
          setcashbondStatus(null);
          setCashBondBalance(null);
        }
      } else {
        setcashbondStatus(null);
        setCashBondBalance(null);
      }
    } catch (error) {
      console.error(error);
    }
  };

  cashbondInfo();
}, [cashBondForm]);

  const handleCashOutSubmit = async(e) => {
    e.preventDefault();
    const today = new Date();
    const isMonday = today.getDay() === 1;

    fetchPendings()
    if (isMonday) {
      if(CheckPending) {
      try {
        const RequestID = generateRequestID();
        const timestamp = serverTimestamp();
        const RequestData = {    
          RequestID: RequestID,
          timestamp: timestamp,
          amount: enteredAmount,
          Method: selectedMethod,
          Recipient: BankInfo.AccountName || GcashInfo.AccountName || PalawanInfo.FullName,
          BankAccountNumber: BankInfo.AccountNumber || 'N/A',
          PhoneNumber: BankInfo.PhoneNumber || GcashInfo.GcashNumber || PalawanInfo.PhoneNumber,
          additionalInfo: additionalInfo|| 'N/A',
          status: "Pending",
          MemberID:userInformation.IdNum,
          user: user.uid
        };
        await set(ref(db, `request/${RequestID}`), RequestData);
        message.success('Requested Successfully!');
      } catch (error) {
        console.error('Error placing order:', error);
      }
    }else{
      setShowAlert(true);
      setCashOutMessage(`You Can only have 1 Pending Request.

                        Please wait for the Admin response.`)
    }
    }else{
      setShowAlert(true);
      setCashOutMessage(`Cash-out requests are only accepted on Mondays.

                         Please try again on Monday.`)
  }
  };
  const generateCashBondID = (count) => {
    const numericPart = (count + 1).toString().padStart(8, '0');
    const cashBondID = `CB-${numericPart}`;
    return cashBondID;
  };
  const handleCashBondSend = async (event) => {
    event.preventDefault();
    const cashBond = ref(db, `cashbond`);
    try {
      const cashBondSnapshot = await get(cashBond);
  
      let cashBondItemsCount = 0;
  
      if (cashBondSnapshot.exists()) {
        cashBondItemsCount = Object.keys(cashBondSnapshot.val()).length;
      }
  
      const timestamp = serverTimestamp();
      const cashBondID = generateCashBondID(cashBondItemsCount);
  
      set(ref(db, `cashbond/${cashBondID}`), {
        user: user.uid,
        date: timestamp,
        sender: userFullName,
        email: userInformation?.email,
        phone: userInformation?.phone,
        amount: CashbondRequest,
        status: 'Pending',
      });
      const cashBondData = {
        user: user.uid,
        date: moment(timestamp).format('MMM. DD, YYYY'),
        sender: userFullName,
        email: userInformation?.email,
        phone: userInformation?.phone,
        amount: CashbondRequest,
        status: 'Pending',
      }
      message.success('Send Successfully');
      const subject = `Your account has been created successfully`
      const email = user.email
      const response = await fetch('https://cdp-email-bef0d127fa8b.herokuapp.com/cashbondReq', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, cashBondData, subject }),
      });
      if (response.ok) {
        console.log('Email sent successfully');
      } else {
        console.error('Failed to send email');
      }
      setCashBondForm(false)

    } catch (error) {
      console.error('Error fetching or updating cash bond data:', error);
    }
  };
  return (
    <div id='walletDetailsContent'>
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">My Wallet</h1>
              <span>Unlock the potential for shared success with our premium products.</span>
            </div>
            <div className="col-sm-6">
              <div className='container breadcrumb-holder d-flex justify-content-end'>
                <Breadcrumb paths={breadcrumbPaths} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {showAlert && (
        <Modal show={showAlert}>
        <Modal.Header>
          <Modal.Title>Request Failed!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{whiteSpace: 'pre-line', textAlign: 'center'}} >
          {cashOutMessage}
          </h5>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-success" onClick={()=>setShowAlert(false)}>
            I Understand
          </button>
        </Modal.Footer>
      </Modal>
      )}
      <div className="content">
        <div className="container-fluid">
            <div className="row">

              <div className="col-xl-9 col-xxl-12">
                <div className="row">

                  <div className="card col-12">
                    <div className="card-header border-0">
                      <div className="row justify-content-between align-items-end g-2">
 
                        <div className="col-12">
                          <div className="d-flex mb-3">
                            <PiWalletBold className='mr-3' style={{fontSize: '68px', color: '#00a86b'}}/>
                              <div className="mr-auto">
                                <h5 className='fs-18' style={{fontSize: '18px', fontWeight: '700'}}>
                                Remaining Balance
                                </h5>
                                <span className='text-num' style={{fontSize: '24px', fontWeight: '700', float: 'left'}}>
                                  {formatCurrency(walletBalance.toFixed(2))}
                                </span>
                              </div>
                              
                          </div>
                          <div className="ml-3 d-flex justify-content-start align-items-center">
                            <h5 className='fs-18 mb-0' style={{fontSize: '18px', fontWeight: '700'}}>
                            Cash bond: {formatCurrency(CashBondBalance - ReturnedCharges)}
                            </h5>
                            {cashbondStatus === 'Pending' ?
                            <p className='text-danger'>*Request is Pending</p>
                             :
                            <button className='btn btn-link' onClick={handleCashBondForm}>Request Cashbond</button>
                          }
                          </div>
                        </div>
                        <div className="col-12">
                        {cashBondForm && (
                          <form className='ml-3' >
                            <div className="col-md-12 col-lg-6 d-flex justify-content-between align-items-center">
                              <div className="form-group" style={{width: '55%'}}>
                                <label htmlFor="cashAmount" className='d-flex justify-content-start'>Amount:</label>
                                <input
                                  type="number"
                                  id="cashAmount"
                                  name="cashAmount"
                                  className="form-control"
                                  placeholder="Enter the amount..."
                                  value={CashbondRequest}
                                  onChange={(e) => setCashbondRequest(e.target.value)}
                                  min={500}
                                  required
                                />
                              </div>
                              <button 
                                type='submit' 
                                className='btn btn-primary mt-2' 
                                style={{width: '40%', height: '38px'}}
                                onClick={handleCashBondSend}> 
                                      <IoIosSend/> Send
                              </button>
                            </div>
                          </form>
                        )}
                        </div>
                  
                      </div>
                    </div>

                    <div className="card-body">
                      <div className="row">
                        {paymentSelection ? (
                        <div className="col-md-12 col-lg-9 payment-selection">
                          <h6>Select the preferred payment method of your choice.</h6>
                          <p> Please be aware that changes to your chosen payment method cannot be made. 
                          <br/>Be sure you thoroughly consider your options before selecting the payment method.</p>
                          <div className="row">

                            <div className="col-md-12 col-xl-4">
                              <div className={`card g-cash method ${selectedMethod === 'GCash'? 'selected' : ''}`}
                                   onClick={() => handleMethodSelection('GCash')}>
                                <div className="card-body">
                                  <div className="logo-holder">
                                    <img src={Gcash} alt="Gcash Emblem" className='gcash-logo' />
                                    <h4 className="card-title ml-2">GCash</h4>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-12 col-xl-4">
                              <div className={`card palawan method ${selectedMethod === 'Palawan'? 'selected' : ''}`}
                                   onClick={() => handleMethodSelection('Palawan')}>
                                <div className="card-body">
                                  <div className="logo-holder">
                                    <img src={Palawan} alt="Gcash Emblem" className='palawan-logo' />
                                    <h4 className="card-title ml-2">Palawan</h4>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-12 col-xl-4">
                              <div className={`card bank-account method ${selectedMethod === 'Bank-Account' ? 'selected' : ''}`}
                                   onClick={() => handleMethodSelection('Bank-Account')}>
                                <div className="card-body">
                                  <div className="logo-holder">
                                    <img src={MasterCard} alt="Gcash Emblem" className='mastercard-logo' />
                                    <h4 className="card-title ml-2">Bank Account</h4>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                        ) : (
                        <div className="col-md-12 col-lg-9 cashout-section">
                          <div className="row">
                            <div className="col-12 mb-3 d-flex align-items-center justify-content-start">
                              <h4 className='ml-3'>Cash-out Request </h4>
                              <small className='ml-2 text-secondary'>*(Available only on Mondays.)</small>
                            </div>
                          </div>
                          
                          <section className='p-3'>
                            <form onSubmit={handleCashOutSubmit}>
                              <div className="form-group">
                                <label htmlFor="amount">Amount:</label>
                                <input
                                  type="number"
                                  id="amount"
                                  name="amount"
                                  className="form-control"
                                  placeholder="Enter the amount to cash out"
                                  min={500}
                                  max={walletBalance}
                                  value={enteredAmount}
                                  onChange={handleAmountChange}
                                  required
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="bankAccountName">Account Name:</label>
                                <input
                                  type="text"
                                  id="bankAccountName"
                                  name="bankAccountName"
                                  className="form-control"
                                  value={BankInfo.AccountName || GcashInfo.AccountName || PalawanInfo.FullName }
                                  readOnly
                                />
                              </div>
                              { selectedMethod === 'Bank-Account'&&
                              <div className="form-group">
                                <label htmlFor="bankAccount">Bank Account Number:</label>
                                <input
                                  type="text"
                                  id="bankAccount"
                                  name="bankAccount"
                                  className="form-control"
                                  value={BankInfo.AccountNumber  }
                                  readOnly
                                />
                              </div>
                              }
                              <div className="form-group">
                                <label htmlFor="PhoneNum">Phone Number:</label>
                                <input
                                  type="text"
                                  id="PhoneNum"
                                  name="PhoneNum"
                                  className="form-control"
                                  value={BankInfo.PhoneNumber|| GcashInfo.GcashNumber || PalawanInfo.PhoneNumber }
                                  readOnly
                                />
                              </div>
                        
                              <div className="form-group">
                                <label htmlFor="additionalInfo">Additional Information:</label>
                                <textarea
                                  id="additionalInfo"
                                  name="additionalInfo"
                                  className="form-control"
                                  rows="3"
                                  onChange={(e) => setAdditionalInfo(e.target.value)}
                                  placeholder="Provide any additional information or comments"
                                ></textarea>
                              </div>
                        
                              <button type="submit" className="btn btn-primary">
                                Submit Request
                              </button>
                            </form>
                          </section>
                        </div>
                        )}

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="col-xl-3 col-xxl-12">
                <div className="row">
                  <div className="col-12">
                    <div className="card p-2">
                      {paymentSelected && (
                        <div className="payment-selected">
                          {selectedMethod === 'GCash' && (
                            <div className='card g-cash method selected'>
                              <div className="card-body">
                                <div className="logo-holder">
                                  <img src={Gcash} alt="Gcash Emblem" className='gcash-logo' />
                                  <h4 className="card-title ml-2">GCash Details</h4>
                                </div>
                              </div>
                            </div>
                          )}
                          {selectedMethod === 'Palawan' && (
                            <div className='card palawan method selected'>
                              <div className="card-body">
                                <div className="logo-holder">
                                  <img src={Palawan} alt="Gcash Emblem" className='palawan-logo' />
                                  <h4 className="card-title ml-2">Recipient Info</h4>
                                </div>
                              </div>
                            </div>
                          )}
                          {selectedMethod === 'Bank-Account' && (
                            <div className='card bank-account method selected'>
                              <div className="card-body">
                                <div className="logo-holder">
                                  <img src={MasterCard} alt="Gcash Emblem" className='mastercard-logo' />
                                  <h4 className="card-title ml-2">Bank Account Details</h4>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                      {showFieldsForm ? (
                        <div className='fields-form'>
                        <form onSubmit={handleMethodSubmit}>
                            {!selectedMethod && (
                              <h6 style={{ textAlign: 'center', margin: '10px' }}><strong>Please Choose Payment Method!</strong></h6>
                            )}
                            {selectedMethod && (
                              <h6 style={{ textAlign: 'center', margin: '10px' }}>Sign up the fields below</h6>
                            )}
                            
                            {selectedMethod === 'GCash' && (
                            <div className='GCash-from'>
                              <div className="input-group">
                                <label htmlFor="GAccountname">Account Name:</label>
                                <input type="text"
                                      name='GAccountname'
                                      id='GAccountname'
                                      onChange={(e) => setGcashInfo({ ...GcashInfo, AccountName: e.target.value })}
                                      />
                              </div>
                              <div className="input-group">
                                <label htmlFor="GNumber">GCash Number:</label>
                                <input
                                    type="tel" // Use "tel" type for phone numbers
                                    pattern="[0-9]{11}" // Set a pattern for 11 digits (adjust as needed)
                                    placeholder="09xxxxxxxxx"
                                    name="GNumber"
                                    id="GNumber"
                                    onChange={(e) => setGcashInfo({ ...GcashInfo, GcashNumber: e.target.value })}
                                    required // Add "required" attribute if the field is mandatory
                                  />
                              </div>
                              <div className='submit-btn'>
                                <input type="submit" className='btn btn-success' />
                              </div>
                            </div>
                            )}
                            {selectedMethod === 'Palawan' && (
                            <div className="palawan-form">
                              <div className="input-group">
                                <label htmlFor="PFname">Full Name:</label>
                                <input type="text"
                                      name='PFname'
                                      id='PFname' 
                                      onChange={(e) => setPalawanInfo({ ...PalawanInfo, FullName: e.target.value })}
                                      />
                              </div>
                              <div className="input-group">
                                <label htmlFor="PhoneNo">Phone Number:</label>
                                <input  
                                    type="tel" // Use "tel" type for phone numbers
                                    pattern="[0-9]{11}" // Set a pattern for 11 digits (adjust as needed)
                                    placeholder="09xxxxxxxxx"
                                      name='PhoneNo'
                                      id='PhoneNo' 
                                      onChange={(e) => setPalawanInfo({ ...PalawanInfo, PhoneNumber: e.target.value })}
                                      required/>
                              </div>
                              <div className='submit-btn'>
                                <input type="submit" className='btn btn-success' />
                              </div>
                            </div>
                            )}
                            {selectedMethod === 'Bank-Account' && (
                            <div className="bankAccount-form">
                              <div className="input-group">
                                <label htmlFor="BAccountName">Account Name:</label>
                                <input type="text"
                                      name='BAccountName'
                                      id='BAccountName' 
                                      onChange={(e) => setBankInfo({ ...BankInfo, AccountName: e.target.value })}
                                      />
                              </div>
                              <div className="input-group">
                                <label htmlFor="BAccountNo">Account Number:</label>
                                <input
                                    type="text"
                                    placeholder="Enter your bank account number (4-4-4-4)"
                                    name="BAccountNo"
                                    id="BAccountNo"
                                    title="Please enter a valid bank account number in the format 4-4-4-4"
                                    required
                                    onChange={(e) => setBankInfo({ ...BankInfo, AccountNumber: e.target.value })}
                                    onInput={(e) => formatBankAccountNumber(e)}
                                  />
                              </div>
                              <div className="input-group">
                                <label htmlFor="PhoneNo">Phone Number:</label>
                                <input  
                                    type="tel" // Use "tel" type for phone numbers
                                    pattern="[0-9]{11}" // Set a pattern for 11 digits (adjust as needed)
                                    placeholder="09xxxxxxxxx"
                                      name='PhoneNo'
                                      id='PhoneNo' 
                                      onChange={(e) => setBankInfo({ ...BankInfo, PhoneNumber: e.target.value })}
                                      required/>
                              </div>
                              <div className='submit-btn'>
                                <input type="submit" className='btn btn-success' />
                              </div>
                            </div>
                            )}
                          </form>
                        </div>
                      ) : (
                        <div className="account-details">
                          {selectedMethod === 'GCash' && (
                            <div className="gcash-details">
                              <label htmlFor="">Account Name:</label>
                              <div className="input-holder">
                                  <span>{GcashInfo.AccountName}</span>
                              </div>
                              <label htmlFor="">GCash Number:</label>
                              <div className="input-holder">
                                  <span>{GcashInfo.GcashNumber}</span>
                              </div>
                            </div>
                          )}
                          {selectedMethod === 'Palawan' && (
                            <div className="gcash-details">
                              <label>Full Name:</label>
                              <div className="input-holder">
                                  <span>{PalawanInfo.FullName}</span>
                              </div>
                              <label>Phone Number:</label>
                              <div className="input-holder">
                                  <span>{PalawanInfo.PhoneNumber}</span>
                              </div>
                            </div>
                          )}
                          {selectedMethod === 'Bank-Account' && (
                            <div className="gcash-details">
                              <label>Account Name:</label>
                              <div className="input-holder">
                                  <span>{BankInfo.AccountName}</span>
                              </div>
                              <label>Account Number:</label>
                              <div className="input-holder">
                                  <span>{BankInfo.AccountNumber}</span>
                              </div>
                              <label>Phone Number:</label>
                              <div className="input-holder">
                                  <span>{BankInfo.PhoneNumber}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

            </div>
        </div>
      </div>
    </div>
  );
};

export default WalletDetails;
