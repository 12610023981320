import React, { useEffect } from 'react'
import './AboutPage.css'
import Footer from '../../Components/Footer/Footer'
import Breadcrumb from '../../../Assets/Breadcrumb/Breadcrumb';
import Navbar from '../../Components/NavBar/Nabvar';
import { useLocation } from 'react-router-dom';

const AboutPage = () => {
  const breadcrumbPaths = [
    { label: 'Home', url: '/' },
    { label: 'About Us', url: '/about-us' },
  ];
  
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
    <Navbar />
    
    <div className='wrapper' id='about-wrapper'>
      <div className='background-div' id='aboutPage'>
        <div className='breadcrumb-holder d-flex justify-content-start'>
          <Breadcrumb paths={breadcrumbPaths}/>
        </div>

        <section id='about-us' className='d-flex align-item-center justify-content-center'>
          <div className="container d-flex align-item-center justify-content-start">
              <h2 data-aos="fade"
                  data-aos-easing="linear"
                  data-aos-duration="2000"
                  data-aos-once="true">
                  About Us
                  </h2>
              <em data-aos="fade-up"
                data-aos-easing="#example-anchor"
                data-aos-duration="1000"
                data-aos-once="true">
                  Welcome to our company, where we are dedicated to 
                  delivering the best products and services in our industry. 
                  We pride ourselves on our commitment to customer 
                  satisfaction and our passion for innovation.
                  Our journey began many years ago, and since then, 
                  we have continuously evolved to meet the changing needs of our 
                  customers. Our team is made up of talented individuals who work 
                  together to achieve our common goals.
              </em>
              <em data-aos="fade-up"
                data-aos-easing="#example-anchorr"
                data-aos-duration="2000"
                data-aos-once="true">
                  Our journey began many years ago, and since then, 
                  we have continuously evolved to meet the changing needs of our 
                  customers. Our team is made up of talented individuals who work 
                  together to achieve our common goals.
              </em>
              <em data-aos="fade-up"
                data-aos-easing="#example-anchor"
                data-aos-duration="2500"
                adta-aos-offset="2500"
                data-aos-once="true">
                  At our core, we value quality, integrity, and reliability. 
                  We look forward to serving you and providing you with the 
                  exceptional products and services that have made us a trusted 
                  name in the industry.
              </em>
          </div>
        </section>

        <section id='whatis' className='d-flex align-item-center justify-content-center'>
          <div className="container row">
            <h2 data-aos="zoom-in-down"
                data-aos-anchor-placement="bottom-bottom"
                data-aos-duration="1000"
                data-aos-once="true"
                className='col-lg 12 d-flex justify-content-center'>
                What is Chosen Drop Point?
            </h2>
            
            <div className='col-lg-12 md-6'>
              <p data-aos="zoom-in-right"
                  data-aos-duration="2000"
                  data-aos-once="true">
                  Welcome to our company, where we are dedicated to delivering the best products and 
                  services in our industry. We pride ourselves on our commitment to customer 
                  satisfaction and our passion for innovation. At our core, we value quality, integrity, and reliability. We look forward to 
                  serving you and providing you with the exceptional products and services that have 
                  made us a trusted name in the industry.
              </p>
              <p data-aos="zoom-in-left"
                  data-aos-duration="2000"
                  data-aos-once="true">
                  Our journey began many years ago, and since then, we have continuously evolved to meet 
                  the changing needs of our customers. Our team is made up of talented individuals who 
                  work together to achieve our common goals.
                  At our core, we value quality, integrity, and reliability. We look forward to serving 
                  you and providing you with the exceptional products and services that have made us a 
                  trusted name in the industry.
              </p>
            </div>
          </div>
        </section>

        <section id='motto' className='d-flex align-item-center justify-content-center'>
          <div className="container">
            
            <div  data-aos="flip-right"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="2000"
                  data-aos-once="true"
                  className="left-card">

                  <h4>Mission</h4>
                  <p>
                  "To provide an effortless and cost-effective e-commerce solution by enabling entrepreneurs 
                  and businesses to easily source, market, and sell a wide range of high-quality products to 
                  their customers without the burden of inventory management. We aim to empower our partners 
                  with the tools, knowledge, and support they need to thrive in the competitive world of online retail."
                  </p>
            </div>
            <div  data-aos="flip-left"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="2000"
                  data-aos-once="true"
                  className="right-card">

                  <h4>Vision</h4>
                  <p>
                  "Our vision is to be the leading and most trusted dropshipping platform that transforms 
                  dreams into successful e-commerce businesses. We aspire to continuously innovate and expand 
                  our network of suppliers, providing access to an even broader selection of in-demand products. 
                  We're committed to fostering a global community of successful entrepreneurs who achieve financial 
                  independence and business growth through our services."
                  </p>
            </div>
          
          </div>
        </section>
      </div>
      <Footer/>
    </div>
    </>
  )
}

export default AboutPage;
