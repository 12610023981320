import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './LandingPage/Pages/HomePage/HomePage';
import AboutPage from './LandingPage/Pages/AboutPage/AboutPage';
import Products from './LandingPage/Pages/Products/Products';
import ContactUs from './LandingPage/Pages/ContactUs/ContactUs';
import LoginSignup from './LandingPage/Pages/LogInSignUp/LoginSignup';
import ForgetPass from './LandingPage/Pages/ForgetPassword/ForgetPass';
import ClientPage from './ClientPage/ClientPage';
import DashBoard from './ClientPage/Pages/DashBoard';
import MyAccount from './ClientPage/Pages/MyAccount';
import OrderHistory from './ClientPage/Pages/OrderHistory';
import InputOrder from './ClientPage/Pages/InputOrder';
import ProductLink from './ClientPage/Pages/ProductLink';
import WalletDetails from './ClientPage/Pages/WalletDetails';
import CashOutHistory from './ClientPage/Pages/CashOutHistory';
import LinkPage from './LinkPage/LinkPage';

function App() {
  
  return (
    <div id='container-app'>
      <Router>
        <Routes>
          <Route path='/' element={<HomePage/>} />
          <Route path='/about' element={<AboutPage />} />
          <Route path='/products' element={<Products />} />
          <Route path='/contact Us' element={<ContactUs />} />
          <Route path="/login" element={<LoginSignup />} />
          <Route path="/forgetpassword" element={<ForgetPass />} />
          <Route path='/MyChosenDropPoint' element={<ClientPage/>}>
            <Route index element={<DashBoard/>} />
            <Route path='/MyChosenDropPoint/OrderHistoy' element={<OrderHistory/>}/>
            <Route path='/MyChosenDropPoint/InputOrder' element={<InputOrder/>}/>
            <Route path='/MyChosenDropPoint/ProductLink' element={<ProductLink/>}/>
            <Route path='/MyChosenDropPoint/WalletDetails' element={<WalletDetails/>}/>
            <Route path='/MyChosenDropPoint/CashOutHistory' element={<CashOutHistory/>}/> 
            <Route path='/MyChosenDropPoint/MyAccount' element={<MyAccount/>} />
          </Route>
          <Route path='/ChoosenDropPoint/:sellerID' element={<LinkPage/>}/>
        </Routes>
      </Router>
    </div>
  );
};

export default App;