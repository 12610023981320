import React, { useState } from 'react'
import './ForgetPass.css'
import { Link } from 'react-router-dom';
import { IoMdArrowRoundBack } from "react-icons/io";
import Navbar from '../../Components/NavBar/Nabvar';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../../firebase/config';


const ForgetPass = () => {
    const [errorMessage, setErrorMessage] = useState('');

    const handleForgetPass = (e) => {
        e.preventDefault();

        const enteredEmail = e.target.elements.email.value;
        sendPasswordResetEmail(auth, enteredEmail)
        alert('Email sent! Check your inbox for password reset instruction')
    };
  return (
    <>
    <Navbar/>
    <div className='forgetpass-container'>
      <div className='forgetpass-content'>
        <div className='back-btn mb-4'>
            <Link to='/login'>
                <IoMdArrowRoundBack /> Back to Login
            </Link>
        </div>
        <div className='card-form p-4'>
            <div className='title'>
                <h1>Forgot Password</h1>
                <p>Please enter your registered email address to get a password reset email.</p>
            </div>
            {errorMessage && (
                <div className='error-message'>
                    <p>{errorMessage}</p>
                </div>
            )}
            <form onSubmit={handleForgetPass}>
                <div className='input-group'>
                    <label htmlFor="email">Email:</label>
                    <input 
                        type="email"
                        id='email' 
                        name='email'
                        placeholder='Enter your Email here...'/>
                </div>
                <div className='input-group d-flex'>
                    <button type='submit' className='send-link-btn btn btn-success'>Send Link</button>
                </div>
            </form>
        </div>
      </div>
    </div>
    </>
  );
};

export default ForgetPass;
