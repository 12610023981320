import React from 'react'

const PrivatePolicy = () => {
  return (
    <pre id='policy' style={{ whiteSpace: 'pre-line', fontSize: '15px', fontFamily: 'Roboto', wordBreak: 'break-word'}}>

        <strong>1. INTRODUCTION </strong>
        <br/>
        <br/>
        <p className='ml-4'>
        A. Welcome to the Drop Point platform run by Chosen Drop Point. Chosen Drop Point takes its responsibilities under applicable privacy laws and regulations ("Privacy Laws") seriously and is committed to respecting the privacy rights and concerns of all Users of our Drop Point website and mobile application (the "Platform") (we refer to the Platform and the services we provide as described on our Platform collectively as the "Services"). Users refer to a user who registers for an account with us for the use of the Services, including Distributors (individually and collectively, “Users”, “you” or “your”). We recognize the importance of the personal data you have entrusted to us and believe that it is our responsibility to properly manage, protect and process your personal data. This Privacy Policy (“Privacy Policy” or “Policy”) is designed to assist you in understanding how we collect, use, disclose and/or process the personal data you have provided to us and/or we possess about you, whether now or in the future, as well as to assist you in making an informed decision before providing us with any of your personal data. 
        </p>
        <p className='ml-4'>
            B. "Personal Data" or "personal data" means data, whether true or not, about an individual who can be identified from that data, or from that data and other information to which an organization has or is likely to have access. Common examples of personal data could include name, identification number, and contact information.
        </p>
        
        <p className='ml-4'>
            C. By using the Services, registering for an account with us, visiting our Platform, or accessing the Services, you acknowledge and agree that you accept the practices, requirements, and/or policies outlined in this Privacy Policy, and you hereby consent to us collecting, using, disclosing and/or processing your personal data as described herein. IF YOU DO NOT CONSENT TO THE PROCESSING OF YOUR PERSONAL DATA AS DESCRIBED IN THIS PRIVACY POLICY, PLEASE DO NOT USE OUR SERVICES OR ACCESS OUR PLATFORM. If we change our Privacy Policy, we will notify you, including by posting those changes or the amended Privacy Policy on our Platform. We reserve the right to amend this Privacy Policy at any time. To the fullest extent permissible under applicable law, your continued use of the Services or Platform, including placing any orders, shall constitute your acknowledgment and acceptance of the changes made to this Privacy Policy.
        </p>
        
        <p className='ml-4'>
            D. This Policy applies in conjunction with other notices, contractual clauses, consent clauses that apply in relation to the collection, storage, use, disclosure and/or processing of your personal data by us and is not intended to override those notices or clauses unless we state expressly otherwise. 
        </p>
        
        <p className='ml-4'>
            E. This Policy applies to both buyers and sellers who use the Services except where expressly stated otherwise.
        </p>
        
        <br/>
        <strong>2. WHEN WILL CHOSEN DROP POINT COLLECT PERSONAL DATA?</strong>
        <br/>
        <br/>
        <p className='ml-4'>
        A. We will/may collect personal data about you:
        </p>
        <p className='ml-4'>
        B. when you register and/or use our Services or Platform, or open an account with us;
        </p>
        <p className='ml-4'>
        C. when you submit any form, including, but not limited to, application forms or other forms relating to any of our products and services, whether online or by way of a physical form;
        </p>
        <p className='ml-4'>
        D. when you enter into any agreement or provide other documentation or information in respect of your interactions with us, or when you use our products and services;
        </p>
        <p className='ml-4'>
        E. when you interact with us, such as via telephone calls (which may be recorded), letters, fax, face-to-face meetings, social media platforms and emails, including when you interact with our customer service agents;
        </p>
        <p className='ml-4'>
        F. when you use our electronic services or interact with us via our application or use services on our Platform. This includes, without limitation, through cookies which we may deploy when you interact with our application or website;
        </p>
        <p className='ml-4'>
        G. when you grant permissions on your device to share information with our application or Platform;
        </p>
        <p className='ml-4'>
        H. when you provide us with feedback or complaints;
        </p>
        <p className='ml-4'>
        I. when you register for a contest; or when you submit your personal data to us for any reason 
        </p>
        <br/>
        <strong>3. WHAT PERSONAL DATA WILL CHOSEN DROP POINT COLLECT?</strong>
        <br/>
        <br/>
        <p className='ml-4'>
        A. The personal data that Chosen Drop Point may collect includes but is not limited to: <br/>
        <div className='ml-3'> 
            1. name; <br/>
            2. email address; <br/>
            3. date of birth; <br/>
            4. bank account and payment information; <br/>
            5. telephone number; <br/>
            6. gender; <br/>
            7. information sent by or associated with the device(s) used to access our Services or Platform; <br/>
            8. information about your network and the people and accounts you interact with; <br/>
            9. government-issued identification or other information required for our due diligence, know your customer, identity verification, or fraud prevention purposes; <br/>
            10. any other information about the User when the User signs up to use our Services or Platform, and when the User uses the Services or Platform, as well as information related to how the User uses our Services or Platform; <br/>
        </div>
        </p>
        <p className='ml-4'>
        B. You agree not to submit any information to us that is inaccurate or misleading, and you agree to inform us of any inaccuracies or changes to such information. We reserve the right at our sole discretion to require further documentation to verify the information provided by you.
        </p>
        <p className='ml-4'>
        C. If you do not want us to collect the aforementioned information/personal data, you may opt out at any time by notifying our Data Protection Officer in writing. Further information on opting out can be found in the section below entitled "How can you withdraw consent, remove, request access to or modify information you have provided to us?" Note, however, that opting out or withdrawing your consent for us to collect, use or process your personal data may affect your use of the Services and the Platform. For example, opting out of the collection of location information will cause its location-based features to be disabled.
        </p>
        <br/>
        <strong>4. HOW DO WE USE THE INFORMATION YOU PROVIDE US?</strong>
        <br/>
        <br/>
        <p className='ml-4'>
        A. We may collect, use, disclose and/or process your personal data for one or more of the following purposes:
        </p>
        <p className='ml-4'>
        B. to consider and/or process your application/transaction with us or your transactions or communications.
        </p>
        <p className='ml-4'>
        C. to manage, operate, provide and/or administer your use of and/or access to our Services and our Platform (including, without limitation, remembering your preference), as well as your relationship and user account with us;
        </p>
        <p className='ml-4'>
        D. to respond to, process, deal with or complete a transaction and/or to fulfill your requests for certain products and services and notify you of service issues and unusual account actions;
        </p>
        <p className='ml-4'>
        E. to enforce our Terms of Service or any applicable end-user license agreements;
        </p>
        <p className='ml-4'>
        F. to protect personal safety and the rights, property or safety of others;;
        </p>
        <p className='ml-4'>
        G. for identification, verification, due diligence, or know your customer purposes;
        </p>
        <p className='ml-4'>
        H. to maintain and administer any software updates and/or other updates and support that may be required from time to time to ensure the smooth running of our Services;
        </p>
        <p className='ml-4'>
        I. to deal with or facilitate customer service, carry out your instructions, deal with or respond to any inquiries given by (or purported to be given by) you or on your behalf;
        </p> 
        <br/>
        <strong>5. We reserve the right to refuse to correct your personal data in accordance with the provisions as set out in Privacy Laws, where they require and/or entitle an organization to refuse to correct personal data in stated circumstances.</strong>
        <br/>
        <br/>
        <strong>6. QUESTIONS, CONCERNS OR COMPLAINTS? CONTACT US</strong>
        <p className='ml-4'>
        If you have any questions or concerns about our privacy practices, we welcome you to contact us through e-mail at iamchoseninternational@gmail.com
        </p>
    </pre>
  )
}

export default PrivatePolicy